import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { connect } from "react-redux";
import patientListAction from "../../../actions/patientListAction";

const mapStateToProps = (state) => ({
  toogleAction: state.patientList.toogleState,
});

const PrivateRoute = ({ element: Component, ...rest }) => {
  return localStorage.getItem("token") ? (
    <main
      id="wrapper"
      class={
        rest.toogleAction.toggle ? "main-container toggled" : "main-container "
      }
    >
      <div class="sidebar">
        <div class="main-logo">
          <a href="/Dashboard">
            <img src="assets/images/logo.png" alt="logo" />
          </a>
        </div>
        <Header {...rest} />
        <Footer />
      </div>
      <Outlet />
    </main>
  ) : (
    <Navigate exact to={{ pathname: "/" }} />
  );
};
export default connect(mapStateToProps, { ...patientListAction })(PrivateRoute);
