import ApiCall from "../api/ApiCall";
import ApiLink from "../api/ApiLink";
const headerText = { "Access-Control-Allow-Origin": "*" };

const viewInvitation = async (params) => {
  const paramsData = {
    apiLink: ApiLink.getPatientInvitation,
    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const viewInvitationResult = await ApiCall.getApiCall(paramsData);

  return viewInvitationResult;
};

const denyPatient = async (params) => {
  const paramsData = {
    apiLink: ApiLink.declineInvitation + params.id,
    method: "post",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const denyPatientResult = await ApiCall.getApiCall(paramsData);
  return denyPatientResult;
};

const acceptPatient = async (params) => {
  const paramsData = {
    apiLink: ApiLink.acceptInvitation + params.id,
    method: "post",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const acceptPatientResult = await ApiCall.getApiCall(paramsData);
  return acceptPatientResult;
};

export default {
  viewInvitation,
  denyPatient,
  acceptPatient,
};
