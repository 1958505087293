import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import LayoutBeforLogin from "../common/components/LayoutBeforLogin";

export default class ForgetPasswordConfirm extends Component {
  render() {
    return (
      <LayoutBeforLogin>
        <div class="auth-box auth-notification" style={{ width: "500px" }}>
          <ToastContainer />
          <h1 class="auth-title text-center">Reset instructions sent</h1>
          <article>
            <p class="text-center">
              If your email exists, we’ve emailed you a link to reset your
              password.
            </p>
          </article>
          <a href="/" type="submit" class="btn btn-primary btn-block">
            Back to Login
          </a>
        </div>
      </LayoutBeforLogin>
    );
  }
}
