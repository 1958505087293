import userConstants from '../constants/userConstants'

const {
    RECENT_PATIENT_LIST_REQUEST,
    RECENT_PATIENT_LIST_SUCCESS,
    RECENT_PATIENT_LIST_FAILURE,
    QUICK_PATIENT_LIST_REQUEST,
    QUICK_PATIENT_LIST_SUCCESS,
    QUICK_PATIENT_LIST_FAILURE,
    DASHBOARD_OVERVIEW_REQUEST,
    DASHBOARD_OVERVIEW_SUCCESS,
    DASHBOARD_OVERVIEW_FAILURE,
    TOGGLE_STATE

} = userConstants

const initialState = {
    recentPatientList: {},
    quickPatientList: {},
    dashboardOverviewData: {},
    toogleState: {}
}

export default (state = initialState, action) => {

    switch (action.type) {

        case RECENT_PATIENT_LIST_REQUEST:
            return {
                ...state
            }
        case RECENT_PATIENT_LIST_SUCCESS:
            return {
                ...state,
                recentPatientList: { success: true, ...action.recentPatientAction }
            }
        case RECENT_PATIENT_LIST_FAILURE:
            return {
                ...state,
                recentPatientList: { success: false }
            }

        case QUICK_PATIENT_LIST_REQUEST:
            return {
                ...state
            }
        case QUICK_PATIENT_LIST_SUCCESS:
            return {
                ...state,
                quickPatientList: { success: true, ...action.quickPatientAction }
            }
        case QUICK_PATIENT_LIST_FAILURE:
            return {
                ...state,
                quickPatientList: { success: false }
            }
        case DASHBOARD_OVERVIEW_REQUEST:
            return {
                ...state
            }
        case DASHBOARD_OVERVIEW_SUCCESS:
            return {
                ...state,
                dashboardOverviewData: { success: true, ...action.dashboardAction }
            }
        case DASHBOARD_OVERVIEW_FAILURE:
            return {
                ...state,
                dashboardOverviewData: { success: false }
            }
        case TOGGLE_STATE + "true":
            return {
                ...state,
                toogleState: { toggle: true }
            }
        case TOGGLE_STATE + "false":
            return {
                ...state,
                toogleState: { toggle: false }
            }

        default:
            return {
                ...state
            }
    }

}
