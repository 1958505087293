import userConstants from "../constants/userConstants";
import patientIndexService from "../services/patientIndexService";

const {
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_FAILURE,
  VIEW_PATIENT_DETAILS_REQUEST,
  VIEW_PATIENT_DETAILS_SUCCESS,
  VIEW_PATIENT_DETAILS_FAILURE,
  SENT_HOMECARE_INSTRUCTION_REQUEST,
  SENT_HOMECARE_INSTRUCTION_SUCESS,
  SENT_HOMECARE_INSTRUCTION_FAILURE,
  VIEW_PATIENT_RECORD_REQUEST,
  VIEW_PATIENT_RECORD_SUCCESS,
  VIEW_PATIENT_RECORD_FAILURE,
  UPDATE_PATIENT_RECORD_REQUEST,
  UPDATE_PATIENT_RECORD_SUCESS,
  UPDATE_PATIENT_RECORD_FAILURE,
  GET_PATIENT_KID_REQUEST,
  GET_PATIENT_KID_SUCESS,
  GET_PATIENT_KID_FAILURE,
  GET_BRUSHING_OVERVIEW_REQUEST,
  GET_BRUSHING_OVERVIEW_SUCESS,
  GET_BRUSHING_OVERVIEW_FAILURE,

  TOGGLE_STATE,
} = userConstants;

const patientList = (params) => {
  const request = (patientListingAction) => ({
    type: PATIENT_LIST_REQUEST,
    patientListingAction,
  });
  const success = (patientListingAction) => ({
    type: PATIENT_LIST_SUCCESS,
    patientListingAction,
  });
  const failure = (error) => ({
    type: PATIENT_LIST_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientIndexService.patientListing(params);
      const patientListResult = res;
      if (patientListResult.status == 200) {
        dispatch(success(patientListResult));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};
const updateToogle = (toggleState) => {
  const toogle = (toogleAction) => ({
    type: TOGGLE_STATE + toggleState,
    toogleAction,
  });

  return async (dispatch) => {
    try {
      dispatch(toogle());
    } catch (e) {}
  };
};

const viewPatient = (params) => {
  const request = (viewPatientAction) => ({
    type: VIEW_PATIENT_DETAILS_REQUEST,
    viewPatientAction,
  });
  const success = (viewPatientAction) => ({
    type: VIEW_PATIENT_DETAILS_SUCCESS,
    viewPatientAction,
  });
  const failure = (error) => ({
    type: VIEW_PATIENT_DETAILS_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientIndexService.viewPatient(params);
      if (res.status == 200) {
        dispatch(success(res));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

const SentHomeCareInstruction = (params) => {
  const request = (sentHomeCareInstructionaction) => ({
    type: SENT_HOMECARE_INSTRUCTION_REQUEST,
    sentHomeCareInstructionaction,
  });
  const success = (sentHomeCareInstructionaction) => ({
    type: SENT_HOMECARE_INSTRUCTION_SUCESS,
    sentHomeCareInstructionaction,
  });
  const failure = (error) => ({
    type: SENT_HOMECARE_INSTRUCTION_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientIndexService.sentHomeCareInsturction(params);
      if (res.status == 200) {
        dispatch(success(res));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

//----------------------- View patients reacord-----------------------//

const viewPatientsRecords = (params) => {
  const request = (viewPatientsRecord) => ({
    type: VIEW_PATIENT_RECORD_REQUEST,
    viewPatientsRecord,
  });
  const success = (viewPatientsRecord) => ({
    type: VIEW_PATIENT_RECORD_SUCCESS,
    viewPatientsRecord,
  });
  const failure = (error) => ({
    type: VIEW_PATIENT_RECORD_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientIndexService.viewPatientsRecords(params);
      if (res.status == 200) {
        dispatch(success(res));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

//------------------update patient records--------------------//

const updatePatientsRecord = (params) => {
  const request = (updatePatientsRecord) => ({
    type: UPDATE_PATIENT_RECORD_REQUEST,
    updatePatientsRecord,
  });
  const success = (updatePatientsRecord) => ({
    type: UPDATE_PATIENT_RECORD_SUCESS,
    updatePatientsRecord,
  });
  const failure = (error) => ({
    type: UPDATE_PATIENT_RECORD_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientIndexService.updatePatientRecords(params);
      if (res.status == 200) {
        dispatch(success(res));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

// ----------------- get patient kid-------------------//

const getPatientkid = (params) => {
  const request = (getPatientkidAction) => ({
    type: GET_PATIENT_KID_REQUEST,
    getPatientkidAction,
  });
  const success = (getPatientkidAction) => ({
    type: GET_PATIENT_KID_SUCESS,
    getPatientkidAction,
  });
  const failure = (error) => ({
    type: GET_PATIENT_KID_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await patientIndexService.getPatientkid(params);

      if (response.status == 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

const getBrushingOverview = (params) => {
  const request = (getBrushingOverviewAction) => ({
    type: GET_BRUSHING_OVERVIEW_REQUEST,
    getBrushingOverviewAction,
  });
  const success = (getBrushingOverviewAction) => ({
    type: GET_BRUSHING_OVERVIEW_SUCESS,
    getBrushingOverviewAction,
  });
  const failure = (error) => ({
    type: GET_BRUSHING_OVERVIEW_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await patientIndexService.getBrushingOverview(params);

      if (response.status == 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

export default {
  patientList,
  viewPatient,
  updateToogle,
  SentHomeCareInstruction,
  viewPatientsRecords,
  updatePatientsRecord,
  getPatientkid,
  getBrushingOverview,
};
