import React from "react";

const LayoutBeforLogin = ({ component, ...rest }) => {
  return (
    <main class="main-container">
      <div class="auth-container">
        <div class="logo-con">
          <a href="/">
            <img src="assets/images/logo.png" alt="logo" />
          </a>
        </div>
        <component {...rest} />
      </div>
    </main>
  );
};

export default LayoutBeforLogin;
