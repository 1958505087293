import ApiLink from "../api/ApiLink";
import ApiCall from "../api/ApiCall";
const headerText = { "Access-Control-Allow-Origin": "*" };

const recentPatientList = async (params) => {
  const paramsData = {
    apiLink: ApiLink.recentPatientList,
    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };
  const recentPatientListResult = await ApiCall.getApiCall(paramsData);
  return recentPatientListResult;
};

const quickPatientList = async (params) => {
  let nameData = {
    name: params.name,
  };
  const paramsData = {
    data: nameData,
    apiLink: ApiLink.quickPatientList,
    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };
  const quickPatientListResult = await ApiCall.getApiCall(paramsData);

  return quickPatientListResult;
};

const dashboardOverview = async (params) => {
  let durationData = {
    duration: params.duration,
  };
  const paramsData = {
    data: durationData,
    apiLink: ApiLink.dashboardOverview,
    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };
  const dashboardOverviewResult = await ApiCall.getApiCall(paramsData);
  return dashboardOverviewResult;
};

export default {
  recentPatientList,
  quickPatientList,
  dashboardOverview,
};
