import ApiCall from "../api/ApiCall";
import ApiLink from "../api/ApiLink";

const login = async (loginData) => {
  const paramsData = {
    data: loginData,
    apiLink: ApiLink.login,
    method: "post",
  };
  const userLoginResult = await ApiCall.postApiCall(paramsData);

  return userLoginResult;
};

const signup = async (signUpData) => {
  const paramsData = {
    data: signUpData,
    apiLink: ApiLink.signup,
    method: "post",
  };

  const signUpResult = await ApiCall.postApiCall(paramsData);

  return signUpResult;
};

const forgetPassword = async (ForgetData) => {
  const paramsData = {
    data: ForgetData,
    apiLink: ApiLink.forgetPassword,
    method: "post",
  };
  const forgetPasswordResult = await ApiCall.postApiCall(paramsData);
  return forgetPasswordResult;
};

export default {
  login,
  signup,
  forgetPassword,
};
