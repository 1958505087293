import ApiLink from "../api/ApiLink";
import ApiCall from "../api/ApiCall";
const headerText = { "Access-Control-Allow-Origin": "*" };

const patientListing = async (params) => {
  let nameData = {
    name: params.name,
  };

  const paramsData = {
    data: nameData,
    apiLink: ApiLink.getPatients,
    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const quickPatientListResult = await ApiCall.getApiCall(paramsData);

  return quickPatientListResult;
};

const viewPatient = async (params) => {
  const paramsData = {
    // data: '',
    apiLink: ApiLink.viewPatient + params.id,
    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };
  const viewPatientResult = await ApiCall.getApiCall(paramsData);

  return viewPatientResult;
};

const sentHomeCareInsturction = async (params) => {
  let data = {
    patient_id: params.patient_id,
    message: params.message,
  };
  const paramsData = {
    data: data,
    apiLink: ApiLink.sentHomeCareInstruction,
    method: "post",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };
  const viewPatientResult = await ApiCall.getApiCall(paramsData);
  return viewPatientResult;
};

const updatePatientRecords = async (params) => {
  const paramsData = {
    // data: '',
    apiLink:
      ApiLink.updatePatientRecords +
      params.id +
      "&note=" +
      params.note +
      "&note_disable=" +
      params.note_disable,

    method: "put",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const updatePatientRecords = await ApiCall.getApiCall(paramsData);
  return updatePatientRecords;
};

const getPatientkid = async (params) => {
  const paramsData = {
    // data: '',
    apiLink: ApiLink.getpatientkidRecords + params.patient_id,

    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const getPatientkid = await ApiCall.getApiCall(paramsData);

  return getPatientkid;
};

const viewPatientsRecords = async (params) => {
  if (!params.kid_id == "") {
    const paramsData = {
      // data: '',

      apiLink:
        ApiLink.viewPatientRecord +
        params.patient_id +
        "&date=" +
        params.date +
        "&kid_id=" +
        params.kid_id,
      method: "get",
      headers: {
        Authorization: `Bearer ${params.token}`,
        headerText,
      },
    };
    const viewPatientRecords = await ApiCall.getApiCall(paramsData);
    return viewPatientRecords;
  } else {
    const paramsData = {
      // data: '',

      apiLink:
        ApiLink.viewPatientRecord + params.patient_id + "&date=" + params.date,
      method: "get",
      headers: {
        Authorization: `Bearer ${params.token}`,
        headerText,
      },
    };
    const viewPatientRecords = await ApiCall.getApiCall(paramsData);
    return viewPatientRecords;
  }
};

const getBrushingOverview = async (params) => {
  if (!params.kid_id == "") {
    const paramsData = {
      // data: '',
      apiLink:
        ApiLink.getBrushingOverview +
        params.patient_id +
        "&from=" +
        params.from +
        "&to=" +
        params.to +
        "&kid_id=" +
        params.kid_id,

      method: "get",
      headers: {
        Authorization: `Bearer ${params.token}`,
        headerText,
      },
    };

    const getBrushingOverview = await ApiCall.getApiCall(paramsData);

    return getBrushingOverview;
  } else {
    const paramsData = {
      // data: '',
      apiLink:
        ApiLink.getBrushingOverview +
        params.patient_id +
        "&from=" +
        params.from +
        "&to=" +
        params.to,

      method: "get",
      headers: {
        Authorization: `Bearer ${params.token}`,
        headerText,
      },
    };

    const getBrushingOverview = await ApiCall.getApiCall(paramsData);

    return getBrushingOverview;
  }
};

export default {
  patientListing,
  viewPatient,
  sentHomeCareInsturction,
  viewPatientsRecords,
  updatePatientRecords,
  getPatientkid,
  getBrushingOverview,
};
