import userConstants from "../constants/userConstants";
import settingsService from "../services/settingsService";
import toastFunction from "../components/common/toast/toastFunction";

const {
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,

  GET_DENTIST_INFO_REQUEST,
  GET_DENTIST_INFO_SUCCESS,
  GET_DENTIST_INFO_FAILURE,
  TOGGLE_STATE,
} = userConstants;

const updateProfile = (params, token) => {
  const request = (updateProfileAction) => ({
    type: UPDATE_PROFILE_REQUEST,
    updateProfileAction,
  });
  const success = (updateProfileAction) => ({
    type: UPDATE_PROFILE_SUCCESS,
    updateProfileAction,
  });
  const failure = (error) => ({
    type: UPDATE_PROFILE_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await settingsService.updateProfile(params, token);
      if (res.status == 200) {
        dispatch(success(res));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const changePassword = (params) => {
  const request = (changePasswordAction) => ({
    type: CHANGE_PASSWORD_REQUEST,
    changePasswordAction,
  });
  const success = (changePasswordAction) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    changePasswordAction,
  });
  const failure = (error) => ({
    type: CHANGE_PASSWORD_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());

      const res = await settingsService.changePassword(params);
      if (res.status == 200) {
        dispatch(success(res));
        setTimeout(() => {
          toastFunction(res.data.success, "#8ae22a", "#000000");
        }, 500);
      } else {
        dispatch(failure(false));
        setTimeout(() => {
          toastFunction(
            "Current password does not match",
            "#8ae22a",
            "#000000"
          );
        }, 500);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

const getDentistInfo = (params) => {
  const request = (getdentistInfoAction) => ({
    type: GET_DENTIST_INFO_REQUEST,
    getdentistInfoAction,
  });
  const success = (getdentistInfoAction) => ({
    type: GET_DENTIST_INFO_SUCCESS,
    getdentistInfoAction,
  });
  const failure = (error) => ({
    type: GET_DENTIST_INFO_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await settingsService.getDentistDetails(params);
      const userdata = res;
      if (res.status == 200) {
        dispatch(success(res));
        // localStorage.removeItem('user')
        localStorage.setItem("user", JSON.stringify(userdata.data));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  updateProfile,
  changePassword,
  getDentistInfo,
};
