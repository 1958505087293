import userConstants from "../constants/userConstants";
const {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  FORGET_REQUEST,
  FORGET_SUCCESS,
  FORGET_FAILURE,
  TOKEN_VALUE,
} = userConstants;

const initialState = {
  loginDetails: {},
  signUpDetails: {},
  forgetDetails: {},
  tokenValue: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginDetails: { success: true, ...action.loginAction },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginDetails: { success: false },
      };
    case SIGNUP_REQUEST:
      return {
        ...state,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signUpDetails: { success: true, ...action.signUpAction },
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        signUpDetails: { success: false },
      };
    case FORGET_REQUEST:
      return {
        ...state,
      };
    case FORGET_SUCCESS:
      return {
        ...state,
        forgetDetails: { success: true, ...action.forgetAction },
      };
    case FORGET_FAILURE:
      return {
        ...state,
        forgetDetails: { success: false },
      };
    case TOKEN_VALUE:
      return {
        ...state,
        tokenValue: state.tokenValue,
      };

    default:
      return state;
  }
};
