import React, { Component } from "react";
import { connect } from "react-redux";
import ConsultationAction from "../../actions/ConsultationAction";
import { DebounceInput } from "react-debounce-input";
import Loader from "../common/toast/Loader";
import moment from "moment";
import { th } from "date-fns/locale";

const mapStatetoProps = (state) => (
  console.log("consoultionbbb", state),
  {
    contactListing: state.ConsultationReducer.contactListData.data,
    MessageList: state.ConsultationReducer.loadMessaging.data,
    SentMessageData: state.ConsultationReducer.sentMessage.data,
  }
);

class ConsultationIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactListingData: [],
      MessageListingData: [],
      toggle: true,
      loader: false,
      names: [],
      divSelect: "",
      InputMessages: [],
      patientId: "",
      avatarImage: "",
      SentMsgData: {},
    };
  }

  componentDidMount = async () => {
    await this.setState({ loader: true });

    let token = await localStorage.getItem("token");
    let paramData = {
      token: token,
    };
    await this.props.contactList(paramData);

    await this.setState({
      contactListingData: this.props.contactListing,
      loader: false,
    });

    await this.loopnames(this.state.contactListingData);
  };

  LoadMessages = async (id) => {
    await this.setState({ loader: true });
    let token = await localStorage.getItem("token");
    const paramData = {
      interlocutor_id: id,
      token: token,
      per_page: 20,
      page: 1,
    };
    await this.props.LoadMessages(paramData);

    await this.setState({
      MessageListingData: this.props.MessageList,
      InputMessages: this.props.MessageList.messages,
      loader: false,
    });

    await this.setState({
      patientId: this.state.MessageListingData.interlocutor.id,
      avatarImage: this.state.MessageListingData.interlocutor.avatar,
    });
  };

  sentMessage = async (id) => {
    await this.setState({ loader: true });
    let token = await localStorage.getItem("token");
    const paramData = {
      message: "hii",
      token: token,
      receiver_id: 68,
    };
    await this.props.sentMessage(paramData);
    await this.setState({
      SentMsgData: this.props.SentMessageData,
      loader: false,
    });
  };

  loopnames = async (dataForArray) => {
    let nameArrayTemp = [];
    dataForArray.map(async (item) => {
      let data = {
        name: item.name,
        avatar: item.avatar,
        id: item.id,
        last_message: item.last_message,
        last_message_at: item.last_message_at,
        unread_message_exist: item.unread_message_exist,
      };
      nameArrayTemp.push(data);
    });

    const sorttedArray = await this.getSections(nameArrayTemp);

    await this.setState({ names: sorttedArray });
  };

  getSections = (startsWithN) => {
    if (startsWithN.length === 0) {
      return [];
    }
    return Object.values(
      startsWithN.reduce((acc, word) => {
        let firstLetter = word.name[0].toLocaleUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = { title: firstLetter, data: [word] };
        } else {
          acc[firstLetter].data.push(word);
        }
        return acc;
      }, {})
    );
  };

  render() {
    return (
      <div class="dash-body">
        {this.state.loader && <Loader />}
        <div class="header">
          <div class="header-left">
            <div class="title-con">
              <h1 class="page-title">Consulation page</h1>
            </div>
          </div>
          <div class="header-right"></div>
        </div>

        <div class="content columns column-2 consultWrapper">
          <div class="content-column d-flex flex-column patientList">
            <div class="search-fill">
              <div class="input-group">
                <DebounceInput
                  class="form-control"
                  name=""
                  placeholder="Search Patients"
                  minLength={0}
                  debounceTimeout={1000}
                />
                <div class="input-group-append">
                  <img
                    src="assets/images/serach-icon.svg"
                    alt=""
                    class="input-group-icon"
                  />
                </div>
              </div>
            </div>
            <div class="oy-auto">
              {this.state.searchLoader ? (
                <div className="text-center">
                  <img
                    src="assets/images/loaderGif.gif"
                    style={{ width: 100, height: 100 }}
                  />
                </div>
              ) : this.state.names && this.state.names.length > 0 ? (
                this.state.names.map((item, index) => {
                  return (
                    <ul class="list-group ">
                      <li class="list-group-item sort-category">
                        {item.title}
                      </li>

                      {item.data.map((userData) => {
                        var dateFormat = require("dateformat");
                        var listdata = "";

                        if (userData.name == null) {
                          var listdata = "";
                        } else {
                          var today = new Date(),
                            date =
                              today.getFullYear() +
                              "-" +
                              (today.getMonth() + 1) +
                              "-" +
                              today.getDate();
                          var ystrdy =
                            today.getFullYear() +
                            "-" +
                            (today.getMonth() + 1) +
                            "-" +
                            (today.getDate() - 1);
                          var daybefore =
                            today.getFullYear() +
                            "-" +
                            (today.getMonth() + 1) +
                            "-" +
                            (today.getDate() - 2);

                          var givendate = dateFormat(
                            userData.last_message_at,
                            "yyyy-mm-dd"
                          );
                          var today = dateFormat(date, "yyyy-mm-dd");
                          var ystrdy = dateFormat(ystrdy, "yyyy-mm-dd");
                          var daybefore = dateFormat(daybefore, "yyyy-mm-dd");

                          if (givendate == today) {
                            listdata = dateFormat(givendate, "HH:mm TT");
                          } else if (givendate == ystrdy) {
                            listdata = "Yestrday";
                          } else if (givendate == daybefore) {
                            listdata = dateFormat(givendate, "dddd");
                          } else {
                            listdata = dateFormat(givendate, "yyyy-mm-dd");
                          }
                        }

                        return (
                          <li
                            class={`list-group-item ${
                              userData.id == this.state.divSelect
                                ? "selected"
                                : ""
                            }`}
                          >
                            <div
                              class="media"
                              onClick={() => {
                                this.LoadMessages(userData.id);
                              }}
                            >
                              <div class="media-avatar">
                                {userData.avatar === null ? (
                                  <img
                                    src="assets/images/icn_avatar.png"
                                    alt="User image"
                                    class=""
                                  />
                                ) : (
                                  <img
                                    src={userData.avatar}
                                    alt="User image"
                                    class=""
                                  />
                                )}
                              </div>
                              <div class="media-body">
                                <div class="media-header">
                                  <h4>{userData.name}</h4>
                                  {userData.last_message_at === null ? (
                                    <small class="timeDetails"> </small>
                                  ) : (
                                    <small class="timeDetails">
                                      {listdata}
                                    </small>
                                  )}
                                </div>
                                <p>{userData.last_message}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  );
                })
              ) : (
                <ul class="list-group list-bottom-open">
                  <ul>
                    <li class="list-group-item">
                      <div class="media">
                        <div
                          class="media-body"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <h4>No Patients </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </ul>
              )}
            </div>
          </div>
          <div class="content-column oy-auto patientMessage">
            {this.state.InputMessages && this.state.InputMessages.length > 0
              ? this.state.InputMessages.map((item, index) => {
                  return (
                    <div class="chatWrapper">
                      <div class="chatImg">
                        {this.state.avatarImage === "" ||
                        this.state.avatarImage === null ? (
                          <img
                            src="assets/images/icn_avatar.png"
                            alt="User image"
                            class=""
                          />
                        ) : (
                          <img
                            src={this.state.avatarImage}
                            alt="User image"
                            class=""
                          />
                        )}
                      </div>
                      <div class="chatDetails">
                        <h4>{item.name}</h4>
                        <ul>
                          <li>
                            <div class="chatOuter">
                              <p>{item.message}</p>
                            </div>
                            <small>10:24 Pm</small>
                          </li>

                          <li>
                            <div class="chatOuter">
                              <p>{item.message}</p>
                            </div>
                            <small>10:24 Pm</small>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })
              : console.log("emptyyyyyy")}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStatetoProps, { ...ConsultationAction })(
  ConsultationIndex
);
