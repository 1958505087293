import React from "react";
import "./App.css";
import { Routes, Route, Navigate, redirect } from "react-router-dom";
import Login from "./components/auth/Login";
import PublicRoute from "./components/common/components/publicRoute";
import PrivateRoute from "./components/common/components/privateRoute";

import SignUp from "./components/auth/Signup";
import SignupPracticeInfo from "./components/auth/SignupPracticeInfo";
import TermsCondition from "./components/auth/TermsCondition";
import SignupComplete from "./components/auth/SignupComplete";
import ForgetPassword from "./components/auth/ForgetPassword";
import ForgetPasswordConfirm from "./components/auth/ForgetPasswordConfirm";
import Dashboard from "./components/dashboard/Dashboard";
import SettingsIndex from "./components/dashboard/SettingsIndex";
import PatientsIndex from "./components/dashboard/PatientsIndex";
import InvitationIndex from "./components/dashboard/InvitationIndex";
import ConsultationIndex from "./components/dashboard/ConsultationIndex";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/SignupPracticeInfo" element={<SignupPracticeInfo />} />
      <Route path="/TermsCondition" element={<TermsCondition />} />
      <Route path="/SignupComplete" element={<SignupComplete />} />
      <Route path="/ForgetPassword" element={<ForgetPassword />} />
      <Route
        path="/ForgetPasswordConfirm"
        element={<ForgetPasswordConfirm />}
      />
      {/* <Route
        path="/Dashboard"
        element={<SettingsIndex />}
        loader={() => {
          if (!localStorage.getItem("token")) {
            redirect("/");
          }
        }}
      /> */}
      <Route
        element={<PrivateRoute path="/Dashboard" element={<Dashboard />} />}
      >
        <Route path="/Dashboard" element={<Dashboard />} />
      </Route>
      <Route
        element={
          <PrivateRoute path="/SettingsIndex" element={<SettingsIndex />} />
        }
      >
        <Route path="/SettingsIndex" element={<SettingsIndex />} />
      </Route>
      <Route
        element={
          <PrivateRoute path="/PatientsIndex" element={<PatientsIndex />} />
        }
      >
        <Route path="/PatientsIndex" element={<PatientsIndex />} />
      </Route>
      <Route
        element={
          <PrivateRoute path="/InvitationIndex" element={<InvitationIndex />} />
        }
      >
        <Route path="/InvitationIndex" element={<InvitationIndex />} />
      </Route>
      <Route
        element={
          <PrivateRoute path="/ConsultationIndex" element={<PatientsIndex />} />
        }
      >
        <Route path="/ConsultationIndex" element={<ConsultationIndex />} />
      </Route>{" "}
    </Routes>
  );
}

export default App;
