import React, { Component } from 'react';
import ReactDOM from 'react-dom'; 
// import '../assets/css/custom-temp.css';


// const modalWrapper = {
//     position: 'fixed',
//     width: '100%',
//     height: '100%',
//     background: 'rgba(0,0,0,0.5)',
//     left: 0,
//     top: 0,
//     overflow: 'auto',
//     zIndex: 9999
// };

const modalWrapper = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.5)',
    left: 0,
    top: 0,
    overflow: 'auto',
    zIndex: 9999,
    opacity: 2
};


const modalBox =  {
    position: 'relative',
    width: 'auto',
    margin: '10% auto',
    background: '#fff',
    padding: '15px',
};

class Modal extends Component {

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
        document.querySelector('.modal-backdrop') &&
        document.querySelector('.modal-backdrop')
            .remove();
        document.body.style.overflow = null;
    }

    render() {
        document.body.classList.add('modal-open');
        return (ReactDOM.createPortal(
            <div style={modalWrapper} onClick={() => this.props.onCancel()}>
            <div className="bgTRans" style={modalBox} onClick={(e) => e.stopPropagation()}>
                {this.props.body}
            </div>
        </div>
                , document.querySelector('#commonModal')
            )
        );
    }
}

export default Modal;