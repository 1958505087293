import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authAction from "../../actions/authAction";
import Loader from "../../components/common/toast/Loader";
import LayoutBeforLogin from "../common/components/LayoutBeforLogin";
import Checkbox from "@mui/material/Checkbox";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      loader: false,
      showPassword: false,
    };
  }

  login = async (e) => {
    /* eslint-disable */
    // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?(\.\w{2,3})+)*(\.\w{2,3})+$/;
    /* eslint-enable */
    e.preventDefault();
    if (this.state.email.trim() === "") {
      this.setState({ emailErrorMsg: "Please enter email" });
    } else if (this.state.email !== "" && !reg.test(this.state.email)) {
      this.setState({ emailErrorMsg: "Please enter valid email" });
    } else if (this.state.password.trim() === "") {
      this.setState({ passwordErrorMsg: "Please enter password" });
    } else {
      this.setState({ loader: true });
      const loginData = {
        email: this.state.email,
        password: this.state.password.trim(),
      };
      await this.props.login(loginData);
      this.setState({ loader: false });
      //api for login
    }
  };
  handleChange = (e) => {
    if (e.target.name === "email") {
      this.setState({ email: e.target.value, emailErrorMsg: "" });
    } else if (e.target.name === "password") {
      this.setState({ password: e.target.value, passwordErrorMsg: "" });
    }
  };

  handleCheck = (e) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    return (
      <LayoutBeforLogin>
        <div class="auth-box sign-in" style={{ width: "500px" }}>
          {this.state.loader && <Loader />}
          <ToastContainer />
          <h1 class="auth-title">Sign In</h1>
          <p class="auth-title-sub">
            New user?
            <a class="new-acc-link" href="/SignUp">
              {" "}
              Create an account
            </a>
          </p>
          <form onSubmit={this.login}>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                class="form-control"
                placeholder="Email"
                id=""
                name="email"
                value={this.state.email.trim()}
                onChange={(e) => this.handleChange(e)}
              />
              <span style={{ color: "red" }}>{this.state.emailErrorMsg}</span>
            </div>
            <div class="form-group">
              <label for="pwd">Password</label>
              <input
                type={this.state.showPassword ? "text" : "password"}
                class="form-control"
                placeholder="Password"
                id=""
                value={this.state.password.trim()}
                name="password"
                onChange={(e) => this.handleChange(e)}
              />
              <span style={{ color: "red" }}>
                {this.state.passwordErrorMsg}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                onChange={(e) => this.handleCheck(e)}
                checked={this.state.showPassword}
              />

              <p>Show Password</p>
            </div>
            <div class="form-action">
              <button type="submit" class="btn btn-primary btn-block">
                Login
              </button>
            </div>
          </form>
          <div class="text-center link-footer">
            <p class="text-center">
              <a href="/ForgetPassword">Forgot Password</a>
            </p>
          </div>
        </div>
      </LayoutBeforLogin>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { ...authAction })(Login);
