import React, { Component } from "react";
import { connect } from "react-redux";
import authAction from "../../actions/authAction";
import { ToastContainer } from "react-toastify";
import Loader from "../common/toast/Loader";
import LayoutBeforLogin from "../common/components/LayoutBeforLogin";

const mapStateToProps = (state) => {
  console.log("state", state);
};

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailErrorMsg: "",
      loader: false,
    };
  }

  handleChange = (e) => {
    if (e.target.name === "email") {
      this.setState({ email: e.target.value, emailErrorMsg: "" });
    }
  };
  submitEmail = async (e) => {
    /* eslint-disable */
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?(\.\w{2,3})+)*(\.\w{2,3})+$/;
    /* eslint-enable */
    e.preventDefault();
    if (this.state.email.trim() === "") {
      this.setState({ emailErrorMsg: "Please enter email" });
    } else if (this.state.email !== "" && !reg.test(this.state.email)) {
      this.setState({ emailErrorMsg: "Please enter valid email" });
    } else {
      this.setState({ loader: true });
      const forgetData = {
        email: this.state.email.trim(),
      };
      await this.props.forgetPassword(forgetData);
      this.setState({ loader: false });
      //api integration
    }
  };

  render() {
    return (
      <LayoutBeforLogin>
        <div class="auth-box reset-password" style={{ width: "500px" }}>
          {this.state.loader && <Loader />}
          <ToastContainer />
          <article>
            <p class="text-center">
              <b>Forgot Password? Let’s reset it</b>
            </p>
          </article>
          <form onSubmit={this.submitEmail}>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                class="form-control"
                placeholder="Email"
                id=""
                name="email"
                value={this.state.email.trim()}
                onChange={(e) => this.handleChange(e)}
              />
              <span style={{ color: "red" }}>{this.state.emailErrorMsg}</span>
            </div>
            <div class="form-action">
              <button type="submit" class="btn btn-primary btn-block">
                Reset
              </button>
            </div>
          </form>
          <div class="text-center link-footer">
            <p>
              <a href="/">Back to Login</a>
            </p>
          </div>
        </div>
      </LayoutBeforLogin>
    );
  }
}
export default connect(mapStateToProps, { ...authAction })(ForgetPassword);
