import React, { Component } from "react";
import { connect } from "react-redux";
import settingsAction from "../../actions/settingsAction";
// import Avatar from 'react-avatar-edit'
import Loader from "../common/toast/Loader";
// import { tr, sr, th } from 'date-fns/locale';
// import history from '../../config/history';
// import fs from 'fs';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
// import axios from 'axios'

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// import { callbackify } from 'util';

const mapStatetoProps = (state) => ({
  passwordchangedata: state.settingsIndex?.changePasswordDetails,
  updateDetailss: state.settingsIndex?.updateProfileDetails,
  dentistDetails: state.settingsIndex?.dentistInforDetails.data,
});

class SettingsIndex extends Component {
  constructor(props) {
    super(props);
    const src = "./example/einshtein.jpg";

    this.state = {
      preview: null,

      cropedstatus: "",
      loader: false,
      changepassword: {},
      oldpassword: "",
      currnetpassword: "",
      curentconfirmationpassword: "",
      oldpasswordErrorMsg: "",
      currnetpasswordErrorMsg: "",
      ConfirmationErrorMsg: "",
      fullName: "",
      practiceName: "",
      address: "",
      Email: "",
      signupData: {},
      practiceAddress: "",
      practicePhoneNumber: "",
      practicePhoneNumberError: "",
      fullNameErrorMsg: "",
      practiceNameErrorMsg: "",
      emailErrorMsg: "",
      practiceAddressErrorMsg: "",
      avatar: "",
      file: null,
      src: null,
      crop: {
        unit: "%",
        width: 30,
        height: 30,
        x: 25,
        y: 25,
        aspect: 1,
      },
      newcropedImagUrl: "",
      originalImagUrl: "",
      updatedInfo: [],
      selected: false,
      addressErrorMsg: "",
      lat: {},
      practice_lat: "",
      practice_lon: "",
      DentistProfieDetails: [],
      orginavatar: "",
      base64orgin: "",
      nocrop: false,
      buttonAlert: false,
      originalAvatar: "",
      save: false,
      imagerrormsg: false,
      base64orginNew: "",
      iscancel: false,
      cropnew: {
        unit: "px",
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        aspect: 1,
      },
    };
  }

  async componentDidMount() {
    await this.getDentistdetails();
  }
  async getDentistdetails() {
    await this.setState({ signupData: this.props.history?.location.state });

    await this.setState({ loader: true });

    let token = await localStorage.getItem("token");
    let paramData = {
      token: token,
    };
    await this.props.getDentistInfo(paramData);
    await this.setState({
      DentistProfieDetails: this.props.dentistDetails,
      loader: false,
    });

    await this.setState({
      fullName: this.state.DentistProfieDetails.name,
      practiceName: this.state.DentistProfieDetails.practice_name,
      address: this.state.DentistProfieDetails.practice_address,
      Email: this.state.DentistProfieDetails.email,
      practicePhoneNumber:
        this.state.DentistProfieDetails.practice_phone_number,
    });

    if (!this.state.DentistProfieDetails.avatar == "") {
      await this.setState({
        avatar: this.state.DentistProfieDetails.avatar,
        originalAvatar: this.state.DentistProfieDetails.avatar,
      });
    }
    if (!this.state.DentistProfieDetails.avatar_origin == "") {
      await this.setState({
        orginavatar: this.state.DentistProfieDetails.avatar_origin,
      });

      this.convertURLtoBase64(this.state.orginavatar).then((dataURL) => {
        this.setState({ base64orgin: dataURL, base64orginNew: dataURL });
      });
    }
  }

  convertURLtoBase64 = (path) =>
    fetch(path)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  handleChangePlace = (address) => {
    this.setState({ address: address, selected: false, addressErrorMsg: "" });
  };

  handleSelect = async (address) => {
    this.setState({ address: address });

    await geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ lat: latLng, selected: true, addressErrorMsg: "" });
      })
      .catch((error) => console.error("Error", error));
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      //   var sizeimage = e.target.files[0].size;

      var _URL = window.URL || window.webkitURL;
      var file, img, img_width, img_height;

      if ((file = e.target.files[0])) {
        img = new Image();

        var self = this;
        // var event = e;
        img.onload = function () {
          if (this.width >= 200 && this.height >= 200) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
              self.setState({ src: reader.result })
            );
            reader.readAsDataURL(file);
          } else {
            self.setState({ imagerrormsg: true, src: null });
          }
        };
        img.src = _URL.createObjectURL(file);
      }
    }

    this.setState({ base64orgin: "" });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
    this.setState({ originalImagUrl: image.src, imagerrormsg: false });
  };

  save(crop) {
    this.setState({ save: true });
    this.onCropComplete(crop);
  }
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };
  onCropCompleteNew = (crop) => {
    this.makeClientCrop(crop);

    if (crop.height == 0 && crop.width == 0) {
      this.setState({ nocrop: true });
    } else {
      this.setState({ nocrop: false, buttonAlert: false });
    }
  };
  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  onCropChange2 = (crop, percentCrop) => {
    // this.setState({ crop });
  };

  async makeClientCrop(crop) {
    var self = this;

    if (this.imageRef && crop && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });

      this.setState({ newcropedImagUrl: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  cancelAvatar() {
    this.setState({
      imagerrormsg: false,
      base64orgin: this.state.base64orginNew,
      croppedImageUrl: undefined,
      iscancel: true,
    });
  }

  statuschanged() {
    this.setState({ cropedstatus: "sucess" });
  }

  changePassword = async (e) => {
    e.preventDefault();

    if (this.state.oldpassword.trim() === "") {
      this.setState({ oldpasswordErrorMsg: "Please enter Current Password" });
    } else if (this.state.currnetpassword.trim() === "") {
      this.setState({ currnetpasswordErrorMsg: "Please enter New Password" });
    } else if (this.state.curentconfirmationpassword.trim() === "") {
      this.setState({
        currnetpasswordErrorMsg: "Please enter Confirm Password",
      });
    } else if (
      this.state.curentconfirmationpassword.trim() !==
      this.state.currnetpassword.trim()
    ) {
      this.setState({ ConfirmationErrorMsg: "Confirm Password Mismatch" });
    } else {
      await this.setState({ loader: true });
      let token = await localStorage.getItem("token");
      const paramData = {
        oldpassword: this.state.oldpassword,
        newpassword: this.state.currnetpassword,
        confirmationpassword: this.state.curentconfirmationpassword,
        token: token,
      };
      await this.props.changePassword(paramData);

      await this.setState({
        changepassword: this.props.passwordchangedata,
        loader: false,
      });

      if (this.state.changepassword.success === true) {
        window.location.reload(true);

        await this.getDentistdetails();
      }
    }
  };

  changeBasicProfile = async (e) => {
    e.preventDefault();
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?(\.\w{2,3})+)*(\.\w{2,3})+$/;
    if (this.state.fullName.trim() === "") {
      this.setState({ fullNameErrorMsg: "Please enter your Full Name" });
    } else if (this.state.practiceName.trim() === "") {
      this.setState({
        practiceNameErrorMsg: "Please enter your Practice Name",
      });
    } else if (this.state.practicePhoneNumber.trim() === "") {
      this.setState({
        practicePhoneNumberError: "Please enter your Phone Number",
      });
    } else if (this.state.Email !== "" && !reg.test(this.state.Email)) {
      this.setState({ emailErrorMsg: "Please enter valid email" });
    } else if (this.state.address.trim() === "") {
      this.setState({ addressErrorMsg: "Please enter your Pratice Address" });
    } else {
      await this.setState({ loader: true });
      const uri = this.state.newcropedImagUrl;

      const response = await fetch(uri);
      const blob = await response.blob();
      var files = new File([blob], "avatar.png", {
        type: "contentType",
        lastModified: Date.now(),
      });

      let token = await localStorage.getItem("token");
      let uploadDetails = new FormData();

      if (!uri == "") {
        await uploadDetails.append("img_avatar", files);
      }

      if (!this.state.originalImagUrl == "") {
        var str2 = this.state.originalImagUrl;

        var substr = str2.split("base64,").pop();
        var b64toBlob = require("b64-to-blob");
        var contentType = "image/png";
        var blobs = b64toBlob(substr, contentType);
        var blobUrl = URL.createObjectURL(blobs);

        const response = await fetch(blobUrl);
        const blob = await response.blob();
        var filess = new File([blob], "avatar.png", {
          type: "contentType",
          lastModified: Date.now(),
        });
        await uploadDetails.append("img_avatar_origin", filess);
      }
      var lat;
      var long;
      if (this.state.lat.lat === undefined) {
        lat = "40.765005";
      } else {
        lat = this.state.lat.lat;
      }

      if (this.state.lat.lng === undefined) {
        long = "73.9752094";
      } else {
        long = this.state.lat.lng;
      }
      await uploadDetails.append("email", this.state.Email.trim());
      await uploadDetails.append("name", this.state.fullName.trim());
      await uploadDetails.append(
        "practice_name",
        this.state.practiceName.trim()
      );
      await uploadDetails.append("practice_address", this.state.address.trim());
      await uploadDetails.append(
        "practice_phone_number",
        this.state.practicePhoneNumber.trim()
      );
      await uploadDetails.append("practice_lat", lat);
      await uploadDetails.append("practice_lon", long);

      await this.props.updateProfile(uploadDetails, token);

      await this.setState({
        updatedInfo: this.props.updateDetailss,
        loader: false,
      });

      if (this.state.updatedInfo.success === true) {
        window.location.reload(true);
        //await this.getDentistdetails()
      }
    }
  };

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  handleChange = (e) => {
    if (e.target.name === "oldpassword") {
      this.setState({ oldpassword: e.target.value, oldpasswordErrorMsg: "" });
    } else if (e.target.name === "currunt password") {
      this.setState({
        currnetpassword: e.target.value,
        currnetpasswordErrorMsg: "",
      });
    } else if (e.target.name === "confirmation password") {
      this.setState({
        curentconfirmationpassword: e.target.value,
        ConfirmationErrorMsg: "",
      });
    }
  };
  handleChangeProfile = (e) => {
    if (e.target.name === "Full Name") {
      this.setState({ fullName: e.target.value, fullNameErrorMsg: "" });
    } else if (e.target.name === "Practice Name") {
      this.setState({ practiceName: e.target.value, practiceNameErrorMsg: "" });
    } else if (e.target.name === "Email") {
      this.setState({ Email: e.target.value, emailErrorMsg: "" });
    } else if (e.target.name === "Practice Address") {
      this.setState({
        practiceAddress: e.target.value,
        practiceAddressErrorMsg: "",
      });
    } else if (e.target.name === "Practice phone") {
      this.setState({
        practicePhoneNumber: e.target.value,
        practicePhoneNumberError: "",
      });
    }
  };

  handleChangeevent(event) {
    this.setState({ preview: "" });
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  modalopen() {
    this.setState({
      buttonAlert: "",
      nocrop: "",
      iscancel: false,
      crop: this.state.cropnew,
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div class="dash-body ">
        {this.state.loader && <Loader />}
        <div class="header">
          <div class="header-left">
            <div class="title-con">
              <h1 class="page-title">Settings</h1>
            </div>
          </div>
          <div class="header-right"></div>
        </div>

        <div class="content settings-wrap">
          <div class="content-inner">
            <div class="row">
              <div class="col-md-7">
                <div class="card h-100 c-shadow">
                  <div class="card-header">
                    <h3 class="card-title">Basic Information</h3>
                  </div>
                  <div class="card-body">
                    <form
                      class="setting-form-container auth-box"
                      onSubmit={this.changeBasicProfile}
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="profile-picture">
                            {croppedImageUrl === undefined ||
                            croppedImageUrl == "" ||
                            this.state.iscancel == true ? (
                              <img
                                src={this.state.avatar}
                                class=" img-responsive img-thumbnail"
                                id="item-img-output"
                              />
                            ) : (
                              <div>
                                {croppedImageUrl && (
                                  <img
                                    alt="Crop"
                                    style={{ maxWidth: "100%" }}
                                    src={croppedImageUrl}
                                  />
                                )}
                              </div>
                            )}
                            <figure>
                              <figcaption>
                                <i
                                  class="fa fa-pencil"
                                  data-toggle="modal"
                                  data-target="#cropImagePop"
                                  onClick={() => {
                                    this.modalopen();
                                  }}
                                ></i>
                              </figcaption>
                            </figure>
                          </div>
                          <div class="form-group">
                            <label for="inputEmail4">Full Name</label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputEmail4"
                              value={this.state.fullName}
                              placeholder="Full Name"
                              name="Full Name"
                              onChange={(e) => this.handleChangeProfile(e)}
                            />
                            <span style={{ color: "red" }}>
                              {this.state.fullNameErrorMsg}
                            </span>
                          </div>

                          <div class="form-group">
                            <label for="inputEmail4">Email</label>
                            <input
                              type="email"
                              class="form-control"
                              value={this.state.Email}
                              id="inputEmail4"
                              placeholder="Email"
                              name="Email"
                              onChange={(e) => this.handleChangeProfile(e)}
                            />
                            <span style={{ color: "red" }}>
                              {this.state.emailErrorMsg}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group mt-3">
                            <label for="inputPassword4">
                              Practice Phone Number
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputPassword4"
                              value={this.state.practicePhoneNumber}
                              placeholder="Practice Phone Number"
                              name="Practice phone"
                              onChange={(e) => this.handleChangeProfile(e)}
                            />
                            <span style={{ color: "red" }}>
                              {this.state.practicePhoneNumberError}
                            </span>
                          </div>
                          <div class="form-group">
                            <label for="inputPassword4">Practice Name</label>
                            <input
                              type="text"
                              class="form-control"
                              id="inputPassword4"
                              value={this.state.practiceName}
                              placeholder="Practice Name"
                              name="Practice Name"
                              onChange={(e) => this.handleChangeProfile(e)}
                            />
                            <span style={{ color: "red" }}>
                              {this.state.practiceNameErrorMsg}
                            </span>
                          </div>
                          <div class="form-group suggest-list">
                            <label for="">Practice Address</label>
                            <PlacesAutocomplete
                              class="form-control"
                              value={this.state.address}
                              onChange={this.handleChangePlace}
                              onSelect={this.handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions = [],
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="autocompleteOuter">
                                  <input
                                    {...getInputProps({
                                      placeholder: "Practice Address",
                                      className: "location-search-input w-100",
                                    })}
                                  />
                                  {suggestions.length ? (
                                    <div className="autocomplete-dropdown-container">
                                      <div class="suggestion-scroll">
                                        {loading && (
                                          <div class="content-loading">
                                            Loading...
                                          </div>
                                        )}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description ===
                                                  "" &&
                                                this.state.address !== ""
                                                  ? "No result Found"
                                                  : suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </PlacesAutocomplete>
                            <span style={{ color: "red" }}>
                              {" "}
                              {this.state.addressErrorMsg}{" "}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="form-row d-flex justify-content-center">
                        <div class="form-action col-md-6 ">
                          <button
                            type="submit"
                            class="btn btn-primary btn-block"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="card h-100 c-shadow">
                  <div class="card-header">
                    <h3 class="card-title">Change password</h3>
                  </div>
                  <div class="card-body">
                    <form
                      class="setting-form-container auth-box"
                      onSubmit={this.changePassword}
                    >
                      <div class="form-group mt-3">
                        <label for="inputEmail4">Current Password</label>
                        <input
                          type="Password"
                          class="form-control"
                          id="inputEmail4"
                          value={this.state.oldpassword}
                          placeholder="Current Password"
                          name="oldpassword"
                          onChange={(e) => this.handleChange(e)}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.oldpasswordErrorMsg}
                        </span>
                      </div>
                      <div class="form-group">
                        <label for="inputEmail4">New Password</label>
                        <input
                          type="Password"
                          class="form-control"
                          id="inputEmail4"
                          value={this.state.currnetpassword}
                          placeholder="New Password"
                          name="currunt password"
                          onChange={(e) => this.handleChange(e)}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.currnetpasswordErrorMsg}
                        </span>
                      </div>
                      <div class="form-group">
                        <label for="inputEmail4">Confirm Password</label>
                        <input
                          type="Password"
                          class="form-control"
                          id="inputEmail4"
                          value={this.state.curentconfirmationpassword}
                          placeholder="Confirm Password"
                          name="confirmation password"
                          onChange={(e) => this.handleChange(e)}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.ConfirmationErrorMsg}
                        </span>
                      </div>
                      {/* <div class="form-action mb-3"  >
                                                <button type="submit" class="btn btn-primary btn-block"  >Reset Password</button>
                                            </div> */}

                      <div class="form-row d-flex justify-content-center">
                        <div class="form-action col-md-12 ">
                          <button
                            type="submit"
                            class="btn btn-primary btn-block"
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade uploadModal"
            id="cropImagePop"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="myModalLabel">
                    Edit Photo
                  </h4>
                  {this.state.buttonAlert == true ? (
                    <span style={{ color: "red", marginRight: 200 }}>
                      Please Crop Your Image
                    </span>
                  ) : (
                    ""
                  )}
                  {this.state.imagerrormsg == true ? (
                    <span style={{ color: "red", marginRight: 200 }}>
                      Upload minimum 200x200px
                    </span>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      this.cancelAvatar();
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div id="upload-demo" class="center-block">
                    {this.state.base64orgin == "" ? (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        circularCrop={true}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropCompleteNew}
                        onChange={this.onCropChange}
                      />
                    ) : (
                      <ReactCrop
                        src={this.state.base64orgin}
                        crop={crop}
                        ruleOfThirds
                        circularCrop={true}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}
                  </div>

                  <div class="uploadInner">
                    <p>Drag frame to adjust portrait.</p>

                    <div class="fileUpload blue-btn btn width100">
                      <span>Upload a Different Photo</span>
                      <input
                        type="file"
                        class="uploadlogo"
                        onChange={this.onSelectFile}
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-default"
                      data-dismiss="modal"
                      onClick={() => {
                        this.cancelAvatar();
                      }}
                    >
                      Cancel
                    </button>

                    {this.state.nocrop == false ? (
                      <button
                        type="button"
                        data-dismiss="modal"
                        id="cropImageBtn"
                        class="btn btn-primary"
                        onClick={() => {
                          this.save();
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        id="cropImageBtn"
                        class="btn btn-primary"
                        onClick={() => {
                          this.setState({ buttonAlert: true });
                        }}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStatetoProps, { ...settingsAction })(SettingsIndex);
