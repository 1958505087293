import userConstants from "../constants/userConstants";
import inivitationService from "../services/inivitationService";

const {
  VIEW_INVITATION_REQUEST,
  VIEW_INVITATION_SUCCESS,
  VIEW_INVITATION_FAILURE,
  DENY_PATIENT_REQUEST,
  DENY_PATIENT_SUCCESS,
  DENY_PATIENT_FAILURE,
  ACCEPT_PATIENT_REQUEST,
  ACCEPT_PATIENT_SUCCESS,
  ACCEPT_PATIENT_FAILURE,
  TOGGLE_STATE,
} = userConstants;

const viewInvitation = (params) => {
  const request = (invitationAct) => ({
    type: VIEW_INVITATION_REQUEST,
    invitationAct,
  });
  const success = (invitationAct) => ({
    type: VIEW_INVITATION_SUCCESS,
    invitationAct,
  });
  const failure = (error) => ({
    type: VIEW_INVITATION_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await inivitationService.viewInvitation(params);
      const invitationData = res;
      if (invitationData.status == 200) {
        dispatch(success(invitationData));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const denyPatientRequest = (params) => {
  const request = (denyPatientAction) => ({
    type: DENY_PATIENT_REQUEST,
    denyPatientAction,
  });
  const success = (denyPatientAction) => ({
    type: DENY_PATIENT_SUCCESS,
    denyPatientAction,
  });
  const failure = (error) => ({
    type: DENY_PATIENT_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await inivitationService.denyPatient(params);
      if (res.status == 200) {
        dispatch(success(res));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const acceptPatientRequest = (params) => {
  const request = (acceptPatientAction) => ({
    type: ACCEPT_PATIENT_REQUEST,
    acceptPatientAction,
  });

  const success = (acceptPatientAction) => ({
    type: ACCEPT_PATIENT_SUCCESS,
    acceptPatientAction,
  });

  const failure = (error) => ({
    type: ACCEPT_PATIENT_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await inivitationService.acceptPatient(params);
      if (res.status == 200) {
        dispatch(success(res));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const updateToogle = (toggleState) => {
  const toogle = (toogleAction) => ({
    type: TOGGLE_STATE + toggleState,
    toogleAction,
  });
  return async (dispatch) => {
    try {
      dispatch(toogle());
    } catch (e) {}
  };
};

export default {
  viewInvitation,
  denyPatientRequest,
  acceptPatientRequest,
  updateToogle,
};
