import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import patientListAction from "../../actions/patientListAction";
import Loader from "../common/toast/Loader";
import history from "../../config/history";

const mapStateToProps = (state) => ({
  recentPatientAction: state.patientList.recentPatientList,
  quickPatientAction: state.patientList.quickPatientList,
  dashboardAction: state.patientList.dashboardOverviewData,
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentPatientListData: [],
      quickPatientListData: [],
      names: [],
      namesData: [],
      duration: "last_month",
      dashboardOverviewData: [],
      graphResult: [],
      graphXaxisSorted: [],
      graphYaxisSorted: [],
      name: "",
      loader: false,
      searchLoader: false,
      yaxisLargestPoint: 10,
      activePatientsPercentage: 0,
      toggle: true,
    };
  }

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    let paramData = {
      token: token,
    };
    let overViewParams = {
      token: token,
      duration: "last_year",
    };
    this.setState({ loader: true });
    await this.props.dashboardOverview(overViewParams);
    await this.props.recentPatientList(paramData);
    await this.props.quickPatientList(paramData);
    await this.setState({
      recentPatientListData: this.props.recentPatientAction.data,
      quickPatientListData: this.props.quickPatientAction.data,
      dashboardOverviewData: this.props.dashboardAction.data,
      graphResult: this.props.dashboardAction.data.results,
    });
    await this.loopnames(this.state.quickPatientListData);
    await this.loadGraphData();
    await this.findAverage(this.state.dashboardOverviewData.active_patients);
    this.setState({ loader: false });
  }

  loadGraphData = async () => {
    let xaxisArray = [];
    this.state.graphResult.map((item, index, key) => {
      let num = parseInt(Object.values(item));
      xaxisArray.push(num);
    });
    let yaxisArray = [];
    this.state.graphResult.map((item, index, key) => {
      yaxisArray.push(Object.keys(item));
    });
    await this.setState({
      graphYaxisSorted: yaxisArray,
      graphXaxisSorted: xaxisArray,
    });
    this.getLargetsNumber(xaxisArray);
  };

  loopnames = async (dataForArray) => {
    let nameArrayTemp = [];
    dataForArray.map(async (item) => {
      let data = {
        name: item.name,
        avatar: item.avatar,
        id: item.id,
      };
      nameArrayTemp.push(data);
    });
    const sorttedArray = await this.getSections(nameArrayTemp);
    await this.setState({ names: sorttedArray });
  };

  getSections = (startsWithN) => {
    if (startsWithN.length === 0) {
      return [];
    }
    return Object.values(
      startsWithN.reduce((acc, word) => {
        let firstLetter = word.name[0].toLocaleUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = { title: firstLetter, data: [word] };
        } else {
          acc[firstLetter].data.push(word);
        }
        return acc;
      }, {})
    );
  };

  loadChangedGraph = async () => {
    let xaxisArray = [];
    this.state.graphResult.map((item, index, key) => {
      let num = parseInt(Object.values(item));
      xaxisArray.push(num);
    });
    let yaxisArray = [];
    this.state.graphResult.map((item, index, key) => {
      yaxisArray.push(Object.keys(item));
    });
    this.setState({
      graphYaxisSorted: yaxisArray,
      graphXaxisSorted: xaxisArray,
    });
  };

  reloadGraph = async (duration) => {
    let durationValue = duration.target.value;
    this.setState({ loader: true });
    let token = await localStorage.getItem("token");
    let overViewParams = {
      token: token,
      duration: durationValue,
    };
    await this.props.dashboardOverview(overViewParams);
    await this.setState({
      dashboardOverviewData: this.props.dashboardAction.data,
      graphResult: this.props.dashboardAction.data.results,
    });
    await this.loadChangedGraph();
    this.setState({ loader: false });
    // await this.loopnames(this.state.quickPatientListData)
  };

  searchPatient = async (e) => {
    let nameVal = e.target.value;

    await this.setState({
      name: e.target.value,
      searchLoader: true,
    });
    let token = await localStorage.getItem("token");
    let paramData = {
      token: token,
      name: nameVal,
    };
    await this.props.quickPatientList(paramData);
    await this.setState({
      quickPatientListData: this.props.quickPatientAction.data,
    });
    await this.loopnames(this.state.quickPatientListData);
    await this.setState({
      searchLoader: false,
    });
  };

  getLargetsNumber = async (xarray) => {
    // var array = [3, 6, 2, 56, 32, 5, 89, 32];
    let arrayLength = xarray.length;
    let largest = 0;

    for (let i = 0; i <= arrayLength; i++) {
      if (xarray[i] > largest) {
        largest = xarray[i];
      }
    }

    await this.setState({
      yaxisLargestPoint: largest,
    });
  };

  findAverage = async (activePatients) => {
    let percentage =
      (activePatients / this.state.dashboardOverviewData.total_patients) * 100;
    let result = Math.round(percentage * 100) / 100;
    await this.setState({
      activePatientsPercentage: result,
    });
  };
  toogleClick = async (toggleState) => {
    await this.props.updateToogle(toggleState);
    await this.setState({ toggle: !this.state.toggle });
  };

  onViewPatient = async (id) => {
    var patientid = id;

    history.push("/PatientsIndex", patientid);
  };

  isValidImage = (url_image) => {
    var image = new Image();
    image.src = url_image;
    if (image.width == 0) {
      return (
        <img src="assets/images/icn_avatar.png" alt="User image" class="" />
      );
    } else {
      return <img src={url_image} alt="User image" class="" />;
    }
  };

  imageExistsnew = (url) => {
    var image = new Image();

    image.src = url;

    if (!image.complete) {
      return false;
    } else if (image.height === 0) {
      return false;
    }

    return true;
  };

  render() {
    const chart = {
      options: {
        chart: {
          id: "apexchart-example",
          toolbar: {
            show: false,
          },
        },

        grid: {
          padding: {
            top: 0,
            right: 50,
            bottom: 0,
            left: 50,
          },
        },

        stroke: {
          curve: "straight",
          width: 3,
          lineCap: "butt",
          colors: ["#3E8EB1"],
        },
        xaxis: {
          categories: this.state.graphYaxisSorted,
          style: {
            padding: 0,
          },
        },
        yaxis: {
          logarithmic: false,
          tickAmount: 6,
          min: 0,
          max: this.state.yaxisLargestPoint + 10,
          floating: false,
          decimalsInFloat: false,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
          axisBorder: {
            show: false,
          },
          title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          crosshairs: {
            show: true,
            position: "back",
            stroke: {
              color: "#b6b6b6",
              width: 1,
              dashArray: 0,
            },
          },
          tooltip: {
            enabled: true,
            offsetX: 0,
          },
        },
        markers: {
          borderColor: "red",
          size: [5.5, 5.5],
          colors: "#ffffff",
          strokeColors: "#3E8EB1",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 7.5,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
        fill: {
          colors: "#419CC3",
          opacity: 1.9,
          type: "solid",
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "0px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
        },
      },
      series: [
        {
          name: "Patients",
          data: this.state.graphXaxisSorted,
        },
      ],
    };
    return (
      <main class="main-container">
        {this.state.loader && <Loader />}
        <ToastContainer />
        <div class="dash-body">
          <div class="header">
            <div class="header-left">
              <button
                onClick={() => {
                  this.toogleClick(this.state.toggle);
                }}
                id="sidebar-toggle"
                class="sidebar-toggle"
                type="button"
                data-toggle="collapse"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
              </button>
              <div class="title-con">
                <h1 class="page-title">Dashboard</h1>
              </div>
            </div>
            <div class="header-right"></div>
          </div>
          <div class="content">
            <div class="content-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header ui-sortable-handle">
                      <h3 class="card-title">Practice Overview</h3>
                      <div class="actions">
                        <div class="input-group select-styled-con">
                          <div class="input-group-prepend">
                            <img
                              src="assets/images/calander-icon.svg"
                              alt=""
                              class="input-group-icon"
                            />
                          </div>
                          <div class="card-header-select">
                            <select
                              class="form-control select-styled"
                              id=""
                              name=""
                              onChange={(e) => this.reloadGraph(e)}
                            >
                              <option value="last_year">Last Year</option>
                              <option value="last_quarter">Last Quarter</option>
                              <option value="last_month">Last Month</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-panel">
                      <div class="heighlights">
                        <div class="heighlight">
                          <h6 class="heighlight-name">TOTAL PATIENTS</h6>
                          <h4 class="heighlight-data">
                            {this.state.loader
                              ? 0
                              : this.state.dashboardOverviewData.total_patients}
                          </h4>
                        </div>
                        <div class="heighlight">
                          <h6 class="heighlight-name text-center">
                            ACTIVE PATIENTS
                          </h6>
                          <h4 class="heighlight-data">
                            {this.state.loader
                              ? 0
                              : this.state.activePatientsPercentage}
                            %
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="line-chart">
                        <ReactApexChart
                          id="chart"
                          options={chart.options}
                          series={chart.series}
                          type="area"
                          width="100%"
                          height="100%"
                          className="mychart"
                          // curve="straight"
                        />
                        {/* <canvas id="chart"></canvas>  */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header ui-sortable-handle">
                      <h3 class="card-title">Recently Accessed Patients</h3>
                    </div>
                    <div class="card-body">
                      <ul class="list-group list-bottom-open">
                        {this.state.recentPatientListData &&
                        this.state.recentPatientListData.length > 0 ? (
                          this.state.recentPatientListData.map(
                            (item, index) => {
                              return (
                                <li key={item.id} class="list-group-item">
                                  <div class="media">
                                    <div class="media-avatar">
                                      {item.avatar === null ? (
                                        <img
                                          src="assets/images/icn_avatar.png"
                                          alt="User image"
                                          class=""
                                        />
                                      ) : (
                                        <img
                                          src={item.avatar}
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "assets/images/icn_avatar.png";
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div class="media-body">
                                      <h4>{item.name}</h4>
                                      <a
                                        onClick={() => {
                                          this.onViewPatient(item.id);
                                        }}
                                        href="#"
                                        class="btn btn-outline-secondary"
                                      >
                                        View Patients
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )
                        ) : (
                          <ul class="list-group list-bottom-open">
                            <ul>
                              <li class="list-group-item">
                                <div class="media">
                                  <div
                                    class="media-body"
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <h4>No Patients </h4>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </ul>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header ui-sortable-handle">
                      <h3 class="card-title">Quick Patient List</h3>
                      <div class="actions fill-sm">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <img
                              src="assets/images/serach-icon.svg"
                              alt=""
                              class="input-group-icon"
                            />
                          </div>

                          {/* <input type="text" class="form-control" name="" placeholder="Search Patients" value={this.state.name} onChange={(e) => this.searchPatient(e)} /> */}

                          <DebounceInput
                            class="form-control"
                            name=""
                            placeholder="Search Patients"
                            minLength={0}
                            debounceTimeout={1000}
                            onChange={(event) => this.searchPatient(event)}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      {this.state.searchLoader ? (
                        <div className="text-center">
                          <img
                            src="assets/images/loaderGif.gif"
                            style={{ width: 100, height: 100 }}
                          />
                        </div>
                      ) : this.state.names && this.state.names.length > 0 ? (
                        this.state.names.map((item, index) => {
                          return (
                            <ul class="list-group list-bottom-open">
                              <li class="list-group-item sort-category">
                                {item.title}
                              </li>
                              <ul>
                                {item.data.map((userData) => {
                                  return (
                                    <li
                                      key={userData.id}
                                      class="list-group-item"
                                    >
                                      <div class="media">
                                        <div class="media-avatar">
                                          {userData.avatar === null ? (
                                            <img
                                              src="assets/images/icn_avatar.png"
                                              alt="User image"
                                              class=""
                                            />
                                          ) : (
                                            <img
                                              src={userData.avatar}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  "assets/images/icn_avatar.png";
                                              }}
                                            />
                                          )}
                                        </div>
                                        <div class="media-body">
                                          <h4>{userData.name}</h4>
                                          <a
                                            onClick={() => {
                                              this.onViewPatient(userData.id);
                                            }}
                                            href="#"
                                            class="btn btn-outline-secondary"
                                          >
                                            View Patient
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </ul>
                          );
                        })
                      ) : (
                        <ul class="list-group list-bottom-open">
                          <ul>
                            <li class="list-group-item">
                              <div class="media">
                                <div
                                  class="media-body"
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <h4>No Patients </h4>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default connect(mapStateToProps, { ...patientListAction })(Dashboard);
