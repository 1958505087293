import userConstants from "../constants/userConstants";
import patientListService from "../services/patientListService";

const {
  RECENT_PATIENT_LIST_REQUEST,
  RECENT_PATIENT_LIST_SUCCESS,
  RECENT_PATIENT_LIST_FAILURE,
  QUICK_PATIENT_LIST_REQUEST,
  QUICK_PATIENT_LIST_SUCCESS,
  QUICK_PATIENT_LIST_FAILURE,
  DASHBOARD_OVERVIEW_REQUEST,
  DASHBOARD_OVERVIEW_SUCCESS,
  DASHBOARD_OVERVIEW_FAILURE,
  TOGGLE_STATE,
} = userConstants;

const recentPatientList = (params) => {
  const request = (recentPatientAction) => ({
    type: RECENT_PATIENT_LIST_REQUEST,
    recentPatientAction,
  });

  const success = (recentPatientAction) => ({
    type: RECENT_PATIENT_LIST_SUCCESS,
    recentPatientAction,
  });

  const failure = (recentPatientAction) => ({
    type: RECENT_PATIENT_LIST_FAILURE,
    recentPatientAction,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientListService.recentPatientList(params);
      const patientListData = res;
      if (patientListData.status == 200) {
        await dispatch(success(patientListData));
      } else {
        await dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const quickPatientList = (params) => {
  const request = (quickPatientAction) => ({
    type: QUICK_PATIENT_LIST_REQUEST,
    quickPatientAction,
  });

  const success = (quickPatientAction) => ({
    type: QUICK_PATIENT_LIST_SUCCESS,
    quickPatientAction,
  });
  const failure = (quickPatientAction) => ({
    type: QUICK_PATIENT_LIST_FAILURE,
    quickPatientAction,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientListService.quickPatientList(params);
      const quickPatientListData = res;

      if (quickPatientListData.status == 200) {
        dispatch(success(quickPatientListData));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const dashboardOverview = (params) => {
  const request = (dashboardAction) => ({
    type: DASHBOARD_OVERVIEW_REQUEST,
    dashboardAction,
  });

  const success = (dashboardAction) => ({
    type: DASHBOARD_OVERVIEW_SUCCESS,
    dashboardAction,
  });
  const failure = (dashboardAction) => ({
    type: DASHBOARD_OVERVIEW_FAILURE,
    dashboardAction,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await patientListService.dashboardOverview(params);
      const dashboardOverviewData = res;

      if (dashboardOverviewData.status == 200) {
        dispatch(success(dashboardOverviewData));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const updateToogle = (toggleState) => {
  const toogle = (toogleAction) => ({
    type: TOGGLE_STATE + toggleState,
    toogleAction,
  });
  return async (dispatch) => {
    try {
      dispatch(toogle());
    } catch (e) {}
  };
};

export default {
  recentPatientList,
  quickPatientList,
  dashboardOverview,
  updateToogle,
};
