import { combineReducers } from 'redux'
 
import authReducer from './authReducer'
import invitationReducer from './invitationReducer'
import patientListReducer from './patientListReducer'
import patientIndexReducer from './patientIndexReducer'
import settingsReducer from './settingsReducer'
import ConsultationReducer from './ConsultationReducer'


const rootReducer = combineReducers({
 
auth : authReducer,
invitation :invitationReducer,
patientList: patientListReducer,
patientIndex:patientIndexReducer,
settingsIndex:settingsReducer,
ConsultationReducer:ConsultationReducer


})

export default rootReducer;