import userConstants from '../constants/userConstants'

const {
    CONTACT_LIST_REQUEST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_FAILURE,
    LOAD_MESSAGE_REQUEST,
    LOAD_MESSAGE_SUCCESS,
    LOAD_MESSAGE_FAILURE,
    SENT_MESSAGE_REQUEST,
    SENT_MESSAGE_SUCESS,
    SENT_MESSAGE_FAILURE,
    TOGGLE_STATE

} = userConstants

const initialState = {

    contactListData: {},
    loadMessaging:{},
    toogleState: {},
    sentMessage:{}
}

export default (state = initialState, action) => {

    switch (action.type) {

        

        case CONTACT_LIST_REQUEST:
            return {
                ...state
            }
        case CONTACT_LIST_SUCCESS:
            return {
                ...state,
                contactListData: { success: true, ...action.contactListAction }
            }
        case CONTACT_LIST_FAILURE:
            return {
                ...state,
                contactListData: { success: false }
            }

            case LOAD_MESSAGE_REQUEST:
                return {
                    ...state
                }
            case LOAD_MESSAGE_SUCCESS:
                return {
                    ...state,
                    loadMessaging: { success: true, ...action.loadMessageAction }
                }
            case LOAD_MESSAGE_FAILURE:
                return {
                    ...state,
                    loadMessaging: { success: false }
                }

                case SENT_MESSAGE_REQUEST:
                    return {
                        ...state
                    }
                case SENT_MESSAGE_SUCESS:
                    return {
                        ...state,
                        sentMessage: { success: true, ...action.sentMessageAction }
                    }
                case SENT_MESSAGE_FAILURE:
                    return {
                        ...state,
                        sentMessage: { success: false }
                    }


case TOGGLE_STATE + "true":
            return {
                ...state,
                toogleState: { toggle: true }
            }
        case TOGGLE_STATE + "false":
            return {
                ...state,
                toogleState: { toggle: false }
            }

        default:
            return {
                ...state
            }
    }

}
