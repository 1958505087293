import userConstants from '../constants/userConstants'

const {
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    GET_DENTIST_INFO_REQUEST,
    GET_DENTIST_INFO_SUCCESS,
    GET_DENTIST_INFO_FAILURE,
    TOGGLE_STATE
} = userConstants
const initialState = {
    updateProfileDetails: {},
    changePasswordDetails: {},
    dentistInforDetails :{}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROFILE_REQUEST:
            return {
                ...state
            }
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfileDetails: { success: true, ...action.updateProfileAction }
            }
        case UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                updateProfileDetails: { success: false }
            }
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state
            }
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordDetails: { success: true, ...action.changePasswordAction }
            }
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordDetails: { success: false }
            }




case GET_DENTIST_INFO_REQUEST:
            return {
                ...state
            }
        case GET_DENTIST_INFO_SUCCESS:
            return {
                ...state,
                dentistInforDetails: { success: true, ...action.getdentistInfoAction }
            }
        case GET_DENTIST_INFO_FAILURE:
            return {
                ...state,
                dentistInforDetails: { success: false }
            }


            




        case TOGGLE_STATE + "true":
            return {
                ...state,
                toogleState: { toggle: true }
            }
        case TOGGLE_STATE + "false":
            return {
                ...state,
                toogleState: { toggle: false }
            }
        default:
            return {
                ...state
            }
    }
}