import React from "react";
import { ColorRing } from "react-loader-spinner";

const LoaderClass = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 2000,
        backgroundColor: "rgba(255,255,255,.7)",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        left: 0,
        top: 0,
      }}
    >
      {/* <Loader
           type="Puff"
           color="#00BFFF"
           height={100}
           width={100}
        //    timeout={3000} //3 secs

        /> */}
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
      />
    </div>
  );
};
export default LoaderClass;
