import React, { Component } from "react";
import { connect } from "react-redux";
import nl2br from "react-newline-to-break";
import invitationAction from "../../actions/invitationAction";
import Loader from "../common/toast/Loader";

const mapStateToProps = (state) => (
  console.log("Patientssstate", state),
  {
    invitationAct: state.invitation.patientList,
  }
);

class InvitationIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patients: [],
      toggle: true,
      loader: false,
    };
  }
  async componentDidMount() {
    await this.setState({ loader: true });
    let token = await localStorage.getItem("token");
    const params = {
      token: token,
    };

    await this.props.viewInvitation(params);
    await this.setState({
      patients: this.props.invitationAct?.data,
      loader: false,
    });
  }

  denyPatient = async (id) => {
    await this.setState({ loader: true });
    let token = await localStorage.getItem("token");
    const params = {
      token: token,
    };
    const paramsData = {
      token: token,
      id: id,
    };
    await this.props.denyPatientRequest(paramsData);
    await this.props.viewInvitation(params);
    await this.setState({
      patients: this.props.invitationAct.data,
      loader: false,
    });
  };

  acceptPatient = async (id) => {
    await this.setState({ loader: true });
    let token = await localStorage.getItem("token");
    const params = {
      token: token,
    };
    const paramsData = {
      token: token,
      id: id,
    };
    await this.props.acceptPatientRequest(paramsData);
    await this.props.viewInvitation(params);
    await this.setState({
      patients: this.props.invitationAct?.data,
      loader: false,
    });
  };

  toogleClick = async (toggleState) => {
    await this.props.updateToogle(toggleState);
    await this.setState({ toggle: !this.state.toggle });
  };

  isValidImage = (url_image) => {
    var image = new Image();
    image.src = url_image;
    if (image.width == 0) {
      return (
        <img src="assets/images/icn_avatar.png" alt="User image" class="" />
      );
    } else {
      return <img src={url_image} alt="User image" class="" />;
    }
  };

  imageExistsnew = (url) => {
    var image = new Image();

    image.src = url;

    if (!image.complete) {
      return false;
    } else if (image.height === 0) {
      return false;
    }

    return true;
  };

  render() {
    return (
      <div class="dash-body">
        {this.state.loader && <Loader />}
        <div class="header">
          <div class="header-left">
            <button
              onClick={() => {
                this.toogleClick(this.state.toggle);
              }}
              id="sidebar-toggle"
              class="sidebar-toggle"
              type="button"
              data-toggle="collapse"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="title-con">
              <h1 class="page-title">Invitations</h1>
            </div>
          </div>
          <div class="header-right"></div>
        </div>
        <div class="content columns column-2">
          <div class="content-column">
            <ul class="list-group invitation-list">
              {this.state?.patients && this.state?.patients.length > 0 ? (
                this.state?.patients.map((item, index) => (
                  <li key={index} class="list-group-item">
                    <div class="media">
                      <div class="media-avatar">
                        {item.avatar === null ? (
                          <img
                            src="assets/images/icn_avatar.png"
                            alt="User image"
                            class=""
                          />
                        ) : (
                          <img
                            src={item.avatar}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "assets/images/icn_avatar.png";
                            }}
                          />
                        )}
                      </div>
                      <div class="media-body">
                        <h4>{item.name}</h4>
                        <div class="button-hr-group">
                          <button
                            onClick={() => this.denyPatient(item.id)}
                            class="btn btn-outline-secondary"
                          >
                            Deny
                          </button>
                          <button
                            onClick={() => this.acceptPatient(item.id)}
                            class="btn btn-primary"
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </div>
                    <article>
                      <p>{nl2br(item.message)}</p>
                    </article>
                  </li>
                ))
              ) : (
                <li class="list-group-item">
                  <div class="media">
                    <div
                      class="media-body"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <h4>No Invitations </h4>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div class="content-column"></div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, { ...invitationAction })(
  InvitationIndex
);
