import userConstants from '../constants/userConstants'
const {
    VIEW_INVITATION_REQUEST,
    VIEW_INVITATION_SUCCESS,
    VIEW_INVITATION_FAILURE,
    TOGGLE_STATE
} = userConstants;

const initialState = {
    patientList: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case VIEW_INVITATION_REQUEST:
            return {
                ...state
            }
        case VIEW_INVITATION_SUCCESS:
            return {
                ...state,
                patientList: { success: true, ...action.invitationAct }
            }
        case VIEW_INVITATION_FAILURE:
            return {
                ...state,
                patientList: { success: false }
            }
        case TOGGLE_STATE + "true":
            return {
                ...state,
                toogleState: { toggle: true }
            }
        case TOGGLE_STATE + "false":
            return {
                ...state,
                toogleState: { toggle: false }
            }

        default:
            return state

    }
}
