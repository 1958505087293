import React, { Component } from "react";
import { connect } from "react-redux";
import authAction from "../../actions/authAction";
import Loader from "../common/toast/Loader";
import { ToastContainer } from "react-toastify";

const mapStateToProps = (state) => {
  console.log("state", state);
};

class TermsCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signupData: {},
      loader: false,
    };
  }
  async componentDidMount() {
    await this.setState({ signupData: this.props.history.location.state });
  }

  register = async (e) => {
    this.setState({ loader: true });
    e.preventDefault();

    await this.props.SignUp(this.state.signupData);
    this.setState({ loader: false });
  };

  render() {
    return (
      <div
        class="auth-box terms"
        style={{ maxWidth: "80%", textAlign: "justify" }}
      >
        {this.state.loader && <Loader />}
        <ToastContainer />
        <h1 class="auth-title" style={{ fontWeight: "bold" }}>
          END USER LICENSE AGREEMENT AND TERMS OF SERVICE
        </h1>
        <div class="scroll-div-con">
          <div class="scroll-div">
            <div class="scroll-div-inner">
              <p>
                <h2>END USER LICENSE AGREEMENT AND TERMS OF SERVICE</h2>
                <br />
                By accessing or using this website you are agreeing to the END
                USER LICENSE AGREEMENT & TERMS OF SERVICE (“TOS”, “EULA”, or
                “Agreement”) set forth herein. This website; all of its
                constituent pages as added and amended from time to time; all
                content on said website and any constituent page; all URL’s
                associated with or which lead to said website; all associated
                applications on any platform (including without limitation
                Android and IOS); all trade dress and monikers on said website;
                all intellectual property contained anywhere on said website
                (including without limitation all trade secrets, trademarks,
                service marks, copyrights, and patents); the source and any
                other code; all design elements and renditions; copies of any of
                the foregoing; and to the extent not heretofore listed all
                content set forth on each and every page of the website,
                including without limitation all copy, marks, monikers, logos,
                trade dress, processes, information, and intellectual property;
                are, collectively and individually, the sole and exclusive
                property of Ionic Blue LLC (“Service Provider”).
                <br />
                You, the user, and any of your agents, representatives,
                assignees, employees, partners, designees, or persons to whom
                you have given access to the Site and the Services (whether
                intentionally, knowingly, negligently, or otherwise) may
                referred to as “user”, “User”, “You” or “Licensee”. This
                Agreement applies to all users regardless of dental training
                and/or licensure.
                <br />
                THIS IS A BINDING, LEGAL CONTRACT BETWEEN YOU AND SERVICE
                PROVIDER. THIS AGREEMENT GOVERNS YOUR ACCESS TO AND USE OF THE
                SERVICES (AS DEFINED BELOW). YOUR USE OF THE SERVICES PROVIDED
                BY OR THROUGH THIS WEBSITE CONSTITUTES ACCEPTANCE OF THIS
                AGREEMENT. IF YOU OBJECT TO ANY PART OR PORTION HEREOF DO NOT
                USE THE SERVICES.
                <br />
                SERVICE PROVIDER DOES NOT PROVIDE DENTAL, MEDICAL, OR HEALTH
                CARE RELATED SERVICES OR REPRESENTATION. THE USE OF THIS SITE OR
                THE SERVICES DOES NOT CREATE AN MEDICAL PROVIDER-PATIENT
                RELATIONSHIP BETWEEN ANY PARTIES AND NO COMMUNICATIONS BETWEEN
                YOU AND ANY DENTIST OR HEALTH CARE PROVIDER, THEIR AGENTS,
                REPRESENTATIVES, OWNERS, OFFICERS, DIRECTORS, OR EMPLOYEES, ARE
                PROTECTED BY THE HEALTH CARE PROVIDER-PATIENT PRIVILEGE. NO PART
                OF THE SITE OR THE SERVICES CONSTITUTE THE PRACTICE OF MEDICINE
                OR DENTISTRY.
                <br />
                1. The Services. Service Provider the services set forth more
                specifically on the Site (as defined below) (collectively “the
                Services”). THE SERVICES DO NOT CONSTITUTE IN WHOLE OR IN PART
                DENTAL OR HEALTH CARE SERVICES AND ARE NOT FOR DIAGNOSTIC OR
                TREATMENT PURPOSES. Data, including without limitation any
                images, are transmitted “AS IS” with no warranty, express or
                implied, including without limitation any warranty as to
                clarity, accuracy, currency or any implied warranty of fitness
                for a particular purpose or merchantability. <br />
                2. Storage. Service Provider is not obligated to store data,
                pictures, photographs, designs, graphics, or information of any
                nature whatsoever for or on behalf of User or any third party.
                <br />
                3. The Site. The Site includes, www.BluOralCare.com,
                www.blutoothbrush.com, all of its constituent pages, all content
                on said websites and any constituent page, all URL’s associated
                with or which lead to said websites, all associated applications
                on any platform (including without limitation Android and IOS),
                all trade dress and monikers on said websites, all of Service
                Provider’s intellectual property contained anywhere on said
                websites (including without limitation all trademarks, service
                marks, copyrights, and patents), the source and any other code,
                design elements, renditions, and copies of any of the foregoing.
                The Site further includes all amendments, modifications, and
                changes of any nature whatsoever to the foregoing.
                <br />
                4. Versions; Cost. Service Provider offers the Service in the
                versions and for the prices set forth on the website and
                incorporated herein as if repeated verbatim.
                <br />
                5. Mobile Access. User shall be solely responsible for and shall
                indemnify Service Provider and hold it harmless from, any costs,
                including without limitation carrier rates and fees, associated
                with accessing the Site or using the Services from a mobile
                device. If Service Provider offers a mobile application, it may
                have different functionality from that available on the Site.
                <br />
                6. Administrator Accounts. If applicable, one or more
                individuals may be designated as the administrator of an account
                (“Administrators”). Service Provide may assign special or
                exclusive functionality to Administrators, including without
                limitation the right to add or remove additional Users, restrict
                the access or use of non-Administrator Users, or prohibit
                non-Administrative Users from purchasing goods or services or
                otherwise engaging in transactions on the Site. Any
                non-Administrator User agrees to hold Service Provider harmless
                and indemnify it from any claim, loss, injury, damages, or cause
                of action arising from such non-Administrator User’s restricted
                access to or use of the Site and the Services.
                <br />
                7. Third-Party Accounts. The Services may, but will not
                necessarily, be accessed via one or more third party accounts.
                You agree to abide by the terms and conditions that govern such
                third-party account and to hold Service Provider harmless from
                any cause of action, liability, loss, injury, or damage caused
                by or resulting from using such third-party provider or a
                violation of any terms of service of such third-party provider.
                <br />
                8. Promotions. Service Provider may but shall under no
                circumstances be required to make gift or promotional offers of
                the Services. Any gift or promotional offers shall be pursuant
                to such additional terms and conditions as Service Provider may
                in its discretion announce on the Site. Service Provider may
                terminate any gift or promotional offer at any time in its sole
                and absolute discretion. In the event of a conflict between the
                terms and conditions associated with a gift or promotional offer
                and this Agreement, the provisions of this Agreement shall
                control.
                <br />
                9. Use of The Services.
                <br />
                <div className="col-12">
                  a. Equipment. You are solely responsible for providing the
                  equipment necessary to access and use the Site and the
                  Services, including without limitation any computer, drive,
                  software, hardware, application, modem, and internet
                  connection. Service Provider shall not be responsible for any
                  fees paid or accrued by you to access the internet. You agree
                  to hold Service Provider harmless from any damage to such
                  equipment regardless of the cause of such damage.
                  <br />
                  b. Content. All information, data, text, software, graphics,
                  voice or sound files, photographs, videos, depictions,
                  messages, emails, chats, text messages, documents, and other
                  such materials (“Content”) of Licensee or any third party used
                  on or transmitted by or through (i) the Site or (ii) the use
                  of the Services shall be the sole and exclusive property of
                  Licensee or such third party as appropriate. Service Provider
                  does not warrant or guaranty the accuracy, integrity, quality,
                  veracity, or appropriateness for any purpose of such Content,
                  and shall be held harmless and indemnified by Licensee for any
                  damages caused by or arising out of the use, reliance on, or
                  application of the Content, including without limitation
                  damages caused by errors, omissions, inaccuracies, negligence,
                  misrepresentations, or fraud.
                  <br />
                  c. Prohibited Content. You shall not post on the Site, on your
                  own behalf or on behalf of any third party, and shall not
                  permit others to post on the Site, any Content that, in whole
                  or in part: (i) Is unlawful, harmful, threatening, abusive,
                  harassing, tortious, defamatory, vulgar, obscene, invasive of
                  another’s privacy, or hate speech;(ii) misrepresents the
                  actual identity of a person, or misrepresents or falsely
                  represents an affiliation with a person or entity; (iii)
                  constitutes advertising, promotional material, junk mail,
                  spam, a chain letter, pyramid scheme, or an unauthorized
                  solicitation; (iv) is infected by or contains viruses,
                  malware, spyware, worms, or any other code, files, or programs
                  designed to interrupt, destroy, or limit the functionality of
                  any computer, software, hardware, program, or
                  telecommunications equipment; (v) is likely to interfere with
                  or disrupt the Site or the Services, or any server or network
                  connected thereto, or is inconsistent with any requirements,
                  policies, procedures, or requirements of networks connected to
                  the Site or the Services; (vi) violates any federal, state, or
                  local law, statute, regulation, rule, code, or ordinance, or
                  otherwise applicable laws regarding transmission of technical
                  data; or (vii) imposes an unreasonable or disproportionate
                  workload on Service Provider, the Site, or the Services.
                  Service Provider shall not prescreen any Content but reserves
                  the right to remove without notice any Content that Service
                  Provider deems, in its sole discretion, to constitute
                  Prohibited Content as herein defined.
                  <br />
                  d. Content Modification. You acknowledge and specifically
                  authorize Service Provider to change, alter, or modify the
                  Content as necessary to transmit it over one or more networks
                  and to conform and adapt it, the Site, or the Services to any
                  technical requirements of connecting networks, devices,
                  hardware, software, and the like.
                  <br />
                  e. Disclosure of Content. Service Provider may preserve and
                  disclose Content (i) when it is or reasonably determines that
                  it is obligated to preserve and/or disclose Content pursuant
                  to any applicable international, treaty, federal, state, or
                  local law, statute, code, rule, regulation, or ordinance; (ii)
                  to comply with any legal process; (iii) to enforce the terms
                  of this Agreement; (iv) to respond to complaints or claims
                  that the Content violates any third party rights; (v) to
                  protect any right or property of Service Provider; and (vi) to
                  protect the personal safety or property of any user of the
                  Site or the Services.
                  <br />
                  f. DISCLOSURE OF INFORMATION TO INSURANCE PROVIDERS. If
                  permitted under applicable law, Service Provider may share
                  health information with your insurance company for the
                  purposes of (i) conducting quality assessment and improvement
                  activities, (ii) conducting population-based activities
                  relating to improving health or reducing health care costs,
                  (iii) conducting population-based activities relating to
                  improving health or reducing health care cost, and (iv) any
                  other permissible reason at law.
                  <br />
                  g. Storage. Service Provider does not warrant or guarantee
                  continued access to any Content transmitted to or stored on
                  the Site or used in any manner on the Site or as part of the
                  provision of the Services, and this Agreement further
                  expressly excludes any warranty that such Content will not be
                  lost, damaged, or destroyed. Service Provider shall not
                  maintain a copy, in whole or in part, of any Content, and
                  further shall be held harmless in the event of a failure to
                  store any Content. Service Provider assumes no responsibility
                  for, and shall be held harmless in the event of, any injury or
                  damage to you arising out of the deletion or failure of
                  communications, addresses, or personalization settings.
                  <br />
                  h. Bots Prohibited. User will not use robots, bots, spiders,
                  scrapers, or other automated means to access the Site and
                  shall not access audiovisual content except via streaming.
                  User shall further not attempt to interrupt or decipher
                  transmissions to or from the servers running the Site or
                  collect or harvest personally identifiable information,
                  including without limitation account names and payment
                  information.
                  <br />
                  i. Comments. Comments left by users on the site shall conform
                  to the content requirements set forth herein. User comments
                  are not the statements or representations of Service Provider
                  and Service Provider assumes no liability for same. Service
                  Provider may, but is not required to, remove any user comment
                  at any time in Service Provider’s sole and absolute
                  discretion.
                  <br />
                </div>
                10. Prohibited Use. User shall not access the Site or use the
                Services, in whole or in part, for building a similar program or
                application or performing or publishing benchmark or other tests
                relating to the Site and/or the Services. Nothing in this
                Agreement shall be construed to grant a license of any Service
                Provider intellectual property right, either expressly, by
                estoppel, implication, or otherwise.
                <br />
                11. Business Associate Contract. The following apply to the
                extent that Service Provider is considered a business associate
                and User is a covered entity as those terms are defined under
                HIPAA, 45 C.F.R. 160.103. As used herein, ‘HIPAA Rules’ means
                those encoded at 45 CFR 160 and 164
                <br />
                <div className="col-12">
                  a. .Service Provider shall (i) not use or disclose protected
                  health information except as provided in this Agreement or by
                  law, (ii) use appropriate safeguards and comply with Subpart C
                  of 45 CFR 146 with respect to electronic protected health
                  information to prevent use or disclosure except as provided
                  for herein, (iii) report any use or disclosure of protected
                  health information not provided for in this Agreement of which
                  it becomes aware (including breaches of unsecured protected
                  health information as required by 45 CFR 164.410 and any
                  security incident), (iv) ensure that any subcontractors that
                  receive, transmit, or maintain protected health information on
                  behalf of Service Provider agree to the same restrictions,
                  conditions, and requirements, (v) make protected health
                  information available in a designated record set to User to
                  satisfy any obligations under 45 CFR 164.524, (vi) make
                  amendments to protected health information in a designated
                  record set as directed or agreed to by a covered entity
                  pursuant to 45 CFR 164.526 (or take additional measures as
                  necessary as provided under 45 CFR 164.524), (vii) maintain
                  and make available the information required to provide an
                  accounting of disclosures to User as necessary to satisfy
                  Service Provider’s obligations under 45 CFR 164.528 (if
                  applicable), (viii) comply with the requirements under Subpart
                  E of 45 CFR Part 164 as applicable, and (ix) make its internal
                  practices, books, and records available to the Secretary for
                  the purposes of determining compliance with the HIPAA Rules.
                  <br />
                  b. Service Provider may only use protected health information
                  (i) as provided in this Agreement or as necessary to fulfill
                  its obligations herein, (ii) as required by law, and (iii)
                  consistent with User’s minimum necessary policies and
                  procedures.
                  <br />
                  c. USER SHALL NOT POST OR ALLOW THE POSTING OF ANY PROTECTED
                  HEALTH INFORMATION IF DOING SO WOULD VIOLATE USER’S POLICIES
                  OR PRACTICES.
                  <br />
                  d. Service Provider does not store or retain copies of
                  protected information and shall have no obligation to return
                  same to User.
                  <br />
                  e. Upon termination of this Agreement for any reason, Service
                  Provider shall (i) continue to use appropriate safeguards and
                  comply with Subpart C of 45 CFR Part 164 with respect to
                  electronic protected health information to the extent and for
                  the length of time Service Provider retains such information
                  and (ii) not use or disclose protected health information
                  except as provided under this Agreement or by law.
                  <br />
                </div>
                12. Modification. Service Provider may (i) establish general
                practices and use limits concerning the Site and the Services,
                (ii) limit the size of communications that may be transmitted
                through the Site and the Services, (iii) limit the amount of
                Content that may be stored or processed by you on or through the
                Site, (iv) limit your access to the Site as may be necessary and
                reasonable under the circumstances, (v) change or modify its
                general practices in its sole discretion and without notice to
                you, (vi) change or modify the Site, these TOS, or the Services
                in its sole discretion and without notice to you, (vii)
                quarantine prohibited Content; (viii) modify domain and user
                settings in its sole discretion and without notice to you
                (including without limitation modifications designed to
                eliminate spam or bulk mail); and (ix) modify, suspend, or
                discontinue the Site or the Services as necessary for routine
                maintenance, extraordinary repairs, or due to an attack by
                hackers or other third party.
                <br />
                13. Authorized Third Party Access. You authorize Service
                Provider to share any Content, including personal identifying
                information protected under HIPAA and related statutes and laws,
                with your insurance provider, it agents, representatives, and
                employees. <br />
                14. Termination. In the event of your breach of any term of this
                Agreement, Service Provider, in its sole discretion, may suspend
                or terminate your account (including without limitation
                deactivation of your password), remove and discard the Content,
                and delete any records concerning or associated with your use of
                the Site or the Services. You agree that you are not entitled to
                any refund in the event of termination, and that you will
                indemnify Service Provider and hold it harmless from any costs
                of any nature whatsoever caused by or attributable in whole or
                in part to such termination.
                <br />
                15. Jury Trial Waiver. IN THE EVENT OF ANY DISPUTE BETWEEN THE
                PARTIES, THE PARTIES HEREBY VOLUNTARILY AND KNOWINGLY WAIVE THE
                RIGHT TO A TRIAL BY JURY ON ANY ISSUE AND IN ANY CAUSE OF
                ACTION.
                <br />
                16. Class Action Waiver. In the event of a dispute between the
                parties, resolution of same, whether by arbitration, litigation,
                or otherwise, shall proceed solely on an individual basis. You
                hereby waive any right to assert any claim or cause of action on
                a class action basis or on bases involving claims brought in a
                purported representative capacity on behalf of others. Claims
                may not be joined or consolidated unless agreed to in writing by
                all parties. No award or decision will have any preclusive
                effect as to issues or claims in any dispute with anyone who is
                not a named party.
                <br />
                17. COPPA Compliance. The Site and Services are not intended for
                access or use by children under the age of 13 years.
                <br />
                18. CalOPPA Compliance. Service Provider maintains a conspicuous
                Privacy Policy set forth below which is incorporated into this
                Agreement as if repeated verbatim.
                <br />
                19. Digital Signature. Pursuant to the U.S. Electronic
                Signatures in Global and National Commerce Act, 15 U.S.C. §
                7001, et seq.), this Agreement is deemed executed on the date of
                and by your clicking to (i) accept these Terms of Service, (ii)
                create an account, or (iii) submit any payment to Service
                Provider.
                <br />
                20. Orders. Payment for the Services or for any goods or
                products ordered through the Services or on the Site is due at
                the time of the placement of any order and must be made through
                the payment portal accessible on the Site. Service Provider is
                not obligated to accept any other form of payment. If payment is
                accepted by cash, check, or money order, acceptance shall be
                subject to any additional conditions imposed by Service Provider
                in its sole discretion. All payments shall be made in United
                States dollars unless otherwise denoted by Service Provider.
                Service Provider may, in its sole discretion, refuse to contract
                with any user. An order placed on the Site constitutes an offer
                to purchase the selected product(s) and service(s). Acceptance
                is in Service Provider’s sole discretion and may be communicated
                by either (i) an email or other writing confirming placement of
                the order or (ii) substantial fulfillment of the order. No
                contract between the parties shall be deemed created until the
                purchased goods are shipped and/or commencement of the rendering
                of any service and such contract thereafter shall only apply to
                such shipped goods and/or services performed. To the extent
                applicable at law or in equity, user waives any claim or action
                for breach of express or implied contract (including without
                limitation quasi-contract actions). Order are subject to
                availability of the product(s) and/or service(s) as well as
                price confirmation. Prices are subject to change at any time. If
                a price set forth on the Site is erroneous and such error is
                discovered after Licensee’s acceptance, Service Provider shall
                promptly notify the user, the contract shall be deemed void, and
                unless the user opts to accept the goods and/or services at the
                correct price Service Provider shall promptly issue a refund to
                user. You are responsible for all shipping, handling, and
                related charges. Service Provider is not responsible for
                shipment delay or failure caused by a third-party shipper or
                force majeure.
                <br />
                21. Payments. Placement of an order constitutes the
                acknowledgement and representation of the user that (i) it is
                over the age of eighteen (18) and otherwise eligible to use the
                Site, (ii) it is authorized to use the credit card or other
                method of payment through which payment is remitted, (iii) use
                of the payment method will not violate any agreement between the
                User and the payment method provider, and (iv) there are
                sufficient funds in the account with the payment method provider
                to remit payment in full to Service Provider. Credit cards may
                be debited immediately upon provision by user of the required
                information. A declined or refused credit card shall be deemed a
                failure of consideration and shall excuse any further action (or
                omission) by Service Provider. Service Provider is neither the
                agent nor the principal with respect to any third party with
                which user may enter into a contract, shall not be bound by any
                third-party contract, and shall have no liability thereunder.
                <br />
                22. Foreign Currencies. If a payment method is denominated in a
                currency other than United States Dollars, User shall be
                responsible for all conversion fees and related fees and shall
                hold Service Provider harmless and indemnify it for same. User
                assumes the sole risk of loss with respect to all foreign
                currency transactions and conversions.
                <br />
                23. Changes; Refund. Service Provider shall be under no
                obligation to refund any payment once remitted.
                <br />
                24. Subscription. You shall provide true, accurate, current, and
                complete information as prompted by the Site and shall update
                such information from time to time as is necessary. The
                provision of false, inaccurate, or incomplete information or
                failure to maintain current information shall be grounds for the
                immediate termination of this Agreement. You agree only you will
                use any account created with your subscription and that you will
                not share any access information, including without limitation
                your login or password, with any third party. You are solely
                responsible for maintaining the security of your login and
                password and agree to indemnify Service Provider and hold it
                harmless for any loss, damage, claim, liability, or cause of
                action occasioned by the use of such login and password by any
                third parties. If you believe your account information has been
                compromised, you are required to notify us immediately.
                <br />
                25. Cancellation. You may cancel at any time on thirty-days
                electronic notice sent to support@blutoothbrush.com.
                <br />
                26. Account Verification. Service Provider reserves the right to
                verify the identity of any User at any time. Verification may be
                by validation using third-party databases as well as government
                or other legal documents. You authorize Service Provider to make
                inquiries reasonably necessary to confirm individual identity as
                well as the identities of any corporate-entity User.
                <br />
                27. Archival Use. User grants Service Provider an irrevocable,
                perpetual license to retain and use to the extent permitted by
                applicable law (but without publication or distribution of
                individual data sets) server and archival copies of User’s
                content.
                <br />
                28. Service Provider’s Proprietary Property. The Site, the
                Services, and any software, hardware, platform, server, code, or
                other materials used in connection with the Site and the
                Services (collectively “the Proprietary Materials”), all of
                which are owned by Service Provider and/or its affiliates,
                contain proprietary and confidential information not available
                to the public and protected by intellectual property and trade
                secret laws, including without limitation the Uniform Trade
                Secrets Act as well as trademark and copyright protections.
                Subject to your continued compliance with the terms of this
                Agreement, including without limitation payment of all sums due
                hereunder, Service Provider hereby grants to you a
                non-exclusive, non-transferable, limited license to use the Site
                and the Services on a computer. You shall not, by or through any
                representative, agent, employee, director, officer, shareholder,
                partner, member, or third party, reverse engineer, reverse
                assemble, attempt or purport to discover any source code; sell,
                assign, sublicense, grant a security interest in or otherwise
                transfer any right in or to the Proprietary Materials; copy,
                modify, rent, lease, loan, sell, distribute, or create
                derivative works of (or based on) the Proprietary Materials, in
                whole or in part; or use modified versions of the Proprietary
                Materials, including without limitation obtaining unauthorized
                access to the Site or the Services. You may access the Site only
                through the interfaces provided by or through Service Provider.
                <br />
                29. Immunity for Disclosure. Pursuant to the Defend Trade
                Secrets Act, 18 U.S.C. § 1832, a disclosure of information is
                immune from prosecution or civil action if (i) it is made (A) in
                confidence to a Federal, State, or local government official or
                an attorney and (B) solely for reporting or investigating a
                suspected violation of law; or (ii) is made in a complaint or
                other document filed under seal in a lawsuit or other
                proceeding.
                <br />
                30. Remedies. The parties acknowledge that any breach of (i)
                Service Provider’s rights in and to its Proprietary Property or
                (ii) the intellectual property protection provisions of this
                Agreement are such that legal remedies alone would be
                insufficient to protect Service Provider’s rights and interests.
                The parties further agree that in the event of a breach or
                potential breach of the Service Provider’s Proprietary Property
                rights or any intellectual property protection section of this
                Agreement, Service Provider may petition any court of competent
                jurisdiction for a restraining order, injunction, or such other
                equitable remedy as it may deem appropriate, and Licensee
                expressly waives any defense to the inapplicability or
                inappropriateness of such equitable remedies. Service Provider
                shall under no circumstances be required to post a bond, and
                user hereby waives any right to demand same. Nothing herein
                shall be deemed as a waiver or to preclude Service Provider from
                asserting any other claims it may have in law or equity.
                <br />
                31. Third-Party Service Providers. Certain third parties may
                offer products and services through the Site. Service Provider
                does not employ, supervise, direct, or control any such third
                party and is not a partner or joint venture of same. Service
                Provider does not and shall not be required to obtain, record,
                or publish feedback or comments with respect to any third party.
                Each third party is solely responsible for the products or
                services it offers, and Service Provider shall not be a party to
                any contract or quasi-contract, whether oral, written, express,
                or implied, between User and such third-party. Service Provider
                makes no warranty, guaranty, or representation concerning the
                availability, quality, fitness, safety, or suitability of or
                with respect to any such product or service or of the person or
                entity that offers, provides, or sells such product or service
                and expressly disclaims any such warranties, express or implied,
                including without limitation any implied warranties of fitness
                for a particular purpose or merchantability. You agree to
                indemnify Service Provider and hold it harmless from any loss,
                liability, damage, injury, or cause of action caused by or
                attributable to, in whole or in part, any negligent, grossly
                negligent, reckless, intentional, or willful act or omission of
                such third-party product or service provider. Service Provider
                does not warrant that the information, assistance, advice, or
                consultation provided by any third party is accurate,
                beneficial, or will produce a desired result. Furthermore,
                Service Provider does not warrant or represent that any third
                party is qualified to provide any specific information or
                advice. All such information, assistance, advice, and
                consultation is provided “AS IS” and subject to the warranty
                disclaimers set forth below. Users agree to indemnify Service
                Provider and hold it harmless from any claim, cause of action,
                injury, loss, or damage caused by or alleged to be caused by the
                information, recommendations, counsel, or advice provided by any
                Third party.
                <br />
                32. Release. User hereby forever releases Service Provider, its
                owners, members, managers, directors, officers, affiliates,
                subsidiaries, joint ventures, employees, representatives, and
                agents from any loss, injury, damages, or claims arising out of
                any relationship or dispute between User and (i) any other user
                or (ii) any third-party provider.
                <br />
                33. Waiver of Protections. User hereby waives the protections of
                California Civil Code § 1542 and any analogous provision of law
                in any other jurisdiction that says in the following or in
                similar language: “A general release does not extend to claims
                which the creditor does not know or suspect to exist in his or
                her favor at the time of executing the release, which if known
                by him or her must have materially affected his or her
                settlement with the debtor.”
                <br />
                34. Remote Access. User may, but is not obligated to, grant
                Service Provider, its agents, contractors, and or/employees,
                access to User’s computer, hardware, software, and files for
                Service Provider to render the Services. User hereby agrees that
                Service Provider shall not be responsible or liable for any
                damages, data loss, data breach, or other property (or other)
                injury of any nature whatsoever arising from, in whole or in
                part, Service Provider’s remote access and indemnifies and holds
                Service Provider, its owners, shareholders, members, partners,
                directors, officers, agents, employees, and contractors,
                harmless from all damage associated with the implementation and
                use of remote access.
                <br />
                35. Disclaimer of Warranties. USE OF THE SITE AND THE SERVICES
                IS AT YOUR SOLE AND EXCLUSIVE RISK. THE SITE AND THE SERVICES
                ARE PROVIDED "AS IS" AND "AS AVAILABLE". SERVICE PROVIDER
                EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS, GUARANTEES
                AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, ACCURACY, TITLE, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT. ANY GOODS OR TANGIBLE MATERIALS PROCURED
                OR OBTAINED THROUGH THE WEBSITE, REGARDLESS OF ORIGIN,
                MANUFACTURER, OR PRODUCER, ARE PROVIDED “AS IS” AND “AS
                AVAILABLE”. SERVICE PROVIDER EXPRESSLY DISCLAIMS ALL WARRANTIES,
                REPRESENTATIONS, GUARANTEES AND CONDITIONS OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT. SERVICE PROVIDER MAKES NO WARRANTY THAT
                (i) ANY INFORMATION OR ADVICE OBTAINED FROM THE USE OF THE SITE
                OR THE SERVICES WILL BE ACCURATE OR RELIABLE, (ii) THE QUALITY
                OF ANY SERVICES OR INFORMATION OBTAINED FROM USE OF THE SITE OR
                THE SERVICES WILL MEET LICENSEE’S EXPECTATIONS, AND (iii) ANY
                ERRORS THAT IMPAIR THE FUNCTIONALITY OF THE SITE OR THE SERVICES
                WILL BE REPAIRED. LICENSEE ASSUMES ALL RISK OF LOSS FROM DAMAGE
                TO ITS COMPUTERS, SOFTWARE, HARDWARE, DRIVES, CODE, DATA,
                INFORMATION, CONTENT, INTELLECTUAL PROPERTY, AND OTHER PROPERTY
                OF ANY NATURE WHATSOEVER CAUSED IN ANY MANNER OR BY ANY METHOD
                FROM MATERIAL TRANSMITTED, RECEIVED, DOWNLOADED OR OTHERWISE
                OBTAINED OR SENT USING THE SITE. YOU ASSUMES ALL RISK OF LOSS
                CAUSED BY VIRUSES, MALWARE, WORMS, OR OTHER PROGRAMS OR
                MATERIALS TRANSMITTED BY OR THROUGH THE SITE.
                <br />
                36. Warranties; Jurisdictional Limitations. Any warranty
                disclaimers in this Agreement do not apply to the extent that
                they are otherwise void or disallowed pursuant to the law of a
                jurisdiction whose laws apply to the interpretation or
                enforcement hereof, but nothing herein shall alter or change any
                choice of law provisions set forth in this contract. Any
                limitations imposed by such jurisdiction on warranty disclaimers
                are incorporated herein by reference to the limited extent
                required by law without waiving or amending any choice of law
                provision set forth in this contract.
                <br />
                37. Privacy. You acknowledge that Service Provider may collect
                personally identifiable, confidential, proprietary, trade
                secret, intellectual property, business, financial, and similar
                information of Licensee (“Licensee Proprietary Information”).
                Except as set forth elsewhere in this Agreement, Service
                Provider shall not knowingly sell, rent, lease, transfer, or
                disclose any Licensee Proprietary Information unless (i) you
                consent to such sale, lease, transfer, or disclosure; (ii) sale,
                lease, transfer, or disclosure by Service Provider is necessary
                to the provision of the Services; or (iii) as necessary due to
                your breach of this Agreement. Service Provider may change the
                provisions of this section as it deems necessary in its sole
                discretion and shall provide notice by posting on the Site.
                <br />
                38. HIPAA. No part of the Site or the Services constitutes the
                practice of medicine or dentistry. Service Provider is not a
                health plan or healthcare clearinghouse as defined by HIPAA,
                HITECH, or any related statute, rule, regulation, or law
                (collectively “the Patient Privacy Laws”). IT IS THE
                RESPONSIBILITY OF THE HEALTH CARE PROVIDER TO OBTAIN THE
                REQUIRED PATIENT RELEASES AUTHORIZING THE RELEASE OF INFORMATION
                COVERED BY THE PATIENT PRIVACY LAWS PRIOR TO UPLOADING
                INFORMATION OR IMAGES ON THE SYSTEM. Dentists and other medical
                practitioners who use the Site or the Services are solely
                responsible for ensuring compliance with the Patient Privacy
                Laws and shall maintain a record of all consents and court
                orders demonstrating compliance therewith. User agrees to
                indemnify and hold Service Provider harmless in the event of any
                liability, judgment, violation, fine, penalty, or forfeiture
                arising in whole or in part under the Patient Privacy Laws.
                <br />
                39. Limitation of Liability. In no event shall Service Provider
                be liable to you for any incidental, indirect, special,
                consequential, or punitive damages, regardless of the nature of
                the claim or liabilities to third parties arising from any
                source, even if a party to this Agreement has been advised of
                the possibility of damages.
                <br />
                40. Electronic Records. You agree that any notices, contracts,
                or other communications from Service Provider may be provided by
                electronic instead of paper means. You further represent that
                (i) you have read and understood this consent and (ii) you have
                the hardware and software necessary to receive electronic
                notices. This consent will remain in effect until withdrawn by
                you by contacting Service Provider. At the time of withdrawal of
                consent, your right to access the Site and use the Services will
                be terminated effective immediately. You agree to give us a
                reasonable time to comply with the withdrawal of consent and to
                continue to receive electronic notifications during such period.
                <br />
                41. Miscellaneous. Failure of a party to enforce any provision
                of this Agreement shall not constitute a waiver of such
                provision or of the right of a party at any time to avail itself
                of such remedies as it may have for any breach or breaches of
                such provision. The provisions of this Agreement are severable.
                Should any part or portion hereof be deemed void or
                unenforceable by a court of competent jurisdiction, the
                remaining parts and portion shall remain in full force and
                effect. This Agreement represents the entire understanding by
                and between the parties and supersedes any other
                representations, promises, or statements not wholly consistent
                herewith. This Agreement may only be modified by a writing
                signed by both parties. The parties acknowledge the receipt and
                sufficiency of the consideration set forth in this Agreement.
                This Agreement shall be construed pursuant to the laws of
                Florida without regard to its conflict of law provisions. Any
                lawsuit brought to interpret or enforce the terms of this
                Agreement shall be filed in the appropriate state or federal
                court with jurisdiction and venue over Tampa, Florida. The
                parties hereby waive any defense of improper venue, lack of
                personal jurisdiction, or forum non conveniens. You warrant that
                you read the foregoing Agreement, understand and acknowledge
                each of its provisions, have the authority to execute this
                Agreement on your own behalf as well as on behalf of any entity
                or third-party signatory, and agree on your behalf and on behalf
                of any entity or third-party signatory to be bound by the terms
                and conditions herein. Service Provider shall be entitled to
                recoup from you its costs, including without limitation its
                reasonable attorneys’ fees, in bringing or defending any lawsuit
                or other proceedings that concern in whole or in part the
                enforcement and/or interpretation of this Agreement. Service
                Provider shall not be liable if the performance of any
                obligation of Service Provider hereunder is rendered impossible
                by any circumstance beyond its control and not created by its
                own act or omission, including flood, fire, and other natural
                disasters, war, riot or social unrest, work stoppage, act of
                terrorism, and Acts of God. Service Provider shall resume
                performance pursuant to the provisions of this Agreement upon
                the abatement of the force majeure. Any notice required to be
                given to Licensee may be made, in Service Provider’s sole
                discretion, by posting same conspicuously on the Site or by
                email to any email address provided by you. Notice to Service
                Provider shall be by email to support@blutoothbrush.com.
                <br />
                <div className="pt-2">
                  <h2>Privacy Policy</h2>
                  This Privacy Policy (“the Policy”) describes the policies and
                  procedures of Ionic Blue LLC (“SERVICE PROVIDER” or “WE”) on
                  the collection, use and disclosure of your information when
                  you access or use , www.BluOralCare.com, www.blutoothbrush,com
                  (“SERVICE”). We will not use or share your information with
                  anyone except as provided herein. This Policy does not apply
                  to information we obtain (i) offline, (ii) by means other than
                  through the SERVICE, or (iii) from any other source.
                  <br />
                  BY ACCESSING OR USING THE SERVICE YOU ARE AGREEING TO BE BOUND
                  BY THE TERMS SET FORTH HEREIN.
                  <br />
                </div>
                This Policy is to be read as consistent with the Terms of Use,
                End User License Agreement, and any other contract or policy in
                effect.
                <br />
                A. Definitions. The following terms shall be defined as set
                forth herein. “Content” has the same meaning as set forth in the
                End User Licensing Agreement to which specific reference is
                made; “Cookies” refers to small data files that may convey to
                SERVICE PROVIDER anonymous information about how you browse the
                SERVICE; “Device Identifiers” are small data files or related
                structures stored on or associated with a mobile device which
                identify your specific mobile device and includes without
                limitation data stored in connection with hardware, operating
                systems, or other software, or information sent directly to
                SERVICE PROVIDER by the device; “Location Data” includes GPS
                coordinates, latitude, longitude, or similar information
                regarding the location of your mobile device; “Mobile Device”
                includes without limitation mobile telephones, iPhones, tablets,
                iPads, Androids, and any similar technology or item;
                “Non-Personally Identifiable Information” includes non-private
                and/or aggregated information that does not identify a user
                (including without limitation anonymous usage data and platform
                types); “Persistent Cookies” refers to cookies that remain on a
                hard drive after a web browser is closed to be used by the
                browser on subsequent visits to the SERVICE; “Personal
                Identifying Information” refers to information that includes a
                user’s name or otherwise could be deemed by a reasonable person
                to identify a user or distinguish a user from all other users;
                “Session Cookies” refers to cookies that are temporary and
                disappear after a web browser is closed; “SSL” means secure
                socket layer technology; “User” means any person who access or
                uses the SERVICE; and, “You” means any user of the SERVICE.
                <br />
                B. Information SERVICE PROVIDER Collects & Uses. SERVICE
                PROVIDER uses the information you provide to (i) operate,
                maintain, and offer the features and functionality of the
                SERVICE, (ii) analyze how the SERVICE is used, (iii) diagnose
                service or technical problems, (iv) maintain security, (v)
                personalize content, (vi) store and remember information to help
                you more efficiently access your account, (vii) monitor metrics
                including without limitation the total number of visitors,
                traffic, and demographic patterns, and (viii) track content and
                users so as to comply with all applicable law, rules, and
                regulations, including without limitation that Digital
                Millennium Copyright Act.
                <br />
                C. Information Provided by Users. You are providing information
                about yourself such as your name and email address. We collect
                this information if you sign up for the SERVICE, follow us on
                social media and related sites such as Facebook and Twitter, or
                download or apply for any applications offered by SERVICE
                PROVIDER through any third-party site or network. Depending on
                your settings and authorizations on the SERVICE, the information
                you choose to provide will be viewable by and accessible to
                third parties.
                <br />
                D. Email. We may use your email address to send you notices
                related to the SERVICE (including notices required by law).
                Notices may include marketing materials. You may opt out of
                receiving marketing materials via email to
                support@blutoothbrush.com. Communication by email may be public,
                including your name, email address, the content of any messages
                you send to the SERVICE, and the content of any message the
                SERVICE sends to you. You agree to be contacted by email as
                opposed to postal mail.
                <br />
                E. Invitation. If you use any invitation service to invite third
                parties to the SERVICE, you will be asked to provide that
                party’s email address and automatically send that party an email
                invitation. SERVICE PROVIDER stores the information needed to
                send in the email, to register the third party if that third
                party accepts the invitation, and to track the metrics
                associated with any invitation service we might offer.
                <br />
                F. Content. You also provide information in the content that you
                post on the SERVICE. This content and metadata may be accessed
                by other users based on your security settings and
                authorizations. SERVICE PROVIDER can, but is not obligated to,
                monitor content posted on the SERVICE. It can remove any
                information that you post for any reason or no reason. Except
                for content viewable in accordance with your authorizations and
                security settings, SERVICE PROVIDER and its agents and employees
                will not view your content except to (i) maintain, provide, or
                improve the SERVICE, (ii) help you and resolve support requests,
                or (iii) comply with or avoid violation of applicable law or
                otherwise cooperate with law enforcement, all in SERVICE
                PROVIDER’s sole discretion.
                <br />
                G. Cookies. When you use the SERVICE, we may send one or more
                cookies to your computer to uniquely identify your browser and
                let SERVICE PROVIDER help you log in faster and enhance site
                navigation. Persistent cookies may be removed per your web
                browser’s instructions. You can also set your web browser to
                refuse all cookies or to indicate when a cookie is being sent.
                Some SERVICE features may not function properly if the ability
                to accept cookies is disabled.
                <br />
                H. Log Files. When you use the SERVICE our servers automatically
                record certain information sent by your web browser. These
                server logs may include information such as your web request, IP
                address, browser type, referring/exit pages, URLs, number of
                clicks, how you interact with links on the SERVICE, domain
                names, landing pages, pages viewed, mobile carrier, and similar
                information.
                <br />
                I. Clear Gifs Information. We may employ clear gifs (also known
                as “web beacons”) to track online usage patterns. We may also
                use clear gifs in HTML-based emails sent to our users to track
                which emails are opened by recipients. The information is used
                to enable more accurate reporting and improve the SERVICE.
                <br />
                J. Location Data. When you use the SERVICE by or through a
                mobile device, SERVICE PROVIDER may access, collect, monitor,
                and/or remotely store location data. Location data may convey to
                SERVICE PROVIDER information about how you use the SERVICE. It
                does not collect or provide us with personally identifying
                information about you; however, location data may be used in
                conjunction with personally identifying information. Some
                features of the SERVICE may not operate properly if use or
                availability of location data is limited or disabled.
                <br />
                K. Device Identifiers. When a user accesses the SERVICE by or
                through a mobile device, SERVICE PROVIDER may access, collect,
                monitor, and/or remotely store one or more device identifiers.
                SERVICE PROVIDER may use device identifiers to improve the
                SERVICE. The device identifier may provide SERVICE PROVIDER with
                information about how you use the SERVICE but it does not
                collect or share personally identifying information. However, a
                device identifier may be used in conjunction with personally
                identifying information and may remain on your device to speed
                up the login process and enhance navigation on the SERVICE. Some
                aspects of the SERVICE may not function properly if the
                availability and use of device identifiers is limited or
                disabled. SERVICE PROVIDER may access, collect, and store device
                identifiers once the user enables the SERVICE.
                <br />
                L. Third Party Tools. SERVICE PROVIDER may use third party tools
                (e.g. Google Analytics or similar tools) to study SERVICE usage
                and performance. Many of these tools collect the information
                sent by your web browser as part of a web page request
                (including cookies and your IP address). These tools receive the
                information provided to SERVICE PROVIDER and use it as governed
                by their own privacy policies.
                <br />
                M. Using Your Information (User). SERVICE PROVIDER will display
                your information on the SERVICE in accordance with your
                authorizations and security preferences. The information that
                you provide for inclusion on the SERVICE should reflect how much
                you want others to know about you. Consider this carefully
                before disclosing any information and recognize that the more
                content you provide the less anonymous you may be. You can
                review and revise your user information at any time. SERVICE
                PROVIDER may share or disclose information with your consent.
                <br />
                N. Sharing Information with Third Parties. SERVICE PROVIDER may
                share your personally identifying information with third parties
                for providing the SERVICE. Information may also be stored in
                locations outside of SERVICE PROVIDER’s direct control. SERVICE
                PROVIDER may share your information with a third-party
                application with your consent including without limitation when
                you access the SERVICE through such third-party application.
                SERVICE PROVIDER is not responsible for any third party’s use or
                disclosure of your information. You should only use applications
                that you trust and that have privacy policies that you consider
                acceptable.
                <br />
                O. HIPAA. Information covered by HIPAA may be obtained from your
                health care provider upon execution of a valid release. You
                represent and warrant that the execution of such release for use
                by SERVICE PROVIDER is made knowingly and willingly and agree to
                hold SERVICE PROVIDER harmless for any claim, cause of action,
                injury, loss, or damage caused or asserted to be caused be
                further disclosure of such information.
                <br />
                P. Business Transfers. As SERVICE PROVIDER develops its
                business, it may buy or sell assets or make certain offerings.
                Customer information is generally one of the transferred assets.
                Information may also be transferred or assigned in the event of
                a divestiture, merger, or dissolution.
                <br />
                Q. Non-Personally Identifiable Information. SERVICE PROVIDER may
                use non-personally identifiable information with third parties
                for any purpose whatsoever.
                <br />
                R. Information Security. SERVICE PROVIDER takes commercially
                reasonable measures to protect the security of user data.
                <br />
                S. SSL.SERVICE PROVIDER and/or its vendors use SSL for the
                encrypted transmission of sensitive information including credit
                card numbers; SERVICE PROVIDER and/or its vendors continuously
                and regularly backup your data to help prevent loss and assist
                in recovery; SERVICE PROVIDER and/or its vendors protect against
                common web attack vectors, host data in secure data centers, and
                implement firewalls and access restriction on their servers to
                secure their network.
                <br />
                T. Notification of Breach. In the event personal information is
                compromised because of a security breach, SERVICE PROVIDER will
                promptly notify those users whose information is reasonably
                believed to have been affected.
                <br />
                U. Disclaimer of Warranties. No method of electronic
                transmission or storage is completely secure. The SERVICE is
                provided “AS IS” and SERVICE PROVIDER makes no warranty, express
                or implied, concerning the security or integrity of any user
                data. All implied warranties, including without limitation the
                implied warranties of merchantability or fitness for a
                particular purpose, are hereby disclaimed.
                <br />
                V. READ THIS AGREEMENT CAREFULLY BEFORE USING THIS WEBSITE. ANY
                INFORMATION OR IMAGES YOU POST ON THIS WEBSITE MAY NOT BE
                AFFORDED CONFIDENTIALITY PROTECTIONS AND YOU ASSUME ALL RISK
                THAT YOUR MEDICAL OR DENTAL INFORMATION IS ACCESSIBLE TO OR
                ACCESSED BY THIRD PARTIES. THE SERVICE PROVIDER (AS DEFINED IN
                THIS AGREEMENT) IS NOT A HEALTH CARE PROVIDER, HEALTHCARE
                CLEARING HOUSE, OR HEALTH PLAN, AND STATUTES PROTECTING THE
                DISCLOSURE OF CONFIDENTIAL INFORMATION MAY NOT APPLY TO IT.
                <br />
                W. USER HEREBY CONSENTS TO AND AUTHORIZES THE DISCLOSURE OF ALL
                IMAGES AND INFORMATION IT SHARES ON THE SITE OR PURSUANT TO THE
                SERVICES.
                <br />
                X. No Obligation to Provide Information. You are not obligated
                to provide any information to SERVICE PROVIDER. However, the
                failure to provide SERVICE PROVIDER with certain information may
                limit or otherwise impact the services available on the SERVICE.
                <br />
                Y. Children’s Privacy. The SERVICE is not intended for use by
                anyone under the age of 13 years. SERVICE PROVIDER does not
                knowingly collect or solicit personal information from anyone
                under the age of 13 years or knowingly allow such persons to
                register for the SERVICE. If SERVICE PROVIDER learns that it has
                collected personal information from a child under the age of 13
                years without verification of parental consent, SERVICE PROVIDER
                will promptly take steps to remove that information. If you
                believe that SERVICE PROVIDER has or may have information from
                or about a child under 13 years of age, please contact SERVICE
                PROVIDER at support@blutoothbrush.com.
                <br />
                Z. Links to Other Websites. SERVICE PROVIDER is not responsible
                or liable for the practices employed by the owners or users of
                websites linked to or from the SERVICE. Furthermore, SERVICE
                PROVIDER is not responsible or liable for the information or
                content on such third-party sites. This Privacy Policy is only
                in effect for the SERVICE and not for any third-party website
                and you are subject to the terms of use and privacy and other
                policies of such third-party website.
                <br />
                <br />
                AA. Discussion Rooms, Blogs, Etc. Any information posted in a
                discussion room, group room, blog, or the like is considered
                publicly accessible and the user should not post any information
                it wishes to keep confidential.
                <br />
                BB. Modifications. SERVICE PROVIDER reserves the right to change
                this Policy from time to time and in its sole discretion. The
                modifications will take effect immediately upon posting on the
                SERVICE website.
                <br />
                <br />
                REVISED February 6, 2020.
                <br />
              </p>
            </div>
          </div>
        </div>
        <a
          href=""
          onClick={this.register}
          type="submit"
          class="btn btn-primary btn-block"
        >
          Accept and Register
        </a>
        <div class="progress-bar progressbar-primary">
          <div class="progress" style={{ width: "100%" }}></div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, { ...authAction })(TermsCondition);
