import React from "react";
import history from "../../../config/history";
import { connect } from "react-redux";
import settingsAction from "../../../actions/settingsAction";
// import { useNavigate } from "react-router-dom";

const mapStatetoProps = (state) => ({
  dentistDetails: state.settingsIndex.dentistInforDetails.data,
});

const Footer = ({ component, ...rest }) => {
  // let navigate = useNavigate();

  let userData = JSON.parse(localStorage.getItem("user"));

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    history.replace("/");
    // navigate("/");
  };

  return (
    <div class="sidebar-footer">
      <div class="user-con">
        <div class="dropup">
          <div class="user-drop dropdown-toggle" data-toggle="dropdown">
            <div class="user-image">
              {(() => {
                if (
                  rest.dentistDetails &&
                  rest.dentistDetails.avatar !== undefined
                ) {
                  return <img src={rest.dentistDetails.avatar} />;
                } else if (userData.avatar !== "") {
                  return (
                    <img
                      src={userData.avatar}
                      alt="User profile image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "assets/images/icn_avatar.png";
                      }}
                    />
                  );
                } else if (userData.avatar == "") {
                  return (
                    <img
                      src="assets/images/woman-wearing-black-brassiere.png"
                      alt="User profile image"
                    />
                  );
                }
              })()}
            </div>
            <div class="user-details">
              {(() => {
                if (
                  rest.dentistDetails &&
                  rest.dentistDetails.name !== undefined
                ) {
                  return <h4 class="user-title">{rest.dentistDetails.name}</h4>;
                } else if (userData.name !== "") {
                  return <h4 class="user-title">{userData.name}</h4>;
                }
              })()}
              {(() => {
                if (
                  rest.dentistDetails &&
                  rest.dentistDetails.practice_name !== undefined
                ) {
                  return (
                    <h6 class="user-category">
                      {rest.dentistDetails.practice_name}
                    </h6>
                  );
                } else if (userData.practice_name !== "") {
                  return (
                    <h6 class="user-category">{userData.practice_name}</h6>
                  );
                }
              })()}
            </div>
          </div>
          <div class="dropdown-menu user-dropdown">
            <a class="dropdown-item" href="/Dashboard">
              Dashboard
            </a>
            <a class="dropdown-item" href="/SettingsIndex">
              Settings
            </a>
            <a class="dropdown-item logout-item" onClick={() => logOut()}>
              <img src="assets/images/log-out.svg" alt="" />
              Log Out
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStatetoProps, { ...settingsAction })(Footer);
