import React, { Component } from "react";
import { connect } from "react-redux";
import patientIndexAction from "../../actions/patientIndexAction";
import { DebounceInput } from "react-debounce-input";
import Loader from "../common/toast/Loader";
import moment from "moment";
import Modal from "../../Modal";
// import history from '../../config/history';
// import { stat } from 'fs';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "react-daterange-picker";
// import DonutChart from "react-svg-donut"
// import Donut from "react-donut";
// import { addDays } from 'date-fns';
// import { useState } from 'react';
import ReactApexChart from "react-apexcharts";
// import validator from 'validate-image-url'

import "react-daterange-picker/dist/css/react-calendar.css";
// import Slider from 'react-rangeslider'
import dateFormat from "dateformat";
// To include the default styles
import "react-rangeslider/lib/index.css";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { withStyles } from "@mui/styles";
// import toastFunction from "../common/toast/toastFunction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { BaseURL } from "../../BaseUrl";

import { ReactComponent as ReactLogo_0 } from "../../../src/icons/icn_tooth_0.svg";
import { ReactComponent as ReactLogo_1 } from "../../../src/icons/icn_tooth_1.svg";
import { ReactComponent as ReactLogo_2 } from "../../../src/icons/icn_tooth_2.svg";
import { ReactComponent as ReactLogo_3 } from "../../../src/icons/icn_tooth_3.svg";
import { ReactComponent as ReactLogo_4 } from "../../../src/icons/icn_tooth_4.svg";
import { ReactComponent as ReactLogo_5 } from "../../../src/icons/icn_tooth_5.svg";
import { ReactComponent as ReactLogo_6 } from "../../../src/icons/icn_tooth_6.svg";
import { ReactComponent as ReactLogo_7 } from "../../../src/icons/icn_tooth_7.svg";
import { ReactComponent as ReactLogo_8 } from "../../../src/icons/icn_tooth_8.svg";
import { ReactComponent as ReactLogo_9 } from "../../../src/icons/icn_tooth_9.svg";
import { ReactComponent as ReactLogo_10 } from "../../../src/icons/icn_tooth_10.svg";
import { ReactComponent as ReactLogo_11 } from "../../../src/icons/icn_tooth_11.svg";
import { ReactComponent as ReactLogo_12 } from "../../../src/icons/icn_tooth_12.svg";
import { ReactComponent as ReactLogo_13 } from "../../../src/icons/icn_tooth_13.svg";
import { ReactComponent as ReactLogo_14 } from "../../../src/icons/icn_tooth_14.svg";
import { ReactComponent as ReactLogo_15 } from "../../../src/icons/icn_tooth_15.svg";
import { ReactComponent as ReactLogo_16 } from "../../../src/icons/icn_tooth_16.svg";
import { ReactComponent as ReactLogo_17 } from "../../../src/icons/icn_tooth_17.svg";
import { ReactComponent as ReactLogo_18 } from "../../../src/icons/icn_tooth_18.svg";
import { ReactComponent as ReactLogo_19 } from "../../../src/icons/icn_tooth_19.svg";
import { ReactComponent as ReactLogo_20 } from "../../../src/icons/icn_tooth_20.svg";
import { ReactComponent as ReactLogo_21 } from "../../../src/icons/icn_tooth_21.svg";
import { ReactComponent as ReactLogo_22 } from "../../../src/icons/icn_tooth_22.svg";
import { ReactComponent as ReactLogo_23 } from "../../../src/icons/icn_tooth_23.svg";
import { ReactComponent as ReactLogo_24 } from "../../../src/icons/icn_tooth_24.svg";
import { ReactComponent as ReactLogo_25 } from "../../../src/icons/icn_tooth_25.svg";
import { ReactComponent as ReactLogo_26 } from "../../../src/icons/icn_tooth_26.svg";
import { ReactComponent as ReactLogo_27 } from "../../../src/icons/icn_tooth_27.svg";
import { ReactComponent as ReactLogo_28 } from "../../../src/icons/icn_tooth_28.svg";
import { ReactComponent as ReactLogo_29 } from "../../../src/icons/icn_tooth_29.svg";
import { ReactComponent as ReactLogo_30 } from "../../../src/icons/icn_tooth_30.svg";
import { ReactComponent as ReactLogo_31 } from "../../../src/icons/icn_tooth_31.svg";
import axios from "axios";
// import { compose } from "redux";

// import CanvasJSReact from "../../../src/canvasjs.react";
// import { async } from "q";
// import { tr } from "date-fns/esm/locale";

const mapStateToProps = (state) => ({
  patientListingAction: state.patientIndex?.patientListing,
  viewPatientAction: state.patientIndex?.patientDetails,
  patientRecordDetails: state.patientIndex?.patientRecords,
  updatedPatientRecordAction: state.patientIndex?.updatedPatientRecords,
  kidRecordsDetails: state.patientIndex?.kidListing,
  brushingOverviewDetails: state.patientIndex?.brushOverviewListing,
});
// const { urlsChecker } = require("urls-checker");

// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
// var dateFormat = require("dateformat");
const today = moment();
// var imageExists = require("image-exists");

class PatientsIndex extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      addDataForHealthyTeeth: false,
      isPlaqueSwitch: false,
      toothMobilityNumber: 0,
      toothCondition: "healthy",
      pocketDepthValue: 0,
      disableNote: false,
      addNote: "",
      isPlaqueSwitchEdit: false,
      toothMobilityNumberEdit: 0,
      toothConditionEdit: "",
      pocketDepthValueEdit: 0,
      disableNoteEdit: false,
      noteEdit: "",
      isSelectedTooth: false,
      dates: "",
      dateFormat: "dd",
      value: new Date(),
      patientListData: [],
      names: [],
      patientDetails: [],
      homCare: [],
      loader: false,
      spinnerstatus: false,
      toggle: true,
      searchLoader: false,
      showPatientDetails: false,
      Coffee: false,
      Tea: false,
      RedWine: false,
      Smoking: false,
      None: false,
      divSelect: "",
      showCardModal: false,
      boardData: "",
      message: "",
      errorMessage: "",
      popup: false,
      patient_id: "",
      selectedCalendValue: "",
      patientDiceseRecord: [],
      devId: "",
      patientTeethId: [],
      patientIndex: [],
      updatedPatientRecord: {},
      patientKidRecords: [],
      dropdownname: "",
      selecteddate: "",
      popupustatus: false,
      isplaque: "",
      tooth_mobility: "",
      periodontal_type: "",
      pocket_depth: "",
      note: "",
      note_disable: "",
      detailstatus: false,
      targetkidid: "",
      recordid: "",
      checkedstatus: "",
      updatestatus: "",
      brushingdate: "",
      dob: moment(),
      divactive: "",
      daterange: "",
      volume: 0,
      brushOverviewRecords: {},
      lists: [],
      focusedInput: null,
      brushing_percentages: [],
      brushed_surface: "",
      brushing_flossed_percentage: "",
      brushing_frequency: "",
      brushing_duration: "",
      showingfromdate: "",
      showingtodate: "",
      minuteduration: "",
      brushingerrormsg: "",
      displyfromdate: "",
      displaytodate: "",
      newdatearry: [],
      datepickerstartingdate: "",
      datepickerendingdate: "",
      startDate: moment(),

      endDatess: moment().add(10, "days"),
      series1: [100, 0],
      series2: [100, 0],
      series3: [100, 0],
      series4: [100, 0],
      options: {
        tooltips: { enabled: false },
        hover: { mode: null },
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: "77%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
          offsetX: 0,
          fillSeriesColor: false,
        },
        markers: {
          onClick: false,
          onDblClick: false,
        },
        fill: {
          colors: ["#47B5E4", "#e0e0e0"],
        },
        legend: {
          show: false,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },

        responsive: [
          {
            breakpoint: 200,
            options: {
              chart: {
                width: 136,
                heigh: 136,
              },
              legend: {
                position: "bottom",
                tooltipHoverFormatter: undefined,
                onItemClick: {
                  toggleDataSeries: false,
                },
              },
              onItemHover: {
                highlightDataSeries: false,
              },
              background: {
                enabled: false,
                foreColor: "#fff",
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: "#fff",
                opacity: 0.9,
                dropShadow: {
                  enabled: false,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: "#000",
                  opacity: 0.45,
                },
                states: {
                  hover: {
                    filter: {
                      enabled: false,
                      type: "none",
                    },
                  },
                },
              },
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: "#000",
                opacity: 0.45,
              },
            },
          },
        ],
      },
    };
    this.dateChange = this.dateChange.bind(this);
  }
  dateChange(date) {
    this.setState({
      dob: date,
    });
  }

  /*for Plaque selection switch*/

  handlePlaqueSwitch = (event) => {
    this.setState({ isPlaqueSwitch: event.target.checked });
  };

  handlePlaqueSwitchEdit = (event) => {
    this.setState({ isPlaqueSwitchEdit: event.target.checked });
  };
  /*for tooth mobility*/
  handleToothMobility = (event) => {
    this.setState({ toothMobilityNumber: event.target.value });
  };

  handleToothMobilityEdit = (event) => {
    this.setState({ toothMobilityNumberEdit: event.target.value });
  };

  /*handle Tooth Condition*/
  handleToothCondition = (event) => {
    this.setState({ toothCondition: event.target.value });
  };
  handleToothConditionEdit = (event) => {
    this.setState({ toothConditionEdit: event.target.value });
  };

  /*handle Pocket Depth text field*/
  handlePocketDepth = (event) => {
    this.setState({ pocketDepthValue: event.target.value });
  };
  handlePocketDepthEdit = (event) => {
    this.setState({ pocketDepthValueEdit: event.target.value });
  };
  /*disable not*/
  handleDisableCheck = (event) => {
    this.setState({ disableNote: event.target.checked });
  };

  /*adding single tooth record*/
  AddRecord = (event) => {
    let token = localStorage.getItem("token");
    var patients = this.state.patientDetails.id;
    var kid = this.state?.targetkidid;

    let date = dateFormat(new Date(), "yyyy-mm-dd");
    let toothIndex = this.state.divactive;

    const headerText = { "Access-Control-Allow-Origin": "*" };
    axios
      .put(
        `${BaseURL}/dentist/disease_record`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          headerText,
          params: {
            patient_id: patients,
            // kid_id: kid,

            date: date,
            tooth_index: toothIndex,
            is_plaque: this.state.isPlaqueSwitch ? 1 : 0,
            tooth_mobility: this.state.toothMobilityNumber,
            periodontal_type: this.state.toothCondition,
            pocket_depth: this.state.pocketDepthValue,
            note: this.state.addNote,
            note_disable: this.state.disableNote ? 1 : 0,
            ...(kid && {
              kid_id: kid,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          Swal.fire("Success!", "Record added successfully!", "success");

          this.setState({ isPlaqueSwitch: false });
          this.setState({ toothMobilityNumber: 0 });
          this.setState({ toothCondition: "healthy" });
          this.setState({ pocketDepthValue: 0 });
          this.setState({ disableNote: false });

          this.setState({ addNote: "" });
          this.setState({ addDataForHealthyTeeth: false });
        }
      })
      .catch((error) => {
        console.log({ error });
      });

    this.ShowCurrentDate();
  };

  componentDidMount = async () => {
    this.setState({ addDataForHealthyTeeth: false });
    var patients = this.props.history?.location.state;
    await this.setState({ loader: true });
    let token = await localStorage.getItem("token");
    let paramData = {
      token: token,
    };
    await this.props.patientList(paramData);
    await this.setState({
      patientListData: this.props.patientListingAction.data,
    });
    await this.loopnames(this.state.patientListData);
    await this.setState({ loader: false });

    await this.setState({ patient_id: patients });
    if (this.state.patient_id) {
      this.onViewPatient(this.state.patient_id);
    }
    let datearr = [];

    datearr.push("2020/04/1");
    datearr.push("2020/04/2");
    datearr.push("2020/04/3");

    await this.setState({ lists: datearr });
  };
  componentWillUnmount() {
    this.setState({ addDataForHealthyTeeth: false });
  }

  imageExists(url, callback) {
    var img = new Image();
    img.onload = function () {
      callback(true);
    };
    img.onerror = function () {
      callback(false);
    };
    img.src = url;
  }

  imageExistsnew = (url) => {
    var image = new Image();

    image.src = url;

    if (!image.complete) {
      return false;
    } else if (image.height === 0) {
      return false;
    }

    return true;
  };
  onClick() {
    // window.location.href = `mailto:${this.props.email}`;

    var email = "";
    window.location.href = "mailto:" + email;
  }

  isValidImage = (url_image) => {
    var image = new Image();
    image.src = url_image;
    if (image.width == 0) {
      return (
        <img src="assets/images/icn_avatar.png" alt="User image" class="" />
      );
    } else {
      return <img src={url_image} alt="User image" class="" />;
    }
  };

  loopnames = async (dataForArray) => {
    let nameArrayTemp = [];

    dataForArray.map(async (item) => {
      let data = {
        name: item.name,
        avatar: item.avatar,
        id: item.id,
      };
      nameArrayTemp.push(data);
    });

    const sorttedArray = await this.getSections(nameArrayTemp);

    await this.setState({ names: sorttedArray });
  };
  getSections = (startsWithN) => {
    if (startsWithN.length === 0) {
      return [];
    }
    return Object.values(
      startsWithN.reduce((acc, word) => {
        let firstLetter = word.name[0].toLocaleUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = { title: firstLetter, data: [word] };
        } else {
          acc[firstLetter].data.push(word);
        }
        return acc;
      }, {})
    );
  };
  searchPatient = async (e) => {
    let nameVal = e.target.value;

    await this.setState({
      name: e.target.value,
      searchLoader: true,
    });
    let token = await localStorage.getItem("token");
    let paramData = {
      token: token,
      name: nameVal,
    };
    await this.props.patientList(paramData);
    await this.setState({
      patientListData: this.props.patientListingAction.data,
    });
    await this.loopnames(this.state.patientListData);
    await this.setState({
      searchLoader: false,
    });
  };
  toogleClick = async (toggleState) => {
    await this.props.updateToogle(toggleState);
    await this.setState({ toggle: !this.state.toggle });
  };

  onViewPatient = async (id) => {
    await this.setState({
      Coffee: false,
      Tea: false,
      RedWine: false,
      Smoking: false,
      None: false,
    });

    await this.setState({ loader: true });

    let token = await localStorage.getItem("token");
    const paramData = {
      id: id,
      token: token,
    };
    await this.props.viewPatient(paramData);
    await this.setState({
      patientDetails: this.props.viewPatientAction.data,
      homCare: this.props.viewPatientAction.data.home_care_instruction_history,
      showPatientDetails: true,
      divSelect: id,
    });

    let habits = this.state.patientDetails.habits.split("_");
    await this.loopHabits(habits);
    await this.setState({
      loader: false,
      dropdownname: this.state.patientDetails.name,
    });
  };
  loopHabits = (habits) => {
    for (var i = 0; i < habits.length; i++) {
      if ("Coffee" == habits[i]) {
        this.setState({ Coffee: true });
      } else if ("Tea" == habits[i]) {
        this.setState({ Tea: true });
      } else if ("RedWine" == habits[i]) {
        this.setState({ RedWine: true });
      } else if ("Smoking" == habits[i]) {
        this.setState({ Smoking: true });
      } else if ("None" === habits[i]) {
        this.setState({ None: true });
      }
    }
  };

  async handleClick() {
    this.setState({ errorMessage: "" });
    if (!this.state.message.trim().length) {
      await this.setState({
        errorMessage: "Please Enter Message",
        popup: false,
      });
      return;
    } else {
      await this.setState({ loader: true });
      var id = this.state.patientDetails.id;
      var messages = this.state.message;

      await this.setState({ popup: true });

      let token = localStorage.getItem("token");
      let paramData_user = {
        token: token,
        patient_id: id,
        message: messages,
      };
      await this.props.SentHomeCareInstruction(paramData_user);
      await this.setState({ loader: false, message: "", popup: false });

      await this.onViewPatient(id);
      return;
    }
  }

  async selectkidfromdropdown(e) {
    this.setState({ targetkidid: e.target.value });
    if (!this.state.showingfromdate == "") {
      this.showBrushOverview(
        this.state.showingfromdate,
        this.state.showingtodate,
        e.target.value
      );
      this.setState({ brushingerrormsg: "" });
    } else {
      this.setState({ brushingerrormsg: "Please Select the date range" });
    }
  }
  async updateStates() {
    await this.setState({
      brushingerrormsg: "",
      brushing_percentages: "",
      brushing_frequency: "",
      brushing_duration: "",
      brushed_surface: "",
      brushing_flossed_percentage: "",
      showingfromdate: "",
      showingtodate: "",
      displyfromdate: "",
      displaytodate: "",
    });
  }

  async showBrushOverview(startdate, enddate, targetid) {
    await this.setState({ series1: [100, 0] });
    await this.setState({ series3: [100, 0] });
    await this.setState({ series2: [100, 0] });
    await this.setState({ series4: [100, 0] });

    await this.setState({
      brushingerrormsg: "",
      brushing_percentages: "",
      brushing_frequency: "",
      brushing_duration: "",
      brushed_surface: "",
      brushing_flossed_percentage: "",
    });
    let token = localStorage.getItem("token");
    var id = this.state.patientDetails.id;
    let brushingoverview = {
      patient_id: id,
      from: startdate,
      to: enddate,
      kid_id: targetid,
      token: token,
    };

    await this.setState({ loader: true });

    await this.props.getBrushingOverview(brushingoverview);

    await this.setState({
      brushOverviewRecords: this.props.brushingOverviewDetails,
    });
    await this.setState({
      loader: false,
      brushing_percentages:
        this.state.brushOverviewRecords.data.brushing_percentages,
    });
    if (!this.state.brushOverviewRecords.data.brushed_surface == "") {
      var prctng_surface =
        this.state.brushOverviewRecords.data.brushed_surface * 100;
      var z = Math.floor(prctng_surface);
      await this.setState({ brushed_surface: z });
    }

    if (!this.state.brushOverviewRecords.data.brushing_duration == "") {
      var durationminutes = moment()
        .startOf("day")
        .seconds(this.state.brushOverviewRecords.data.brushing_duration)
        .format("mm:ss");

      var minutes = moment()
        .startOf("day")
        .seconds(this.state.brushOverviewRecords.data.brushing_duration)
        .format("m");

      await this.setState({
        brushing_duration: durationminutes,
        minuteduration: minutes,
      });
    }

    if (!this.state.brushOverviewRecords.data.brushing_frequency == "") {
      var frequency = Number(
        this.state.brushOverviewRecords.data.brushing_frequency.toFixed(1)
      );

      await this.setState({ brushing_frequency: frequency });
    }

    if (!this.state.brushOverviewRecords.data.flossed_percentage == "") {
      var prctng_flossed =
        this.state.brushOverviewRecords.data.flossed_percentage * 100;
      var z = Math.floor(prctng_flossed);
      await this.setState({ brushing_flossed_percentage: z });
    }

    var defaultXvalue = 100;
    var defaultyvalue = 0;

    var defaultXvalue_flossed = 100;
    var defaultyvalue_flossed = 0;

    var defaultXfrequency = 100;
    var defaultyfrequency = 0;

    var defaultXduration = 100;
    var defaultyduration = 0;
    var duration = 0;

    if (!this.state.brushed_surface == "") {
      defaultXvalue = this.state.brushed_surface;
      defaultyvalue = 100 - this.state.brushed_surface;
    }

    if (!this.state.brushing_flossed_percentage == "") {
      defaultXvalue_flossed = this.state.brushing_flossed_percentage;
      defaultyvalue_flossed = 100 - this.state.brushing_flossed_percentage;
    }
    var defaultXduration = 100;
    var defaultyduration = 0;
    if (!this.state.brushing_duration == "") {
      defaultXduration = (this.state.minuteduration * 100) / 2;
      defaultyduration = 100 - defaultXduration;
    }
    if (!this.state.brushing_frequency == "") {
      defaultXfrequency = this.state.brushing_frequency * 50;
      defaultyfrequency = 100 - defaultXfrequency;
    }
    await this.setState({ series1: [defaultXvalue, defaultyvalue] });
    await this.setState({ series3: [defaultXduration, defaultyduration] });
    await this.setState({ series2: [defaultXfrequency, defaultyfrequency] });
    await this.setState({
      series4: [defaultXvalue_flossed, defaultyvalue_flossed],
    });
  }
  secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  async kididCalling() {
    this.setState({
      targetkidid: "",
      patientKidRecords: [],
      brushOverviewRecords: "",
    });
    var todayss = new Date(),
      currntdate = todayss.getDate();

    this.setState({
      daterange: moment.range(
        today.clone().subtract(currntdate, "days"),
        today.clone()
      ),
    });

    await this.patientKid_brushingoverview();
  }

  async patientKid_brushingoverview() {
    await this.setState({
      loader: true,
    });

    let token = localStorage.getItem("token");
    var id = this.state.patientDetails.id;
    let records_kid = {
      patient_id: id,
      token: token,
    };
    await this.props.getPatientkid(records_kid);

    await this.setState({
      patientKidRecords: this.props.kidRecordsDetails.data,
    });

    await this.setState({
      loader: false,
    });
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let currnetdate = dateFormat(date, "yyyy-mm-dd");
    let firstdate = dateFormat(firstDay, "yyyy-mm-dd");

    let showingfrom = dateFormat(firstdate, "dd-mm-yy");
    let showingto = dateFormat(currnetdate, "dd-mm-yy");

    this.setState({
      showingfromdate: firstdate,
      showingtodate: currnetdate,
      displyfromdate: showingfrom,
      displaytodate: showingto,
    });
    this.showBrushOverview(firstdate, currnetdate, "");
  }

  async patientKid() {
    this.setState({ addDataForHealthyTeeth: false });
    this.setState({ isPlaqueSwitch: false });
    this.setState({ toothMobilityNumber: 0 });
    this.setState({ toothCondition: "healthy" });
    this.setState({ pocketDepthValue: 0 });
    this.setState({ disableNote: false });

    this.setState({ addNote: "" });

    let token = localStorage.getItem("token");
    var id = this.state.patientDetails.id;

    let records_kid = {
      patient_id: id,
      token: token,
    };
    await this.props.getPatientkid(records_kid);
    await this.setState({
      patientKidRecords: this.props.kidRecordsDetails.data,
    });

    //   var index = School.indexOf(email);
    // this.state.patientKidRecords
  }

  async updateRecord() {
    if (this.state.checkedstatus === true) {
      await this.setState({ updatestatus: 1 });
    } else if (this.state.checkedstatus === false) {
      await this.setState({ updatestatus: 0 });
    } else {
      await this.setState({ updatestatus: this.state.note_disable });
    }
    let token = localStorage.getItem("token");
    var id = this.state.patientDetails.id;

    var patients = this.state.patientDetails.id;
    var kid = await this.state?.targetkidid;

    let date = dateFormat(new Date(), "yyyy-mm-dd");

    let toothIndex = this.state.divactive;

    let records_update = {
      id: this.state.recordid,
      note: this.state.noteEdit,
      note_disable: this.state.updatestatus,
      token: token,
      date: new Date(),
    };

    var patients = this.state.patientDetails.id;
    var kid = await this.state?.targetkidid;

    // let date = new Date();
    // let toothIndex = this.state.divactive;

    const headerText = { "Access-Control-Allow-Origin": "*" };
    axios
      .put(
        `${BaseURL}/dentist/disease_record`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          headerText,
          params: {
            patient_id: patients,
            // kid_id: kid,

            date: date,
            tooth_index: toothIndex,
            is_plaque: this.state.isPlaqueSwitchEdit ? 1 : 0,
            tooth_mobility: this.state.toothMobilityNumberEdit,
            periodontal_type: this.state.toothConditionEdit,
            pocket_depth: this.state.pocketDepthValueEdit,
            note: this.state.noteEdit,
            note_disable: this.state.updatestatus,
            ...(kid && {
              kid_id: kid,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          Swal.fire("Success!", "Record updated successfully!", "success");
          this.setState({ isPlaqueSwitch: false });
          this.setState({ toothMobilityNumber: 0 });
          this.setState({ toothCondition: "healthy" });
          this.setState({ pocketDepthValue: 0 });
          this.setState({ disableNote: false });

          this.setState({ addNote: "" });
          this.setState({ addDataForHealthyTeeth: false });
        }
      })
      .catch((error) => {
        console.log({ error });
      });

    // await this.props.updatePatientsRecord(records_update);
    await this.setState({
      updatedPatientRecord: this.props.updatedPatientRecordAction,
    });
    this.ShowCurrentDate();
  }
  ShowCurrentDate = async () => {
    await this.patientKid();
    await this.setState({ targetkidid: "", value: "", divactive: "" });

    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

    this.viewPatientsRecord(date, false);

    this.setState({ dates: date });
  };

  viewPatientsRecord = async (val, status) => {
    await this.setState({
      loader: true,
      detailstatus: false,
      patientTeethId: [],
      patientIndex: [],
      value: "",
    });

    await this.setState({
      patientDiceseRecord: [],
    });
    // dropdownname
    var kidid = this.state.targetkidid;

    if (kidid == "" || kidid.length == 0) {
      kidid = "";
    }

    let token = localStorage.getItem("token");
    var id = this.state.patientDetails.id;
    let records = {
      token: token,
      patient_id: id,
      date: val,
      kid_id: kidid,
    };
    await this.props.viewPatientsRecords(records);
    await this.setState({
      patientDiceseRecord: this.props.patientRecordDetails.data,
    });

    {
      this.state.patientDiceseRecord.map((userData) => {
        this.state.patientTeethId.push(userData.tooth_index);
        this.setState({
          patientTeethId: this.state.patientTeethId,
        });
      });
    }

    // await this.patientKid()

    if (this.state.patientTeethId.includes(0)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(1)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(2)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(3)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(4)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(5)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(6)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(7)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(8)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(9)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(10)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(11)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(12)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(13)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(14)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(15)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(16)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(17)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(18)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(19)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(20)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(21)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(22)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(23)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(24)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(25)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(26)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(27)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(28)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(29)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(30)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    if (this.state.patientTeethId.includes(31)) {
      this.state.patientIndex.push(true);
    } else {
      this.state.patientIndex.push(false);
    }
    this.setState({
      patientIndex: this.state.patientIndex,
    });

    await this.setState({ loader: false, spinnerstatus: false });
  };

  getTeethDetails = async (val) => {
    await this.setState({ divactive: val });
    this.setState({ addDataForHealthyTeeth: true });
    this.setState({ isSelectedTooth: true });

    this.setState({
      detailstatus: false,
      note_disable: 0,
      checkedstatus: "",
      updatestatus: "",
    });

    {
      this.state.patientDiceseRecord.map((userData) => {
        if (userData.tooth_index == val) {
          this.setState({
            isplaque: userData.is_plaque,
            tooth_mobility: userData.tooth_mobility,
            periodontal_type: userData.periodontal_type,
            pocket_depth: userData.pocket_depth,
            note: userData.note,
            note_disable: userData.note_disable,
            detailstatus: true,
            recordid: userData.id,
          });
        }
      });
    }

    {
      this.state.patientDiceseRecord.map((userData) => {
        if (userData.tooth_index == val) {
          this.setState({
            isPlaqueSwitchEdit: userData.is_plaque,
            toothMobilityNumberEdit: userData.tooth_mobility,
            toothConditionEdit: userData.periodontal_type,
            pocketDepthValueEdit: userData.pocket_depth,
            noteEdit: userData.note,
            disableNoteEdit: userData.note_disable,
          });
        }
      });
    }
  };

  handleCheck = async (e) => {
    var value = e.target.checked;

    await this.setState({ checkedstatus: value });
    if (this.state.note_disable === 0) {
      this.setState({ note_disable: 1 });
    } else if (this.state.note_disable === 1) {
      this.setState({ note_disable: 0 });
    }
  };
  handleCardDetailsModal = () => {
    this.setState({ showCardModal: true });
    const { boardData } = this.state;
  };
  handleChange = async (e) => {
    if (e.target.value.trim() === "") {
      await this.setState({ message: e.target.value, popup: false });
    } else {
      await this.setState({ message: e.target.value, popup: true });
    }
  };
  renderModal = () => {
    return <Modal onCancel={this.onCloseCardDetailModal} />;
  };
  handleOnChangeslider = (value) => {
    this.setState({
      volume: value,
    });
  };

  onChange = (value) => {
    let today = value;

    let date =
      today.getFullYear() +
      "-" +
      parseInt(today.getMonth() + 1) +
      "-" +
      today.getDate();
    this.viewPatientsRecord(date, true);
    this.setState({
      brushingdate: date,
      selectedCalendValue: value,
    });

    this.state.patientKidRecords.map(async (item) => {
      if (item.id == this.state.targetkidid) {
        this.setState({
          dropdownname: item.name,
        });
      }
    });

    this.setState({
      value,
    });
  };

  async selectkidfromdropdownOverview(e) {
    this.setState({ addDataForHealthyTeeth: false });
    await this.setState({ targetkidid: e.target.value, spinnerstatus: true });
    if (this.state.brushingdate == "") {
      var today = new Date(),
        date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
      this.viewPatientsRecord(date, true);
    } else {
      this.viewPatientsRecord(this.state.brushingdate, true);
      this.setState({
        value: this.state.selectedCalendValue,
      });
    }
  }

  onSelect = (daterange) => {
    this.setState({ daterange });

    this.setState({ showingfromdate: "", showingtodate: "" });

    const startdate = this.state.daterange.start._i;
    const enddate = this.state.daterange.end._i;

    let startingdate = dateFormat(startdate, "yyyy-mm-dd");
    let endingdate = dateFormat(enddate, "yyyy-mm-dd");

    let showingfrom = dateFormat(startdate, "dd-mm-yy");
    let showingto = dateFormat(enddate, "dd-mm-yy");
    this.setState({
      showingfromdate: startingdate,
      showingtodate: endingdate,
      displyfromdate: showingfrom,
      displaytodate: showingto,
    });
    this.showBrushOverview(startingdate, endingdate, this.state.targetkidid);
    this.setState({ brushingerrormsg: "" });
  };

  focousOut(value) {
    this.setState({ daterange: "" });
  }
  handleFocusChange = (focusedInput) => this.setState({ focusedInput });
  render() {
    let imageavatar = (
      <img src="assets/images/icn_avatar.png" alt="User image" class="" />
    );

    let { volume } = this.state;
    const { value, targetkidid } = this.state;

    const stateDefinitions = {
      available: {
        color: null,
      },
      enquire: {
        color: "#47b5e4",
      },
      unavailable: {
        selectable: true,
        color: "yellow",
      },
    };
    let datearr = [];
    let dateRanges = [];

    const startsunavialble = moment(new Date(), "YYYY-MM-DD");
    const endsunavialabe = moment(new Date(), "YYYY-MM-DD");
    const rangesunavialbel = moment.range(startsunavialble, endsunavialabe);

    if (
      this.state.brushOverviewRecords &&
      this.state.brushOverviewRecords.data &&
      this.state.brushOverviewRecords.data.dates
    ) {
      this.state.brushOverviewRecords.data.dates.map((item, index) => {
        const starts = moment(item, "YYYY-MM-DD");
        const ends = moment(item, "YYYY-MM-DD");
        const ranges = moment.range(starts, ends);
        datearr.push(ranges);
      });

      dateRanges = [];

      datearr.map((item, index) => {
        dateRanges.push({
          state: "enquire",
          range: item,
        });
      });
      dateRanges.push({
        state: "unavailable",
        range: rangesunavialbel,
      });
    }

    dateRanges.push({
      state: "unavailable",
      range: rangesunavialbel,
    });
    return (
      <>
        <div class="dash-body">
          {this.state.loader && <Loader />}

          {this.state.showCardModal && this.renderModal()}

          <div class="header">
            <div class="header-left">
              <button
                onClick={() => {
                  this.toogleClick(this.state.toggle);
                }}
                id="sidebar-toggle"
                class="sidebar-toggle"
                type="button"
                data-toggle="collapse"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
              </button>
              <div class="title-con">
                <h1 class="page-title">Patients</h1>
              </div>
            </div>
            <div class="header-right"></div>
          </div>
          <div class="content columns column-2">
            <div class="content-column d-flex flex-column">
              <div class="search-fill">
                <div class="input-group">
                  {/* <input type="text" class="form-control" name="" placeholder="Search Patients"/>  */}
                  <DebounceInput
                    class="form-control"
                    name=""
                    placeholder="Search Patients"
                    minLength={0}
                    debounceTimeout={1000}
                    onChange={(event) => this.searchPatient(event)}
                  />
                  <div class="input-group-append">
                    <img
                      src="assets/images/serach-icon.svg"
                      alt=""
                      class="input-group-icon"
                    />
                  </div>
                </div>
              </div>
              <div class="oy-auto">
                {this.state.searchLoader ? (
                  <div className="text-center">
                    <img
                      src="assets/images/loaderGif.gif"
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                ) : this.state.names && this.state.names.length > 0 ? (
                  this.state.names.map((item, index) => {
                    return (
                      <ul class="list-group ">
                        <li class="list-group-item sort-category">
                          {item.title}
                        </li>
                        {item.data.map((userData) => {
                          return (
                            <li
                              class={`list-group-item ${
                                userData.id == this.state.divSelect
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <div class="media">
                                <div class="media-avatar">
                                  {userData.avatar === null ? (
                                    <img
                                      src="assets/images/icn_avatar.png"
                                      alt="User image"
                                      class=""
                                    />
                                  ) : (
                                    <img
                                      src={userData.avatar}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "assets/images/icn_avatar.png";
                                      }}
                                    />
                                  )}
                                </div>

                                <div class="media-body">
                                  <h4>{userData.name}</h4>
                                  <a
                                    onClick={() => {
                                      this.onViewPatient(userData.id);
                                    }}
                                    class="btn btn-outline-secondary"
                                  >
                                    View Patient
                                  </a>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      // </ul>
                    );
                  })
                ) : (
                  <ul class="list-group list-bottom-open">
                    <ul>
                      <li class="list-group-item">
                        <div class="media">
                          <div
                            class="media-body"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <h4>No Patients </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </ul>
                )}
              </div>
            </div>
            {this.state.showPatientDetails === false ? (
              <div class="content-column no-result">
                <p>
                  <b>Select User to Display Results</b>
                </p>
              </div>
            ) : (
              <div class="content-column oy-auto">
                <div class="column-head-profile">
                  <div class="media">
                    <div class="media-avatar">
                      {this.state.patientDetails.avatar === null ? (
                        <img
                          src="assets/images/icn_avatar.png"
                          alt="User image"
                          class=""
                        />
                      ) : (
                        <img
                          src={this.state.patientDetails.avatar}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "assets/images/icn_avatar.png";
                          }}
                        />
                      )}
                    </div>
                    <div class="media-body">
                      <div class="btn-group" id="newone">
                        <a
                          href="#"
                          class="btn btn-outline-secondary"
                          data-toggle="modal"
                          data-target="#brushing_modal"
                          onClick={() => this.kididCalling()}
                        >
                          View Brushing Log
                        </a>

                        <a
                          href="#"
                          class="btn btn-outline-secondary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={() => this.ShowCurrentDate()}
                        >
                          View Disease Record
                        </a>

                        <a
                          href="#"
                          class="btn btn-outline-secondary"
                          onClick={this.onClick}
                        >
                          Send Email
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sec-basic-details">
                  <div class="sec-basic-row">
                    <h4>BASIC DETAILS</h4>
                    <ul class="list-items-hr contact-details">
                      <li>{"+" + this.state.patientDetails.phone_number}</li>
                      <li>{this.state.patientDetails.email}</li>
                      <li>{this.state.patientDetails.hand} Hand</li>
                    </ul>
                  </div>
                  <div class="sec-basic-row sec-basic-row-hr">
                    <div class="left-col habit">
                      <h4>HABITS</h4>
                      <ul class="listing-icon">
                        <li class={this.state.Coffee ? "selected" : ""}>
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.Coffee
                                  ? "assets/images/coffe copy.png"
                                  : "assets/images/coffe.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Coffee</span>
                        </li>
                        <li class={this.state.Tea ? "selected" : ""}>
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.Tea
                                  ? "assets/images/tea copy.png"
                                  : "assets/images/tea.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Tea</span>
                        </li>
                        <li class={this.state.Smoking ? "selected" : ""}>
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.Smoking
                                  ? "assets/images/smoking copy.png"
                                  : "assets/images/smoking.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Smoking</span>
                        </li>
                        <li class={this.state.RedWine ? "selected" : ""}>
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.RedWine
                                  ? "assets/images/wine copy.png"
                                  : "assets/images/wine.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Red Wine</span>
                        </li>
                        <li class={this.state.None ? "selected" : ""}>
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.None
                                  ? "assets/images/none copy.png"
                                  : "assets/images/none.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">None</span>
                        </li>
                      </ul>
                    </div>
                    <div class="right-col t-condition">
                      <h4>TEETH CONDITION</h4>
                      <ul class="listing-icon health-conditions">
                        <li
                          class={
                            this.state.patientDetails.teeth_cond === "Healthy"
                              ? "selected"
                              : ""
                          }
                        >
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.patientDetails.teeth_cond ===
                                "Healthy"
                                  ? "assets/images/healthy 2.png"
                                  : "assets/images/healthy 1.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Healthy</span>
                        </li>
                        <li
                          class={
                            this.state.patientDetails.teeth_cond === "Sensitive"
                              ? "selected"
                              : ""
                          }
                        >
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.patientDetails.teeth_cond ===
                                "Sensitive"
                                  ? "assets/images/snesitive 2.png"
                                  : "assets/images/snesitive 1.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Sensitive</span>
                        </li>
                        <li
                          class={
                            this.state.patientDetails.teeth_cond ===
                            "Gum_Bleeding"
                              ? "selected"
                              : ""
                          }
                        >
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.patientDetails.teeth_cond ===
                                "Gum_Bleeding"
                                  ? "assets/images/bleeding.png"
                                  : "assets/images/bleeding 1.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Gum bleeding</span>
                        </li>
                        <li
                          class={
                            this.state.patientDetails.teeth_cond === "Yellowish"
                              ? "selected"
                              : ""
                          }
                        >
                          <div class="li-avatar">
                            <img
                              src={
                                this.state.patientDetails.teeth_cond ===
                                "Yellowish"
                                  ? "assets/images/yellow 2.png"
                                  : "assets/images/yellow 1.png"
                              }
                              alt="User image"
                              class=""
                            />
                          </div>
                          <span class="listing-name">Yellowish</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="sec-basic-row sec-basic-row-hr">
                    <div class="left-col">
                      <h4>TEETH CONDITION</h4>
                      <div class="teeth-image-block">
                        {this.state.patientDetails.whiteness_level === 1 ? (
                          <img src="assets/images/shade 1.png" alt="" />
                        ) : this.state.patientDetails.whiteness_level === 2 ? (
                          <img src="assets/images/shade 2.png" alt="" />
                        ) : this.state.patientDetails.whiteness_level === 3 ? (
                          <img src="assets/images/shade 3.png" alt="" />
                        ) : this.state.patientDetails.whiteness_level === 4 ? (
                          <img src="assets/images/shade 4.png" alt="" />
                        ) : this.state.patientDetails.whiteness_level === 5 ? (
                          <img src="assets/images/shade 5.png" alt="" />
                        ) : this.state.patientDetails.whiteness_level === 6 ? (
                          <img src="assets/images/shade 6.png" alt="" />
                        ) : (
                          <img src="assets/images/shade 6.png" alt="" />
                        )}
                      </div>
                      <ul class="teeth-color-strip">
                        <li
                          class={
                            this.state.patientDetails.whiteness_level === 1
                              ? "tc-1 selected"
                              : "tc-1"
                          }
                        ></li>
                        <li
                          class={
                            this.state.patientDetails.whiteness_level === 2
                              ? "tc-2 selected"
                              : "tc-2"
                          }
                        ></li>
                        <li
                          class={
                            this.state.patientDetails.whiteness_level === 3
                              ? "tc-3 selected"
                              : "tc-3"
                          }
                        ></li>
                        <li
                          class={
                            this.state.patientDetails.whiteness_level === 4
                              ? "tc-4 selected"
                              : "tc-4"
                          }
                        ></li>
                        <li
                          class={
                            this.state.patientDetails.whiteness_level === 5
                              ? "tc-3 selected"
                              : "tc-5"
                          }
                        ></li>
                        <li
                          class={
                            this.state.patientDetails.whiteness_level === 6
                              ? "tc-3 selected"
                              : "tc-6"
                          }
                        ></li>
                      </ul>
                    </div>
                    <div class="right-col card-block-con">
                      <h4>REPLENISHMENT INFORMATION</h4>
                      <div class="card-block">
                        <div class="card">
                          <div class="card-header">
                            <span>Usage lose rate</span>
                          </div>
                          <div class="card-body">
                            <div class="card-body-data">
                              <span class="digits">
                                {this.state.patientDetails.usage_lose_rate}
                              </span>
                              <span>%</span>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <span>Total time</span>
                          </div>
                          <div class="card-body">
                            <div class="card-body-data">
                              <span class="digits">
                                {this.state.patientDetails.total_time}
                              </span>
                              <span>Hours</span>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <span>Total brushing session</span>
                          </div>
                          <div class="card-body">
                            <div class="card-body-data">
                              <span class="digits">
                                {
                                  this.state.patientDetails
                                    .total_brushing_session
                                }
                              </span>
                              <span>Times</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="sec-history">
                    <div class="sec-history-header">
                      <h4>HOME CARE INSTRUCTIONS HISTORY</h4>
                      <div class="ml-auto">
                        <button
                          class="btn btn-primary"
                          data-toggle="modal"
                          data-target="#sendHomeCareInstructions"
                          onClick={() => {
                            this.setState({ errorMessage: "" });
                          }}
                        >
                          Send Home Care Instructions
                        </button>
                      </div>
                    </div>
                    {this.state.patientDetails.home_care_instruction_history.map(
                      (item, index) => {
                        let tempTime = item.sent_at + " GMT";
                        var text = item.message;

                        return (
                          <div class="sec-history-item">
                            <h6>
                              SENT{" "}
                              {moment(new Date(tempTime)).format("MM/DD/YY")} at{" "}
                              {moment(new Date(tempTime)).format("HH:mm")}
                            </h6>
                            {/* <p>
                                                    {item.message}
                                                </p> */}
                            <div>
                              {text.split("\n").map((i, key) => {
                                return <div key={key}>{i}</div>;
                              })}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="modal modal-center" id="sendHomeCareInstructions">
          <div class="modal-dialog modal-md-feedback">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Send Home Care Instructions</h4>
                <h5>Sending to {this.state.patientDetails.name}</h5>
                <button type="button" class="close" data-dismiss="modal">
                  <img src="assets/images/modal-close.svg" alt="" />
                </button>
              </div>

              <div class="modal-body">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    placeholder="Type your home care instructions here..."
                    value={this.state.message}
                    onChange={(e) => this.handleChange(e)}
                  ></textarea>
                  <span className="text-danger mt-4">
                    {this.state.errorMessage}
                  </span>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  data-toggle={this.state.popup ? "modal" : ""}
                  data-target="#sendHomeCareInstructions"
                  class="btn btn-primary"
                  onClick={() => this.handleClick()}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal modal-center Disease-record-modal" id="myModal">
          <div class="modal-dialog modal-md-feedback">
            <div class="modal-content">
              <div class="modal-header">
                <div class="d-flex m-header-text">
                  <h4 class="modal-title">Disease Record</h4>

                  <div class="form-group">
                    {
                      <select
                        class="form-control custom-select"
                        value={this.state.targetkidid}
                        onChange={(e) => {
                          this.selectkidfromdropdownOverview(e);
                        }}
                      >
                        <option value="">
                          {this.state.patientDetails.name}
                        </option>
                        {this.state.patientKidRecords.map(function (item) {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    }
                  </div>
                </div>
                <button type="button" class="close" data-dismiss="modal">
                  <img src="assets/images/modal-close.svg" alt="" />
                </button>
              </div>

              <div class="modal-body">
                <div class="d-flex disease-modal-wrap">
                  <div class="modal-callender-wrapper">
                    <div class="calendar">
                      <div className="Sample" style={{ marginTop: 10 }}>
                        <div className="Sample__container">
                          <main className="Sample__container__content">
                            <Calendar
                              onChange={this.onChange}
                              showWeekNumbers
                              value={value}
                              maxDate={new Date()}
                              calendarType={"ISO 8601"}
                            />
                          </main>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    <div>
                      {(() => {
                        return (
                          <div class="teeth-container">
                            <div class="container-d">
                              <img
                                class="frame-left-up"
                                src="assets/images/TeethIcons/icn_tooth_left_up.png"
                              />

                              <div
                                class={
                                  this.state.divactive == 12 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(12);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[12]) {
                                    var index12 =
                                      this.state.patientTeethId.indexOf(12);
                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index12
                                        ] &&
                                        this.state.patientDiceseRecord[index12]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index12
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index12
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_12
                                              // class="cover-left-up lu-first plaque"
                                              class={
                                                this.state.divactive === 12 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 12
                                                  ? "cover-left-up lu-first  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 12
                                                  ? "cover-left-up lu-first     Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 12
                                                  ? "cover-left-up lu-first     Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 12
                                                  ? "cover-left-up lu-first     BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 12
                                                  ? "cover-left-up lu-first     BleedingTwo"
                                                  : "cover-left-up lu-first     plaque"
                                              }
                                              id="cover_12"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index12
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_12
                                                // class="cover-left-up lu-first BleedingTwo"
                                                class={
                                                  this.state.divactive === 12 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 12
                                                    ? "cover-left-up lu-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingTwo"
                                                    : "cover-left-up lu-first     BleedingTwo"
                                                }
                                                id="cover_12"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index12
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_12
                                                // class="cover-left-up lu-first  Bleeding"
                                                class={
                                                  this.state.divactive === 12 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 12
                                                    ? "cover-left-up lu-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingTwo"
                                                    : "cover-left-up lu-first     Bleeding"
                                                }
                                                id="cover_12"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index12
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_12
                                                // class="cover-left-up lu-first  plaque"
                                                class={
                                                  this.state.divactive === 12 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 12
                                                    ? "cover-left-up lu-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingTwo"
                                                    : "cover-left-up lu-first plaque"
                                                }
                                                id="cover_12"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index12
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_12
                                                // class="cover-left-up lu-first  Abscess"
                                                class={
                                                  this.state.divactive === 12 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 12
                                                    ? "cover-left-up lu-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingTwo"
                                                    : "cover-left-up lu-first     Abscess"
                                                }
                                                id="cover_12"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index12
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_12
                                                // class="cover-left-up lu-first  BleedingAbscess"
                                                class={
                                                  this.state.divactive === 12 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 12
                                                    ? "cover-left-up lu-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        12
                                                    ? "cover-left-up lu-first     BleedingTwo"
                                                    : "cover-left-up lu-first     BleedingAbscess"
                                                }
                                                id="cover_12"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_12
                                        // class="cover-left-up lu-first red svg"

                                        class={
                                          this.state.divactive === 12 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-up lu-first red svg BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 12
                                            ? "cover-left-up lu-first red svg plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 12
                                            ? "cover-left-up lu-first red svg Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 12
                                            ? "cover-left-up lu-first red svg Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 12
                                            ? "cover-left-up lu-first red svg BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 12
                                            ? "cover-left-up lu-first red svg BleedingTwo"
                                            : "cover-left-up lu-first red svg "
                                        }
                                        id="cover_12"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <div
                                class={
                                  this.state.divactive == 13 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(13);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[13]) {
                                    var index13 =
                                      this.state.patientTeethId.indexOf(13);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index13
                                        ] &&
                                        this.state.patientDiceseRecord[index13]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[13] &&
                                          this.state.patientDiceseRecord[13]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_13
                                              // class="cover-left-up lu-second plaque"
                                              class={
                                                this.state.divactive === 13 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 13
                                                  ? "cover-left-up lu-second  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 13
                                                  ? "cover-left-up lu-second     Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 13
                                                  ? "cover-left-up lu-second     Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 13
                                                  ? "cover-left-up lu-second     BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 13
                                                  ? "cover-left-up lu-second     BleedingTwo"
                                                  : "cover-left-up lu-second plaque"
                                              }
                                              id="cover_13"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index13
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_13
                                                // class="cover-left-up lu-second BleedingTwo"
                                                class={
                                                  this.state.divactive === 13 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 13
                                                    ? "cover-left-up lu-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingTwo"
                                                    : "cover-left-up lu-second     BleedingTwo"
                                                }
                                                id="cover_13"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index13
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_13
                                                // class="cover-left-up lu-second Bleeding"
                                                class={
                                                  this.state.divactive === 13 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 13
                                                    ? "cover-left-up lu-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingTwo"
                                                    : "cover-left-up lu-second     Bleeding"
                                                }
                                                id="cover_13"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index13
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_13
                                                // class="cover-left-up lu-second plaque"
                                                class={
                                                  this.state.divactive === 13 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 13
                                                    ? "cover-left-up lu-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingTwo"
                                                    : "cover-left-up lu-second plaque"
                                                }
                                                id="cover_13"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index13
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_13
                                                // class="cover-left-up lu-second Abscess"

                                                class={
                                                  this.state.divactive === 13 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 13
                                                    ? "cover-left-up lu-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingTwo"
                                                    : "cover-left-up lu-second     Abscess"
                                                }
                                                id="cover_13"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index13
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_13
                                                // class="cover-left-up lu-second BleedingAbscess"
                                                class={
                                                  this.state.divactive === 13 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 13
                                                    ? "cover-left-up lu-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        13
                                                    ? "cover-left-up lu-second     BleedingTwo"
                                                    : "cover-left-up lu-second     BleedingAbscess"
                                                }
                                                id="cover_13"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_13
                                        // class="cover-left-up lu-second "

                                        // class={
                                        //   this.state.divactive === 13
                                        //     ? "cover-left-up lu-second BleedingTwo"
                                        //     : "cover-left-up lu-second "
                                        // }
                                        class={
                                          this.state.divactive === 13 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-up lu-second BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 13
                                            ? "cover-left-up lu-second plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 13
                                            ? "cover-left-up lu-second Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 13
                                            ? "cover-left-up lu-second Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 13
                                            ? "cover-left-up lu-second BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 13
                                            ? "cover-left-up lu-second BleedingTwo"
                                            : "cover-left-up lu-second "
                                        }
                                        id="cover_13"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <div
                                class={
                                  this.state.divactive == 14 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(14);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[14]) {
                                    var index14 =
                                      this.state.patientTeethId.indexOf(14);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index14
                                        ] &&
                                        this.state.patientDiceseRecord[index14]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[14] &&
                                          this.state.patientDiceseRecord[14]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_14
                                              // class="cover-left-up lu-third plaque"
                                              class={
                                                this.state.divactive === 14 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 14
                                                  ? "cover-left-up lu-third  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 14
                                                  ? "cover-left-up lu-third     Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 14
                                                  ? "cover-left-up lu-third     Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 14
                                                  ? "cover-left-up lu-third     BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 14
                                                  ? "cover-left-up lu-third     BleedingTwo"
                                                  : "cover-left-up lu-third plaque"
                                              }
                                              id="cover_14"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index14
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_14
                                                // class="cover-left-up lu-third BleedingTwo "
                                                class={
                                                  this.state.divactive === 14 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 14
                                                    ? "cover-left-up lu-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingTwo"
                                                    : "cover-left-up lu-third     BleedingTwo"
                                                }
                                                id="cover_14"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index14
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_14
                                                // class="cover-left-up lu-third Bleeding"
                                                class={
                                                  this.state.divactive === 14 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 14
                                                    ? "cover-left-up lu-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingTwo"
                                                    : "cover-left-up lu-third     Bleeding"
                                                }
                                                id="cover_14"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index14
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_14
                                                // class="cover-left-up lu-third plaque"
                                                class={
                                                  this.state.divactive === 14 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 14
                                                    ? "cover-left-up lu-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingTwo"
                                                    : "cover-left-up lu-third plaque"
                                                }
                                                id="cover_14"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index14
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_14
                                                // class="cover-left-up lu-third Abscess"
                                                class={
                                                  this.state.divactive === 14 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 14
                                                    ? "cover-left-up lu-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingTwo"
                                                    : "cover-left-up lu-third   Abscess"
                                                }
                                                id="cover_14"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index14
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_14
                                                // class="cover-left-up lu-third BleedingAbscess"
                                                class={
                                                  this.state.divactive === 14 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 14
                                                    ? "cover-left-up lu-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        14
                                                    ? "cover-left-up lu-third     BleedingTwo"
                                                    : "cover-left-up lu-third     BleedingAbscess"
                                                }
                                                id="cover_14"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_14
                                        // class={
                                        //   this.state.divactive === 14
                                        //     ? "cover-left-up lu-third BleedingTwo "
                                        //     : "cover-left-up lu-third "
                                        // }
                                        class={
                                          this.state.divactive === 14 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-up lu-third BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 14
                                            ? "cover-left-up lu-third plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 14
                                            ? "cover-left-up lu-third Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 14
                                            ? "cover-left-up lu-third Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 14
                                            ? "cover-left-up lu-third BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 14
                                            ? "cover-left-up lu-third BleedingTwo"
                                            : "cover-left-up lu-third "
                                        }
                                        id="cover_14"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <div
                                class={
                                  this.state.divactive == 15 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(15);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[15]) {
                                    var index15 =
                                      this.state.patientTeethId.indexOf(15);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index15
                                        ] &&
                                        this.state.patientDiceseRecord[index15]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[15] &&
                                          this.state.patientDiceseRecord[15]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_15
                                              // class="cover-left-up lu-fourth red plaque"
                                              class={
                                                this.state.divactive === 15 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 15
                                                  ? "cover-left-up lu-fourth red   plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 15
                                                  ? "cover-left-up lu-fourth red      Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 15
                                                  ? "cover-left-up lu-fourth red      Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 15
                                                  ? "cover-left-up lu-fourth red      BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 15
                                                  ? "cover-left-up lu-fourth red  BleedingTwo"
                                                  : "cover-left-up lu-fourth red  plaque"
                                              }
                                              id="cover_15"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index15
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_15
                                                // class="cover-left-up lu-fourth red BleedingTwo"
                                                class={
                                                  this.state.divactive === 15 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 15
                                                    ? "cover-left-up lu-fourth red   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingTwo"
                                                    : "cover-left-up lu-fourth red      BleedingTwo"
                                                }
                                                id="cover_15"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index15
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_15
                                                // class="cover-left-up lu-fourth red Bleeding"
                                                class={
                                                  this.state.divactive === 15 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 15
                                                    ? "cover-left-up lu-fourth red   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingTwo"
                                                    : "cover-left-up lu-fourth red      Bleeding"
                                                }
                                                id="cover_15"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index15
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_15
                                                // class="cover-left-up lu-fourth red plaque"
                                                class={
                                                  this.state.divactive === 15 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 15
                                                    ? "cover-left-up lu-fourth red   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingTwo"
                                                    : "cover-left-up lu-fourth red  plaque"
                                                }
                                                id="cover_15"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index15
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_15
                                                // class="cover-left-up lu-fourth red Abscess"
                                                class={
                                                  this.state.divactive === 15 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 15
                                                    ? "cover-left-up lu-fourth red   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red  BleedingTwo"
                                                    : "cover-left-up lu-fourth red Abscess"
                                                }
                                                id="cover_15"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index15
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_15
                                                // class="cover-left-up lu-fourth red BleedingAbscess"
                                                class={
                                                  this.state.divactive === 15 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 15
                                                    ? "cover-left-up lu-fourth red   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        15
                                                    ? "cover-left-up lu-fourth red      BleedingTwo"
                                                    : "cover-left-up lu-fourth red      BleedingAbscess"
                                                }
                                                id="cover_15"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_15
                                        // class="cover-left-up lu-fourth red"

                                        class={
                                          this.state.divactive === 15 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-up lu-fourth red BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 15
                                            ? "cover-left-up lu-fourth red plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 15
                                            ? "cover-left-up lu-fourth red Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 15
                                            ? "cover-left-up lu-fourth red Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 15
                                            ? "cover-left-up lu-fourth red BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 15
                                            ? "cover-left-up lu-fourth red BleedingTwo"
                                            : "cover-left-up lu-fourth red "
                                        }
                                        id="cover_15"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 16 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(16);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[16]) {
                                    var index16 =
                                      this.state.patientTeethId.indexOf(16);
                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index16
                                        ] &&
                                        this.state.patientDiceseRecord[index16]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index16
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index16
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_16
                                              // class="cover-left-up lu-fifth plaque"
                                              class={
                                                this.state.divactive === 16 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 16
                                                  ? "cover-left-up lu-fifth   plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 16
                                                  ? "cover-left-up lu-fifth      Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 16
                                                  ? "cover-left-up lu-fifth      Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 16
                                                  ? "cover-left-up lu-fifth      BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 16
                                                  ? "cover-left-up lu-fifth      BleedingTwo"
                                                  : "cover-left-up lu-fifth      plaque"
                                              }
                                              id="cover_16"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index16
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_16
                                                // class="cover-left-up lu-fifth BleedingTwo"
                                                class={
                                                  this.state.divactive === 16 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 16
                                                    ? "cover-left-up lu-fifth   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingTwo"
                                                    : "cover-left-up lu-fifth      BleedingTwo"
                                                }
                                                id="cover_16"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index16
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_16
                                                // class="cover-left-up lu-fifth Bleeding"
                                                class={
                                                  this.state.divactive === 16 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 16
                                                    ? "cover-left-up lu-fifth   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingTwo"
                                                    : "cover-left-up lu-fifth      Bleeding"
                                                }
                                                id="cover_16"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index16
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_16
                                                // class="cover-left-up lu-fifth plaque"
                                                class={
                                                  this.state.divactive === 16 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 16
                                                    ? "cover-left-up lu-fifth   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingTwo"
                                                    : "cover-left-up lu-fifth plaque"
                                                }
                                                id="cover_16"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index16
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_16
                                                // class="cover-left-up lu-fifth Abscess"
                                                class={
                                                  this.state.divactive === 16 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 16
                                                    ? "cover-left-up lu-fifth   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingTwo"
                                                    : "cover-left-up lu-fifth      Abscess"
                                                }
                                                id="cover_16"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index16
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_16
                                                // class="cover-left-up lu-fifth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 16 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 16
                                                    ? "cover-left-up lu-fifth   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        16
                                                    ? "cover-left-up lu-fifth      BleedingTwo"
                                                    : "cover-left-up lu-fifth      BleedingAbscess"
                                                }
                                                id="cover_16"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_16
                                        // class="cover-left-up lu-fifth"

                                        class={
                                          this.state.divactive === 16 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-up lu-fifth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 16
                                            ? "cover-left-up lu-fifth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 16
                                            ? "cover-left-up lu-fifth Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 16
                                            ? "cover-left-up lu-fifth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 16
                                            ? "cover-left-up lu-fifth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 16
                                            ? "cover-left-up lu-fifth BleedingTwo"
                                            : "cover-left-up lu-fifth "
                                        }
                                        id="cover_16"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <img
                                class="frame-right-up"
                                src="assets/images/TeethIcons/icn_tooth_left_up.png"
                              />
                              <div
                                class={
                                  this.state.divactive == 22 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(22);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[22]) {
                                    var index22 =
                                      this.state.patientTeethId.indexOf(22);
                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index22
                                        ] &&
                                        this.state.patientDiceseRecord[index22]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index22
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index22
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_22
                                              // class="cover-right-up ru-first plaque"
                                              class={
                                                this.state.divactive === 22 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 22
                                                  ? "cover-right-up ru-first  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 22
                                                  ? "cover-right-up ru-first     Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 22
                                                  ? "cover-right-up ru-first     Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 22
                                                  ? "cover-right-up ru-first     BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 22
                                                  ? "cover-right-up ru-first     BleedingTwo"
                                                  : "cover-right-up ru-first     plaque"
                                              }
                                              id="cover_22"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index22
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_22
                                                // class="cover-right-up ru-first BleedingTwo"
                                                class={
                                                  this.state.divactive === 22 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 22
                                                    ? "cover-right-up ru-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingTwo"
                                                    : "cover-right-up ru-first     BleedingTwo"
                                                }
                                                id="cover_22"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index22
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_22
                                                // class="cover-right-up ru-first Bleeding"
                                                class={
                                                  this.state.divactive === 22 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 22
                                                    ? "cover-right-up ru-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingTwo"
                                                    : "cover-right-up ru-first     Bleeding"
                                                }
                                                id="cover_22"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index22
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_22
                                                // class="cover-right-up ru-first plaque"
                                                class={
                                                  this.state.divactive === 22 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 22
                                                    ? "cover-right-up ru-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingTwo"
                                                    : "cover-right-up ru-first     plaque"
                                                }
                                                id="cover_22"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index22
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_22
                                                // class="cover-right-up ru-first Abscess"
                                                class={
                                                  this.state.divactive === 22 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 22
                                                    ? "cover-right-up ru-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingTwo"
                                                    : "cover-right-up ru-first     Abscess"
                                                }
                                                id="cover_22"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index22
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_22
                                                // class="cover-right-up ru-first BleedingAbscess"
                                                class={
                                                  this.state.divactive === 22 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 22
                                                    ? "cover-right-up ru-first  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        22
                                                    ? "cover-right-up ru-first     BleedingTwo"
                                                    : "cover-right-up ru-first     BleedingAbscess"
                                                }
                                                id="cover_22"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_22
                                        // class="cover-right-up ru-first"

                                        class={
                                          this.state.divactive === 22 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-up ru-first BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 22
                                            ? "cover-right-up ru-first plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 22
                                            ? "cover-right-up ru-first Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 22
                                            ? "cover-right-up ru-first Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 22
                                            ? "cover-right-up ru-first BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 22
                                            ? "cover-right-up ru-first BleedingTwo"
                                            : "cover-right-up ru-first "
                                        }
                                        id="cover_22"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 23 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(23);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[23]) {
                                    var index23 =
                                      this.state.patientTeethId.indexOf(23);
                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index23
                                        ] &&
                                        this.state.patientDiceseRecord[index23]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index23
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index23
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_23
                                              // class="cover-right-up ru-second plaque"
                                              class={
                                                this.state.divactive === 23 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 23
                                                  ? "cover-right-up ru-second  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 23
                                                  ? "cover-right-up ru-second     Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 23
                                                  ? "cover-right-up ru-second     Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 23
                                                  ? "cover-right-up ru-second     BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 23
                                                  ? "cover-right-up ru-second     BleedingTwo"
                                                  : "cover-right-up ru-second     plaque"
                                              }
                                              id="cover_23"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index23
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_23
                                                // class="cover-right-up ru-second BleedingTwo"
                                                class={
                                                  this.state.divactive === 23 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 23
                                                    ? "cover-right-up ru-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingTwo"
                                                    : "cover-right-up ru-second     BleedingTwo"
                                                }
                                                id="cover_23"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index23
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_23
                                                // class="cover-right-up ru-second Bleeding"
                                                class={
                                                  this.state.divactive === 23 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 23
                                                    ? "cover-right-up ru-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingTwo"
                                                    : "cover-right-up ru-second     Bleeding"
                                                }
                                                id="cover_23"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index23
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_23
                                                // class="cover-right-up ru-second plaque"
                                                class={
                                                  this.state.divactive === 23 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 23
                                                    ? "cover-right-up ru-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingTwo"
                                                    : "cover-right-up ru-second     plaque"
                                                }
                                                id="cover_23"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index23
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_23
                                                // class="cover-right-up ru-second Abscess"
                                                class={
                                                  this.state.divactive === 23 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 23
                                                    ? "cover-right-up ru-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingTwo"
                                                    : "cover-right-up ru-second     Abscess"
                                                }
                                                id="cover_23"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index23
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_23
                                                // class="cover-right-up ru-second BleedingAbscess"

                                                class={
                                                  this.state.divactive === 23 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 23
                                                    ? "cover-right-up ru-second  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        23
                                                    ? "cover-right-up ru-second     BleedingTwo"
                                                    : "cover-right-up ru-second     BleedingAbscess"
                                                }
                                                id="cover_23"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_23
                                        // class="cover-right-up ru-second"

                                        class={
                                          this.state.divactive === 23 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-up ru-second BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 23
                                            ? "cover-right-up ru-second plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 23
                                            ? "cover-right-up ru-second Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 23
                                            ? "cover-right-up ru-second Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 23
                                            ? "cover-right-up ru-second BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 23
                                            ? "cover-right-up ru-second BleedingTwo"
                                            : "cover-right-up ru-second "
                                        }
                                        id="cover_23"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 24 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(24);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[24]) {
                                    var index24 =
                                      this.state.patientTeethId.indexOf(24);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index24
                                        ] &&
                                        this.state.patientDiceseRecord[index24]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index24
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index24
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_24
                                              // class="cover-right-up ru-third plaque"
                                              class={
                                                this.state.divactive === 24 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 24
                                                  ? "cover-right-up ru-third plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 24
                                                  ? "cover-right-up ru-third    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 24
                                                  ? "cover-right-up ru-third    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 24
                                                  ? "cover-right-up ru-third    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 24
                                                  ? "cover-right-up ru-third    BleedingTwo"
                                                  : "cover-right-up ru-third plaque"
                                              }
                                              id="cover_24"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index24
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_24
                                                // class="cover-right-up ru-third BleedingTwo"
                                                class={
                                                  this.state.divactive === 24 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 24
                                                    ? "cover-right-up ru-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingTwo"
                                                    : "cover-right-up ru-third    BleedingTwo"
                                                }
                                                id="cover_24"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index24
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_24
                                                // class="cover-right-up ru-third Bleeding"
                                                class={
                                                  this.state.divactive === 24 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 24
                                                    ? "cover-right-up ru-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingTwo"
                                                    : "cover-right-up ru-third    Bleeding"
                                                }
                                                id="cover_24"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index24
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_24
                                                // class="cover-right-up ru-third plaque"
                                                class={
                                                  this.state.divactive === 24 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 24
                                                    ? "cover-right-up ru-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingTwo"
                                                    : "cover-right-up ru-third plaque"
                                                }
                                                id="cover_24"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index24
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_24
                                                // class="cover-right-up ru-third Abscess"
                                                class={
                                                  this.state.divactive === 24 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 24
                                                    ? "cover-right-up ru-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingTwo"
                                                    : "cover-right-up ru-third    Abscess"
                                                }
                                                id="cover_24"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index24
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_24
                                                // class="cover-right-up ru-third BleedingAbscess"
                                                class={
                                                  this.state.divactive === 24 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 24
                                                    ? "cover-right-up ru-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        24
                                                    ? "cover-right-up ru-third    BleedingTwo"
                                                    : "cover-right-up ru-third    BleedingAbscess"
                                                }
                                                id="cover_24"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_24
                                        // class="cover-right-up ru-third"

                                        class={
                                          this.state.divactive === 24 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-up ru-third BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 24
                                            ? "cover-right-up ru-third plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 24
                                            ? "cover-right-up ru-third Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 24
                                            ? "cover-right-up ru-third Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 24
                                            ? "cover-right-up ru-third BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 24
                                            ? "cover-right-up ru-third BleedingTwo"
                                            : "cover-right-up ru-third "
                                        }
                                        id="cover_24"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 25 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(25);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[25]) {
                                    var index25 =
                                      this.state.patientTeethId.indexOf(25);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index25
                                        ] &&
                                        this.state.patientDiceseRecord[index25]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index25
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index25
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_25
                                              // class="cover-right-up ru-fourth plaque"
                                              class={
                                                this.state.divactive === 25 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 25
                                                  ? "cover-right-up ru-fourth plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 25
                                                  ? "cover-right-up ru-fourth    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 25
                                                  ? "cover-right-up ru-fourth    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 25
                                                  ? "cover-right-up ru-fourth    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 25
                                                  ? "cover-right-up ru-fourth    BleedingTwo"
                                                  : "cover-right-up ru-fourth plaque"
                                              }
                                              id="cover_25"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index25
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_25
                                                // class="cover-right-up ru-fourth BleedingTwo"
                                                class={
                                                  this.state.divactive === 25 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 25
                                                    ? "cover-right-up ru-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingTwo"
                                                    : "cover-right-up ru-fourth    BleedingTwo"
                                                }
                                                id="cover_25"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index25
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_25
                                                // class="cover-right-up ru-fourth Bleeding"
                                                class={
                                                  this.state.divactive === 25 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 25
                                                    ? "cover-right-up ru-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingTwo"
                                                    : "cover-right-up ru-fourth    Bleeding"
                                                }
                                                id="cover_25"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index25
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_25
                                                // class="cover-right-up ru-fourth plaque"
                                                class={
                                                  this.state.divactive === 25 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 25
                                                    ? "cover-right-up ru-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingTwo"
                                                    : "cover-right-up ru-fourth    plaque"
                                                }
                                                id="cover_25"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index25
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_25
                                                // class="cover-right-up ru-fourth Abscess"
                                                class={
                                                  this.state.divactive === 25 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 25
                                                    ? "cover-right-up ru-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingTwo"
                                                    : "cover-right-up ru-fourth    Abscess"
                                                }
                                                id="cover_25"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index25
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_25
                                                // class="cover-right-up ru-fourth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 25 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 25
                                                    ? "cover-right-up ru-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        25
                                                    ? "cover-right-up ru-fourth    BleedingTwo"
                                                    : "cover-right-up ru-fourth    BleedingAbscess"
                                                }
                                                id="cover_25"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_25
                                        // class="cover-right-up ru-fourth"

                                        class={
                                          this.state.divactive === 25 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-up ru-fourth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 25
                                            ? "cover-right-up ru-fourth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 25
                                            ? "cover-right-up ru-fourth Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 25
                                            ? "cover-right-up ru-fourth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 25
                                            ? "cover-right-up ru-fourth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 25
                                            ? "cover-right-up ru-fourth BleedingTwo"
                                            : "cover-right-up ru-fourth "
                                        }
                                        id="cover_25"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 26 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(26);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[26]) {
                                    var index26 =
                                      this.state.patientTeethId.indexOf(26);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index26
                                        ] &&
                                        this.state.patientDiceseRecord[index26]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index26
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index26
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_26
                                              // class="cover-right-up ru-fifth plaque"
                                              class={
                                                this.state.divactive === 26 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 26
                                                  ? "cover-right-up ru-fifth plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 26
                                                  ? "cover-right-up ru-fifth    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 26
                                                  ? "cover-right-up ru-fifth    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 26
                                                  ? "cover-right-up ru-fifth    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 26
                                                  ? "cover-right-up ru-fifth    BleedingTwo"
                                                  : "cover-right-up ru-fifth    plaque"
                                              }
                                              id="cover_26"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index26
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_26
                                                // class="cover-right-up ru-fifth BleedingTwo"
                                                class={
                                                  this.state.divactive === 26 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 26
                                                    ? "cover-right-up ru-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingTwo"
                                                    : "cover-right-up ru-fifth    BleedingTwo"
                                                }
                                                id="cover_26"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index26
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_26
                                                // class="cover-right-up ru-fifth Bleeding"
                                                class={
                                                  this.state.divactive === 26 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 26
                                                    ? "cover-right-up ru-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingTwo"
                                                    : "cover-right-up ru-fifth    Bleeding"
                                                }
                                                id="cover_26"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index26
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_26
                                                // class="cover-right-up ru-fifth plaque"
                                                class={
                                                  this.state.divactive === 26 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 26
                                                    ? "cover-right-up ru-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingTwo"
                                                    : "cover-right-up ru-fifth    plaque"
                                                }
                                                id="cover_26"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index26
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_26
                                                // class="cover-right-up ru-fifth Abscess"
                                                class={
                                                  this.state.divactive === 26 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 26
                                                    ? "cover-right-up ru-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingTwo"
                                                    : "cover-right-up ru-fifth    Abscess"
                                                }
                                                id="cover_26"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index26
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_26
                                                // class="cover-right-up ru-fifth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 26 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 26
                                                    ? "cover-right-up ru-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        26
                                                    ? "cover-right-up ru-fifth    BleedingTwo"
                                                    : "cover-right-up ru-fifth    BleedingAbscess"
                                                }
                                                id="cover_26"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_26
                                        // class="cover-right-up ru-fifth"

                                        class={
                                          this.state.divactive === 26 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-up ru-fifth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 26
                                            ? "cover-right-up ru-fifth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 26
                                            ? "cover-right-up ru-fifth Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 26
                                            ? "cover-right-up ru-fifth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 26
                                            ? "cover-right-up ru-fifth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 26
                                            ? "cover-right-up ru-fifth BleedingTwo"
                                            : "cover-right-up ru-fifth "
                                        }
                                        id="cover_26"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <img
                                class="frame-up"
                                src="assets/images/TeethIcons/icn_tooth_up.png"
                              />
                              <div
                                class={
                                  this.state.divactive == 0 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(0);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[0]) {
                                    var index0 =
                                      this.state.patientTeethId.indexOf(0);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index0
                                        ] &&
                                        this.state.patientDiceseRecord[index0]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index0
                                          ] &&
                                          this.state.patientDiceseRecord[index0]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_0
                                              // class="cover-up cu-first plaque"
                                              class={
                                                this.state.divactive === 0 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 0
                                                  ? "cover-up cu-first plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 0
                                                  ? "cover-up cu-first    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 0
                                                  ? "cover-up cu-first    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 0
                                                  ? "cover-up cu-first    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 0
                                                  ? "cover-up cu-first    BleedingTwo"
                                                  : "cover-up cu-first plaque"
                                              }
                                              id="cover_0"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index0
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_0
                                                // class="cover-up cu-first BleedingTwo"
                                                class={
                                                  this.state.divactive === 0 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 0
                                                    ? "cover-up cu-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingTwo"
                                                    : "cover-up cu-first    BleedingTwo"
                                                }
                                                id="cover_0"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index0
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_0
                                                // class="cover-up cu-first Bleeding"
                                                class={
                                                  this.state.divactive === 0 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 0
                                                    ? "cover-up cu-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingTwo"
                                                    : "cover-up cu-first    Bleeding"
                                                }
                                                id="cover_0"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index0
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_0
                                                // class="cover-up cu-first plaque"
                                                class={
                                                  this.state.divactive === 0 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 0
                                                    ? "cover-up cu-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingTwo"
                                                    : "cover-up cu-first plaque"
                                                }
                                                id="cover_0"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index0
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_0
                                                // class="cover-up cu-first Abscess"

                                                class={
                                                  this.state.divactive === 0 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 0
                                                    ? "cover-up cu-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingTwo"
                                                    : "cover-up cu-first    Abscess"
                                                }
                                                id="cover_0"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index0
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_0
                                                // class="cover-up cu-first BleedingAbscess"
                                                class={
                                                  this.state.divactive === 0 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 0
                                                    ? "cover-up cu-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 0
                                                    ? "cover-up cu-first    BleedingTwo"
                                                    : "cover-up cu-first    BleedingAbscess"
                                                }
                                                id="cover_0"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_0
                                        // class="cover-up cu-first"

                                        class={
                                          this.state.divactive === 0 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-up cu-first BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 0
                                            ? "cover-up cu-first plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 0
                                            ? "cover-up cu-first Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 0
                                            ? "cover-up cu-first Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 0
                                            ? "cover-up cu-first BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 0
                                            ? "cover-up cu-first BleedingTwo"
                                            : "cover-up cu-first "
                                        }
                                        id="cover_0"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <div
                                class={
                                  this.state.divactive == 1 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(1);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[1]) {
                                    var index1 =
                                      this.state.patientTeethId.indexOf(1);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index1
                                        ] &&
                                        this.state.patientDiceseRecord[index1]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index1
                                          ] &&
                                          this.state.patientDiceseRecord[index1]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_1
                                              // class="cover-up cu-second plaque"
                                              class={
                                                this.state.divactive === 1 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 1
                                                  ? "cover-up cu-second plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 1
                                                  ? "cover-up cu-second    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 1
                                                  ? "cover-up cu-second    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 1
                                                  ? "cover-up cu-second    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 1
                                                  ? "cover-up cu-second    BleedingTwo"
                                                  : "cover-up cu-second plaque"
                                              }
                                              id="cover_1"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index1
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_1
                                                // class="cover-up cu-second BleedingTwo"
                                                class={
                                                  this.state.divactive === 1 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 1
                                                    ? "cover-up cu-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingTwo"
                                                    : "cover-up cu-second    BleedingTwo"
                                                }
                                                id="cover_1"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index1
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_1
                                                // class="cover-up cu-second Bleeding"
                                                class={
                                                  this.state.divactive === 1 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 1
                                                    ? "cover-up cu-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingTwo"
                                                    : "cover-up cu-second    Bleeding"
                                                }
                                                id="cover_1"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index1
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_1
                                                // class="cover-up cu-second plaque"
                                                class={
                                                  this.state.divactive === 1 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 1
                                                    ? "cover-up cu-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingTwo"
                                                    : "cover-up cu-second plaque"
                                                }
                                                id="cover_1"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index1
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_1
                                                // class="cover-up cu-second Abscess"
                                                class={
                                                  this.state.divactive === 1 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 1
                                                    ? "cover-up cu-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingTwo"
                                                    : "cover-up cu-second    Abscess"
                                                }
                                                id="cover_1"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index1
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_1
                                                // class="cover-up cu-second BleedingAbscess"
                                                class={
                                                  this.state.divactive === 1 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 1
                                                    ? "cover-up cu-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 1
                                                    ? "cover-up cu-second    BleedingTwo"
                                                    : "cover-up cu-second    BleedingAbscess"
                                                }
                                                id="cover_1"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_1
                                        // class="cover-up cu-first"

                                        class={
                                          this.state.divactive === 1 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-up cu-second BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 1
                                            ? "cover-up cu-second plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 1
                                            ? "cover-up cu-second Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 1
                                            ? "cover-up cu-second Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 1
                                            ? "cover-up cu-second BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 1
                                            ? "cover-up cu-second BleedingTwo"
                                            : "cover-up cu-second "
                                        }
                                        id="cover_1"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <div
                                class={
                                  this.state.divactive == 2 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(2);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[2]) {
                                    var index2 =
                                      this.state.patientTeethId.indexOf(2);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index2
                                        ] &&
                                        this.state.patientDiceseRecord[index2]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index2
                                          ] &&
                                          this.state.patientDiceseRecord[index2]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_2
                                              // class="cover-up cu-third plaque"
                                              class={
                                                this.state.divactive === 2 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 2
                                                  ? "cover-up cu-third plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 2
                                                  ? "cover-up cu-third    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 2
                                                  ? "cover-up cu-third    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 2
                                                  ? "cover-up cu-third    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 2
                                                  ? "cover-up cu-third    BleedingTwo"
                                                  : "cover-up cu-third plaque"
                                              }
                                              id="cover_2"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index2
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_2
                                                // class="cover-up cu-third BleedingTwo"
                                                class={
                                                  this.state.divactive === 2 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 2
                                                    ? "cover-up cu-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingTwo"
                                                    : "cover-up cu-third    BleedingTwo"
                                                }
                                                id="cover_2"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index2
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_2
                                                // class="cover-up cu-third Bleeding"
                                                class={
                                                  this.state.divactive === 2 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 2
                                                    ? "cover-up cu-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingTwo"
                                                    : "cover-up cu-third Bleeding"
                                                }
                                                id="cover_2"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index2
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_2
                                                // class="cover-up cu-third plaque"
                                                class={
                                                  this.state.divactive === 2 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 2
                                                    ? "cover-up cu-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingTwo"
                                                    : "cover-up cu-third plaque"
                                                }
                                                id="cover_2"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index2
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_2
                                                // class="cover-up cu-third Abscess"
                                                class={
                                                  this.state.divactive === 2 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 2
                                                    ? "cover-up cu-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingTwo"
                                                    : "cover-up cu-third    Abscess"
                                                }
                                                id="cover_2"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index2
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_2
                                                // class="cover-up cu-third BleedingAbscess"
                                                class={
                                                  this.state.divactive === 2 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 2
                                                    ? "cover-up cu-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 2
                                                    ? "cover-up cu-third    BleedingTwo"
                                                    : "cover-up cu-third    BleedingAbscess"
                                                }
                                                id="cover_2"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_2
                                        // class="cover-up cu-first"

                                        class={
                                          this.state.divactive === 2 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-up cu-third BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 2
                                            ? "cover-up cu-third plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 2
                                            ? "cover-up cu-third  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 2
                                            ? "cover-up cu-third Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 2
                                            ? "cover-up cu-third BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 2
                                            ? "cover-up cu-third BleedingTwo"
                                            : "cover-up cu-third "
                                        }
                                        id="cover_2"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <div
                                class={
                                  this.state.divactive == 3 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(3);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[3]) {
                                    var index3 =
                                      this.state.patientTeethId.indexOf(3);
                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index3
                                        ] &&
                                        this.state.patientDiceseRecord[index3]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index3
                                          ] &&
                                          this.state.patientDiceseRecord[index3]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_3
                                              // class="cover-up cu-fourth plaque"
                                              class={
                                                this.state.divactive === 3 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 3
                                                  ? "cover-up cu-fourth  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 3
                                                  ? "cover-up cu-fourth    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 3
                                                  ? "cover-up cu-fourth    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 3
                                                  ? "cover-up cu-fourth    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 3
                                                  ? "cover-up cu-fourth    BleedingTwo"
                                                  : "cover-up cu-fourth plaque"
                                              }
                                              id="cover_3"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index3
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_3
                                                // class="cover-up cu-fourth BleedingTwo"
                                                class={
                                                  this.state.divactive === 3 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 3
                                                    ? "cover-up cu-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingTwo"
                                                    : "cover-up cu-fourth    BleedingTwo"
                                                }
                                                id="cover_3"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index3
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_3
                                                // class="cover-up cu-fourth Bleeding"

                                                class={
                                                  this.state.divactive === 3 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 3
                                                    ? "cover-up cu-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingTwo"
                                                    : "cover-up cu-fourth    Bleeding"
                                                }
                                                id="cover_3"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index3
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_3
                                                // class="cover-up cu-fourth plaque"
                                                class={
                                                  this.state.divactive === 3 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 3
                                                    ? "cover-up cu-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingTwo"
                                                    : "cover-up cu-fourth plaque"
                                                }
                                                id="cover_3"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index3
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_3
                                                // class="cover-up cu-fourth Abscess"
                                                class={
                                                  this.state.divactive === 3 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 3
                                                    ? "cover-up cu-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingTwo"
                                                    : "cover-up cu-fourth    Abscess"
                                                }
                                                id="cover_3"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index3
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_3
                                                // class="cover-up cu-fourth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 3 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 3
                                                    ? "cover-up cu-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 3
                                                    ? "cover-up cu-fourth    BleedingTwo"
                                                    : "cover-up cu-fourth    BleedingAbscess"
                                                }
                                                id="cover_3"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_3
                                        // class="cover-up cu-fourth"

                                        class={
                                          this.state.divactive === 3 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch &&
                                          this.state.divactive === 3
                                            ? "cover-up cu-fourth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 3
                                            ? "cover-up cu-fourth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 3
                                            ? "cover-up cu-fourth  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 3
                                            ? "cover-up cu-fourth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 3
                                            ? "cover-up cu-fourth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 3
                                            ? "cover-up cu-fourth BleedingTwo"
                                            : "cover-up cu-fourth "
                                        }
                                        id="cover_3"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 4 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(4);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[4]) {
                                    var index4 =
                                      this.state.patientTeethId.indexOf(4);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index4
                                        ] &&
                                        this.state.patientDiceseRecord[index4]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index4
                                          ] &&
                                          this.state.patientDiceseRecord[index4]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_4
                                              // class="cover-up cu-fifth plaque"
                                              class={
                                                this.state.divactive === 4 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 4
                                                  ? "cover-up cu-fifth  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 4
                                                  ? "cover-up cu-fifth    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 4
                                                  ? "cover-up cu-fifth    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 4
                                                  ? "cover-up cu-fifth    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 4
                                                  ? "cover-up cu-fifth    BleedingTwo"
                                                  : "cover-up cu-fifth plaque"
                                              }
                                              id="cover_4"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index4
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_4
                                                // class="cover-up cu-fifth BleedingTwo"
                                                class={
                                                  this.state.divactive === 4 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 4
                                                    ? "cover-up cu-fifth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingTwo"
                                                    : "cover-up cu-fifth    BleedingTwo"
                                                }
                                                id="cover_4"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index4
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_4
                                                // class="cover-up cu-fifth Bleeding"
                                                class={
                                                  this.state.divactive === 4 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 4
                                                    ? "cover-up cu-fifth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingTwo"
                                                    : "cover-up cu-fifth    Bleeding"
                                                }
                                                id="cover_4"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index4
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_4
                                                // class="cover-up cu-fifth plaque"
                                                class={
                                                  this.state.divactive === 4 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 4
                                                    ? "cover-up cu-fifth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingTwo"
                                                    : "cover-up cu-fifth plaque"
                                                }
                                                id="cover_4"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index4
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_4
                                                // class="cover-up cu-fifth Abscess"
                                                class={
                                                  this.state.divactive === 4 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 4
                                                    ? "cover-up cu-fifth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingTwo"
                                                    : "cover-up cu-fifth    Abscess"
                                                }
                                                id="cover_4"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index4
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_4
                                                // class="cover-up cu-fifth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 4 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 4
                                                    ? "cover-up cu-fifth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 4
                                                    ? "cover-up cu-fifth    BleedingTwo"
                                                    : "cover-up cu-fifth    BleedingAbscess"
                                                }
                                                id="cover_4"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_4
                                        // class="cover-up cu-fifth"

                                        class={
                                          this.state.divactive === 4 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-up cu-fifth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 4
                                            ? "cover-up cu-fifth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 4
                                            ? "cover-up cu-fifth  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 4
                                            ? "cover-up cu-fifth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 4
                                            ? "cover-up cu-fifth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 4
                                            ? "cover-up cu-fifth BleedingTwo"
                                            : "cover-up cu-fifth "
                                        }
                                        id="cover_4"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 5 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(5);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[5]) {
                                    var index5 =
                                      this.state.patientTeethId.indexOf(5);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index5
                                        ] &&
                                        this.state.patientDiceseRecord[index5]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index5
                                          ] &&
                                          this.state.patientDiceseRecord[index5]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_5
                                              // class="cover-up cu-sixth plaque"
                                              class={
                                                this.state.divactive === 5 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 5
                                                  ? "cover-up cu-sixth    plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 5
                                                  ? "cover-up cu-sixth    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 5
                                                  ? "cover-up cu-sixth    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 5
                                                  ? "cover-up cu-sixth    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 5
                                                  ? "cover-up cu-sixth    BleedingTwo"
                                                  : "cover-up cu-sixth plaque"
                                              }
                                              id="cover_5"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index5
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_5
                                                // class="cover-up cu-sixth BleedingTwo"
                                                class={
                                                  this.state.divactive === 5 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 5
                                                    ? "cover-up cu-sixth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingTwo"
                                                    : "cover-up cu-sixth    BleedingTwo"
                                                }
                                                id="cover_5"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index5
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_5
                                                // class="cover-up cu-sixth Bleeding"
                                                class={
                                                  this.state.divactive === 5 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 5
                                                    ? "cover-up cu-sixth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingTwo"
                                                    : "cover-up cu-sixth    Bleeding"
                                                }
                                                id="cover_5"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index5
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_5
                                                // class="cover-up cu-sixth plaque"
                                                class={
                                                  this.state.divactive === 5 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 5
                                                    ? "cover-up cu-sixth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingTwo"
                                                    : "cover-up cu-sixth plaque"
                                                }
                                                id="cover_5"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index5
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_5
                                                // class="cover-up cu-sixth Abscess"
                                                class={
                                                  this.state.divactive === 5 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 5
                                                    ? "cover-up cu-sixth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingTwo"
                                                    : "cover-up cu-sixth    Abscess"
                                                }
                                                id="cover_5"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index5
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_5
                                                // class="cover-up cu-sixth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 5 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 5
                                                    ? "cover-up cu-sixth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 5
                                                    ? "cover-up cu-sixth    BleedingTwo"
                                                    : "cover-up cu-sixth    BleedingAbscess"
                                                }
                                                id="cover_5"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_5
                                        // class={
                                        //   this.state.divactive === 5
                                        //     ? "cover-up cu-sixth BleedingTwo"
                                        //     : "cover-up cu-sixth"
                                        // }
                                        class={
                                          this.state.divactive === 5 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-up cu-sixth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 5
                                            ? "cover-up cu-sixth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 5
                                            ? "cover-up cu-sixth  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 5
                                            ? "cover-up cu-sixth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 5
                                            ? "cover-up cu-sixth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 5
                                            ? "cover-up cu-sixth BleedingTwo"
                                            : "cover-up cu-sixth "
                                        }
                                        id="cover_5"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <img
                                class="frame-left-down"
                                src="assets/images/TeethIcons/icn_tooth_left_down.png"
                              />

                              <div
                                class={
                                  this.state.divactive == 17 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(17);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[17]) {
                                    var index17 =
                                      this.state.patientTeethId.indexOf(17);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index17
                                        ] &&
                                        this.state.patientDiceseRecord[index17]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index17
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index17
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_17
                                              // class="cover-left-down ld-first plaque"
                                              class={
                                                this.state.divactive === 17 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 17
                                                  ? "cover-left-down ld-first    plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 17
                                                  ? "cover-left-down ld-first    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 17
                                                  ? "cover-left-down ld-first    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 17
                                                  ? "cover-left-down ld-first    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 17
                                                  ? "cover-left-down ld-first    BleedingTwo"
                                                  : "cover-left-down ld-first    plaque"
                                              }
                                              id="cover_17"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index17
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_17
                                                // class="cover-left-down ld-first BleedingTwo"
                                                class={
                                                  this.state.divactive === 17 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 17
                                                    ? "cover-left-down ld-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingTwo"
                                                    : "cover-left-down ld-first    BleedingTwo"
                                                }
                                                id="cover_17"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index17
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_17
                                                // class="cover-left-down ld-first Bleeding"
                                                class={
                                                  this.state.divactive === 17 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 17
                                                    ? "cover-left-down ld-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingTwo"
                                                    : "cover-left-down ld-first    Bleeding"
                                                }
                                                id="cover_17"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index17
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_17
                                                // class="cover-left-down ld-first plaque"
                                                class={
                                                  this.state.divactive === 17 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 17
                                                    ? "cover-left-down ld-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingTwo"
                                                    : "cover-left-down ld-first    plaque"
                                                }
                                                id="cover_17"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index17
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_17
                                                // class="cover-left-down ld-first Abscess"
                                                class={
                                                  this.state.divactive === 17 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 17
                                                    ? "cover-left-down ld-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingTwo"
                                                    : "cover-left-down ld-first    Abscess"
                                                }
                                                id="cover_17"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index17
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_17
                                                // class="cover-left-down ld-first BleedingAbscess"
                                                class={
                                                  this.state.divactive === 17 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 17
                                                    ? "cover-left-down ld-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        17
                                                    ? "cover-left-down ld-first    BleedingTwo"
                                                    : "cover-left-down ld-first    BleedingAbscess"
                                                }
                                                id="cover_17"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_17
                                        // class={
                                        //   this.state.divactive === 17
                                        //     ? "cover-left-down ld-first BleedingTwo"
                                        //     : "cover-left-down ld-first"
                                        // }
                                        class={
                                          this.state.divactive === 17 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-down ld-first BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 17
                                            ? "cover-left-down ld-first plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 17
                                            ? "cover-left-down ld-first Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 17
                                            ? "cover-left-down ld-first Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 17
                                            ? "cover-left-down ld-first BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 17
                                            ? "cover-left-down ld-first BleedingTwo"
                                            : "cover-left-down ld-first "
                                        }
                                        // class="cover-left-down ld-second"
                                        id="cover_17"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <div
                                class={
                                  this.state.divactive == 18 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(18);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[18]) {
                                    var index18 =
                                      this.state.patientTeethId.indexOf(18);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index18
                                        ] &&
                                        this.state.patientDiceseRecord[index18]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index18
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index18
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_18
                                              // class="cover-left-down ld-second plaque"
                                              class={
                                                this.state.divactive === 18 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 18
                                                  ? "cover-left-down ld-second    plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 18
                                                  ? "cover-left-down ld-second    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 18
                                                  ? "cover-left-down ld-second    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 18
                                                  ? "cover-left-down ld-second    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 18
                                                  ? "cover-left-down ld-second    BleedingTwo"
                                                  : "cover-left-down ld-second    plaque"
                                              }
                                              id="cover_18"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index18
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_18
                                                // class="cover-left-down ld-second BleedingTwo"
                                                class={
                                                  this.state.divactive === 18 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 18
                                                    ? "cover-left-down ld-second    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingTwo"
                                                    : "cover-left-down ld-second    BleedingTwo"
                                                }
                                                id="cover_18"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index18
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_18
                                                // class="cover-left-down ld-second Bleeding"
                                                class={
                                                  this.state.divactive === 18 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 18
                                                    ? "cover-left-down ld-second    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingTwo"
                                                    : "cover-left-down ld-second    Bleeding"
                                                }
                                                id="cover_18"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index18
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_18
                                                // class="cover-left-down ld-second plaque"
                                                class={
                                                  this.state.divactive === 18 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 18
                                                    ? "cover-left-down ld-second    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingTwo"
                                                    : "cover-left-down ld-second    plaque"
                                                }
                                                id="cover_18"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index18
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_18
                                                // class="cover-left-down ld-second Abscess"
                                                class={
                                                  this.state.divactive === 18 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 18
                                                    ? "cover-left-down ld-second    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingTwo"
                                                    : "cover-left-down ld-second    Abscess"
                                                }
                                                id="cover_18"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index18
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_18
                                                // class="cover-left-down ld-second BleedingAbscess"
                                                class={
                                                  this.state.divactive === 18 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 18
                                                    ? "cover-left-down ld-second    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        18
                                                    ? "cover-left-down ld-second    BleedingTwo"
                                                    : "cover-left-down ld-second    BleedingAbscess"
                                                }
                                                id="cover_18"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_18
                                        // class={
                                        //   this.state.divactive === 18
                                        //     ? "cover-left-down ld-second BleedingTwo"
                                        //     : "cover-left-down ld-second"
                                        // }
                                        class={
                                          this.state.divactive === 18 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-down ld-second BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 18
                                            ? "cover-left-down ld-second plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 18
                                            ? "cover-left-down ld-second  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 18
                                            ? "cover-left-down ld-second Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 18
                                            ? "cover-left-down ld-second BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 18
                                            ? "cover-left-down ld-second BleedingTwo"
                                            : "cover-left-down ld-second "
                                        }
                                        // class="cover-left-down ld-second"
                                        id="cover_18"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                // class="active"
                                class={
                                  this.state.divactive == 19 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(19);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[19]) {
                                    var index19 =
                                      this.state.patientTeethId.indexOf(19);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index19
                                        ] &&
                                        this.state.patientDiceseRecord[index19]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index19
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index19
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_19
                                              // class="cover-left-down ld-third plaque"
                                              class={
                                                this.state.divactive === 19 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 19
                                                  ? "cover-left-down ld-third    plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 19
                                                  ? "cover-left-down ld-third    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 19
                                                  ? "cover-left-down ld-third    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 19
                                                  ? "cover-left-down ld-third    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 19
                                                  ? "cover-left-down ld-third    BleedingTwo"
                                                  : "cover-left-down ld-third    plaque"
                                              }
                                              id="cover_19"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index19
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_19
                                                // class="cover-left-down ld-third BleedingTwo"
                                                class={
                                                  this.state.divactive === 19 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 19
                                                    ? "cover-left-down ld-third    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingTwo"
                                                    : "cover-left-down ld-third    BleedingTwo"
                                                }
                                                id="cover_19"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index19
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_19
                                                // class="cover-left-down ld-third Bleeding"
                                                class={
                                                  this.state.divactive === 19 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 19
                                                    ? "cover-left-down ld-third    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingTwo"
                                                    : "cover-left-down ld-third    Bleeding"
                                                }
                                                id="cover_19"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index19
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_19
                                                // class="cover-left-down ld-third plaque"
                                                class={
                                                  this.state.divactive === 19 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 19
                                                    ? "cover-left-down ld-third    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingTwo"
                                                    : "cover-left-down ld-third    plaque"
                                                }
                                                id="cover_19"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index19
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_19
                                                // class="cover-left-down ld-third Abscess"
                                                class={
                                                  this.state.divactive === 19 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 19
                                                    ? "cover-left-down ld-third    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingTwo"
                                                    : "cover-left-down ld-third    Abscess"
                                                }
                                                id="cover_19"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index19
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_19
                                                // class="cover-left-down ld-third BleedingAbscess"
                                                class={
                                                  this.state.divactive === 19 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 19
                                                    ? "cover-left-down ld-third    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        19
                                                    ? "cover-left-down ld-third    BleedingTwo"
                                                    : "cover-left-down ld-third    BleedingAbscess"
                                                }
                                                id="cover_19"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_19
                                        // class={
                                        //   this.state.divactive === 19
                                        //     ? "cover-left-down ld-third BleedingTwo"
                                        //     : "cover-left-down ld-third"
                                        // }
                                        class={
                                          this.state.divactive === 19 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-down ld-third BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 19
                                            ? "cover-left-down ld-third plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 19
                                            ? "cover-left-down ld-third  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 19
                                            ? "cover-left-down ld-third Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 19
                                            ? "cover-left-down ld-third BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 19
                                            ? "cover-left-down ld-third BleedingTwo"
                                            : "cover-left-down ld-third "
                                        }
                                        // class="cover-left-down ld-third"
                                        id="cover_19"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 20 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(20);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[20]) {
                                    var index20 =
                                      this.state.patientTeethId.indexOf(20);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index20
                                        ] &&
                                        this.state.patientDiceseRecord[index20]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index20
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index20
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_20
                                              // class="cover-left-down ld-fourth plaque"

                                              class={
                                                this.state.divactive === 20 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 20
                                                  ? "cover-left-down ld-fourth    plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 20
                                                  ? "cover-left-down ld-fourth    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 20
                                                  ? "cover-left-down ld-fourth    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 20
                                                  ? "cover-left-down ld-fourth    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 20
                                                  ? "cover-left-down ld-fourth    BleedingTwo"
                                                  : "cover-left-down ld-fourth    plaque"
                                              }
                                              id="cover_20"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index20
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_20
                                                // class="cover-left-down ld-fourth BleedingTwo"
                                                class={
                                                  this.state.divactive === 20 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 20
                                                    ? "cover-left-down ld-fourth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingTwo"
                                                    : "cover-left-down ld-fourth    BleedingTwo"
                                                }
                                                id="cover_20"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index20
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_20
                                                // class="cover-left-down ld-fourth Bleeding"
                                                class={
                                                  this.state.divactive === 20 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 20
                                                    ? "cover-left-down ld-fourth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingTwo"
                                                    : "cover-left-down ld-fourth    Bleeding"
                                                }
                                                id="cover_20"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index20
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_20
                                                // class="cover-left-down ld-fourth plaque"
                                                class={
                                                  this.state.divactive === 20 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 20
                                                    ? "cover-left-down ld-fourth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingTwo"
                                                    : "cover-left-down ld-fourth    plaque"
                                                }
                                                id="cover_20"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index20
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_20
                                                // class="cover-left-down ld-fourth Abscess"
                                                class={
                                                  this.state.divactive === 20 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 20
                                                    ? "cover-left-down ld-fourth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingTwo"
                                                    : "cover-left-down ld-fourth    Abscess"
                                                }
                                                id="cover_20"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index20
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_20
                                                // class="cover-left-down ld-fourth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 20 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 20
                                                    ? "cover-left-down ld-fourth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        20
                                                    ? "cover-left-down ld-fourth    BleedingTwo"
                                                    : "cover-left-down ld-fourth    BleedingAbscess"
                                                }
                                                id="cover_20"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_20
                                        // class={
                                        //   this.state.divactive === 20
                                        //     ? "cover-left-down ld-fourth BleedingTwo"
                                        //     : "cover-left-down ld-fourth"
                                        // }
                                        class={
                                          this.state.divactive === 20 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-down ld-fourth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 20
                                            ? "cover-left-down ld-fourth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 20
                                            ? "cover-left-down ld-fourth  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 20
                                            ? "cover-left-down ld-fourth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 20
                                            ? "cover-left-down ld-fourth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 20
                                            ? "cover-left-down ld-fourth BleedingTwo"
                                            : "cover-left-down ld-fourth "
                                        }
                                        // class="cover-left-down ld-fourth"
                                        id="cover_20"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 21 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(21);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[21]) {
                                    var index21 =
                                      this.state.patientTeethId.indexOf(21);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index21
                                        ] &&
                                        this.state.patientDiceseRecord[index21]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index21
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index21
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_21
                                              // class="cover-left-down ld-fifth plaque"
                                              class={
                                                this.state.divactive === 21 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 21
                                                  ? "cover-left-down ld-fifth    plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 21
                                                  ? "cover-left-down ld-fifth    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 21
                                                  ? "cover-left-down ld-fifth    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 21
                                                  ? "cover-left-down ld-fifth    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 21
                                                  ? "cover-left-down ld-fifth    BleedingTwo"
                                                  : "cover-left-down ld-fifth    plaque"
                                              }
                                              id="cover_21"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index21
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_21
                                                // class="cover-left-down ld-fifth BleedingTwo"
                                                class={
                                                  this.state.divactive === 21 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 21
                                                    ? "cover-left-down ld-fifth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingTwo"
                                                    : "cover-left-down ld-fifth    BleedingTwo"
                                                }
                                                id="cover_21"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index21
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_21
                                                // class="cover-left-down ld-fifth Bleeding"
                                                class={
                                                  this.state.divactive === 21 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 21
                                                    ? "cover-left-down ld-fifth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingTwo"
                                                    : "cover-left-down ld-fifth    Bleeding"
                                                }
                                                id="cover_21"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index21
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_21
                                                // class="cover-left-down ld-fifth plaque"
                                                class={
                                                  this.state.divactive === 21 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 21
                                                    ? "cover-left-down ld-fifth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingTwo"
                                                    : "cover-left-down ld-fifth    plaque"
                                                }
                                                id="cover_21"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index21
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_21
                                                // class="cover-left-down ld-fifth Abscess"
                                                class={
                                                  this.state.divactive === 21 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 21
                                                    ? "cover-left-down ld-fifth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingTwo"
                                                    : "cover-left-down ld-fifth    Abscess"
                                                }
                                                id="cover_21"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index21
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_21
                                                // class="cover-left-down ld-fifth BleedingAbscess"
                                                class={
                                                  this.state.divactive === 21 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 21
                                                    ? "cover-left-down ld-fifth    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        21
                                                    ? "cover-left-down ld-fifth    BleedingTwo"
                                                    : "cover-left-down ld-fifth    BleedingAbscess"
                                                }
                                                id="cover_21"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_21
                                        // class={
                                        //   this.state.divactive === 21
                                        //     ? "cover-left-down ld-fifth BleedingTwo"
                                        //     : "cover-left-down ld-fifth"
                                        // }

                                        class={
                                          this.state.divactive === 21 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-left-down ld-fifth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 21
                                            ? "cover-left-down ld-fifth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 21
                                            ? "cover-left-down ld-fifth  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 21
                                            ? "cover-left-down ld-fifth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 21
                                            ? "cover-left-down ld-fifth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 21
                                            ? "cover-left-down ld-fifth BleedingTwo"
                                            : "cover-left-down ld-fifth "
                                        }
                                        // class="cover-left-down ld-fifth"
                                        id="cover_21"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <img
                                class="frame-right-down"
                                src="assets/images/TeethIcons/icn_tooth_left_down.png"
                              />
                              <div
                                class={
                                  this.state.divactive == 27 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(27);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[27]) {
                                    var index27 =
                                      this.state.patientTeethId.indexOf(27);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index27
                                        ] &&
                                        this.state.patientDiceseRecord[index27]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index27
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index27
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_27
                                              // class="cover-right-down rd-first plaque"
                                              class={
                                                this.state.divactive === 27 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 27
                                                  ? "cover-right-down rd-first    plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 27
                                                  ? "cover-right-down rd-first    Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 27
                                                  ? "cover-right-down rd-first    Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 27
                                                  ? "cover-right-down rd-first    BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 27
                                                  ? "cover-right-down rd-first    BleedingTwo"
                                                  : "cover-right-down rd-first    plaque"
                                              }
                                              id="cover_27"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index27
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_27
                                                // class="cover-right-down rd-first BleedingTwo"
                                                class={
                                                  this.state.divactive === 27 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 27
                                                    ? "cover-right-down rd-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingTwo"
                                                    : "cover-right-down rd-first    BleedingTwo"
                                                }
                                                id="cover_27"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index27
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_27
                                                // class="cover-right-down rd-first Bleeding"
                                                class={
                                                  this.state.divactive === 27 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 27
                                                    ? "cover-right-down rd-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingTwo"
                                                    : "cover-right-down rd-first    Bleeding"
                                                }
                                                id="cover_27"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index27
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_27
                                                // class="cover-right-down rd-first plaque"
                                                class={
                                                  this.state.divactive === 27 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 27
                                                    ? "cover-right-down rd-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingTwo"
                                                    : "cover-right-down rd-first    plaque"
                                                }
                                                id="cover_27"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index27
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_27
                                                // class="cover-right-down rd-first Abscess"
                                                class={
                                                  this.state.divactive === 27 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 27
                                                    ? "cover-right-down rd-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingTwo"
                                                    : "cover-right-down rd-first    Abscess"
                                                }
                                                id="cover_27"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index27
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_27
                                                // class="cover-right-down rd-first BleedingAbscess"
                                                class={
                                                  this.state.divactive === 27 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 27
                                                    ? "cover-right-down rd-first    plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        27
                                                    ? "cover-right-down rd-first    BleedingTwo"
                                                    : "cover-right-down rd-first    BleedingAbscess"
                                                }
                                                id="cover_27"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_27
                                        // class={
                                        //   this.state.divactive === 27
                                        //     ? "cover-right-down rd-first BleedingTwo"
                                        //     : "cover-right-down rd-first"
                                        // }

                                        class={
                                          this.state.divactive === 27 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-down rd-first BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 27
                                            ? "cover-right-down rd-first plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 27
                                            ? "cover-right-down rd-first  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 27
                                            ? "cover-right-down rd-first Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 27
                                            ? "cover-right-down rd-first BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 27
                                            ? "cover-right-down rd-first BleedingTwo"
                                            : "cover-right-down rd-first "
                                        }
                                        // class="cover-right-down rd-first"
                                        id="cover_27"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 28 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(28);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[28]) {
                                    var index28 =
                                      this.state.patientTeethId.indexOf(28);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index28
                                        ] &&
                                        this.state.patientDiceseRecord[index28]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index28
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index28
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_28
                                              // class="cover-right-down rd-second plaque"
                                              class={
                                                this.state.divactive === 28 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 28
                                                  ? "cover-right-down rd-second   plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 28
                                                  ? "cover-right-down rd-second   Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 28
                                                  ? "cover-right-down rd-second   Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 28
                                                  ? "cover-right-down rd-second   BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 28
                                                  ? "cover-right-down rd-second   BleedingTwo"
                                                  : "cover-right-down rd-second   plaque"
                                              }
                                              id="cover_28"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index28
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_28
                                                // class="cover-right-down rd-second BleedingTwo"
                                                class={
                                                  this.state.divactive === 28 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 28
                                                    ? "cover-right-down rd-second   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingTwo"
                                                    : "cover-right-down rd-second   BleedingTwo"
                                                }
                                                id="cover_28"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index28
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_28
                                                // class="cover-right-down rd-second Bleeding"
                                                class={
                                                  this.state.divactive === 28 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 28
                                                    ? "cover-right-down rd-second   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingTwo"
                                                    : "cover-right-down rd-second   Bleeding"
                                                }
                                                id="cover_28"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index28
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_28
                                                // class="cover-right-down rd-second plaque"
                                                class={
                                                  this.state.divactive === 28 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 28
                                                    ? "cover-right-down rd-second   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingTwo"
                                                    : "cover-right-down rd-second   plaque"
                                                }
                                                id="cover_28"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index28
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_28
                                                // class="cover-right-down rd-second Abscess"
                                                class={
                                                  this.state.divactive === 28 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 28
                                                    ? "cover-right-down rd-second   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingTwo"
                                                    : "cover-right-down rd-second   Abscess"
                                                }
                                                id="cover_28"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index28
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_28
                                                // class="cover-right-down rd-second BleedingAbscess"
                                                class={
                                                  this.state.divactive === 28 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 28
                                                    ? "cover-right-down rd-second   plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        28
                                                    ? "cover-right-down rd-second   BleedingTwo"
                                                    : "cover-right-down rd-second   BleedingAbscess"
                                                }
                                                id="cover_28"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_28
                                        //   class={
                                        //     this.state.divactive === 28
                                        //       ? "cover-right-down rd-second BleedingTwo"
                                        //       : "cover-right-down rd-second"
                                        //   }
                                        class={
                                          this.state.divactive === 28 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-down rd-second BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 28
                                            ? "cover-right-down rd-second plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 28
                                            ? "cover-right-down rd-second  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 28
                                            ? "cover-right-down rd-second Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 28
                                            ? "cover-right-down rd-second BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 28
                                            ? "cover-right-down rd-second BleedingTwo"
                                            : "cover-right-down rd-second "
                                        }
                                        // class="cover-right-down rd-second"
                                        id="cover_28"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 29 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(29);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[29]) {
                                    var index29 =
                                      this.state.patientTeethId.indexOf(29);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index29
                                        ] &&
                                        this.state.patientDiceseRecord[index29]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index29
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index29
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_29
                                              // class="cover-right-down rd-third plaque"

                                              class={
                                                this.state.divactive === 29 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 29
                                                  ? "cover-right-down rd-third  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 29
                                                  ? "cover-right-down rd-third  Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 29
                                                  ? "cover-right-down rd-third  Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 29
                                                  ? "cover-right-down rd-third  BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 29
                                                  ? "cover-right-down rd-third  BleedingTwo"
                                                  : "cover-right-down rd-third  plaque"
                                              }
                                              id="cover_29"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index29
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_29
                                                // class="cover-right-down rd-third BleedingTwo"

                                                class={
                                                  this.state.divactive === 29 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 29
                                                    ? "cover-right-down rd-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingTwo"
                                                    : "cover-right-down rd-third  BleedingTwo"
                                                }
                                                id="cover_29"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index29
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_29
                                                // class="cover-right-down rd-third Bleeding"
                                                class={
                                                  this.state.divactive === 29 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 29
                                                    ? "cover-right-down rd-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingTwo"
                                                    : "cover-right-down rd-third  Bleeding"
                                                }
                                                id="cover_29"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index29
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_29
                                                // class="cover-right-down rd-third plaque"

                                                class={
                                                  this.state.divactive === 29 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 29
                                                    ? "cover-right-down rd-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingTwo"
                                                    : "cover-right-down rd-third  plaque"
                                                }
                                                id="cover_29"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index29
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_29
                                                // class="cover-right-down rd-third Abscess"
                                                class={
                                                  this.state.divactive === 29 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 29
                                                    ? "cover-right-down rd-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingTwo"
                                                    : "cover-right-down rd-third  Abscess"
                                                }
                                                id="cover_29"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index29
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_29
                                                // class="cover-right-down rd-third BleedingAbscess"
                                                class={
                                                  this.state.divactive === 29 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 29
                                                    ? "cover-right-down rd-third  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        29
                                                    ? "cover-right-down rd-third  BleedingTwo"
                                                    : "cover-right-down rd-third  BleedingAbscess"
                                                }
                                                id="cover_29"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_29
                                        // class={
                                        //   this.state.divactive === 29
                                        //     ? "cover-right-down rd-third BleedingTwo"
                                        //     : "cover-right-down rd-third"
                                        // }

                                        class={
                                          this.state.divactive === 29 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-down rd-third BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 29
                                            ? "cover-right-down rd-third plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 29
                                            ? "cover-right-down rd-third  Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 29
                                            ? "cover-right-down rd-third Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 29
                                            ? "cover-right-down rd-third BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 29
                                            ? "cover-right-down rd-third BleedingTwo"
                                            : "cover-right-down rd-third "
                                        }
                                        // class="cover-right-down rd-third"
                                        id="cover_29"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 30 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(30);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[30]) {
                                    var index30 =
                                      this.state.patientTeethId.indexOf(30);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index30
                                        ] &&
                                        this.state.patientDiceseRecord[index30]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index30
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index30
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_30
                                              // class="cover-right-down rd-fourth plaque"

                                              class={
                                                this.state.divactive === 30 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 30
                                                  ? "cover-right-down rd-fourth  plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 30
                                                  ? "cover-right-down rd-fourth  Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 30
                                                  ? "cover-right-down rd-fourth  Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 30
                                                  ? "cover-right-down rd-fourth  BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 30
                                                  ? "cover-right-down rd-fourth  BleedingTwo"
                                                  : "cover-right-down rd-fourth  plaque"
                                              }
                                              id="cover_30"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index30
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_30
                                                // class="cover-right-down rd-fourth BleedingTwo"
                                                id="cover_30"
                                                class={
                                                  this.state.divactive === 30 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 30
                                                    ? "cover-right-down rd-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingTwo"
                                                    : "cover-right-down rd-fourth  BleedingTwo"
                                                }
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index30
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_30
                                                // class="cover-right-down rd-fourth Bleeding"
                                                class={
                                                  this.state.divactive === 30 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 30
                                                    ? "cover-right-down rd-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingTwo"
                                                    : "cover-right-down rd-fourth  Bleeding"
                                                }
                                                id="cover_30"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index30
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_30
                                                // class="cover-right-down rd-fourth plaque"

                                                class={
                                                  this.state.divactive === 30 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 30
                                                    ? "cover-right-down rd-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingTwo"
                                                    : "cover-right-down rd-fourth  plaque"
                                                }
                                                id="cover_30"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index30
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_30
                                                // class="cover-right-down rd-fourth Abscess"

                                                class={
                                                  this.state.divactive === 30 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 30
                                                    ? "cover-right-down rd-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingTwo"
                                                    : "cover-right-down rd-fourth  Abscess"
                                                }
                                                id="cover_30"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index30
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_30
                                                // class="cover-right-down rd-fourth BleedingAbscess"

                                                class={
                                                  this.state.divactive === 30 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 30
                                                    ? "cover-right-down rd-fourth  plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        30
                                                    ? "cover-right-down rd-fourth  BleedingTwo"
                                                    : "cover-right-down rd-fourth  BleedingAbscess"
                                                }
                                                id="cover_30"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_30
                                        // class={
                                        //   this.state.divactive === 30
                                        //     ? "cover-right-down rd-fourth BleedingTwo"
                                        //     : "cover-right-down rd-fourth"
                                        // }
                                        class={
                                          this.state.divactive === 30 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-down rd-fourth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 30
                                            ? "cover-right-down rd-fourth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 30
                                            ? "cover-right-down rd-fourth Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 30
                                            ? "cover-right-down rd-fourth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 30
                                            ? "cover-right-down rd-fourth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 30
                                            ? "cover-right-down rd-fourth BleedingTwo"
                                            : "cover-right-down rd-fourth "
                                        }
                                        // class="cover-right-down rd-fourth"
                                        id="cover_30"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 31 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(31);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[31]) {
                                    var index31 =
                                      this.state.patientTeethId.indexOf(31);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index31
                                        ] &&
                                        this.state.patientDiceseRecord[index31]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index31
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index31
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_31
                                              // class="cover-right-down rd-fifth plaque"

                                              class={
                                                this.state.divactive === 31 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 31
                                                  ? "cover-right-down rd-fifth plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 31
                                                  ? "cover-right-down rd-fifth Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 31
                                                  ? "cover-right-down rd-fifth Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 31
                                                  ? "cover-right-down rd-fifth BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 31
                                                  ? "cover-right-down rd-fifth BleedingTwo"
                                                  : "cover-right-down rd-fifth plaque"
                                              }
                                              id="cover_31"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index31
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_31
                                                // class="cover-right-down rd-fifth BleedingTwo"

                                                class={
                                                  this.state.divactive === 31 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 31
                                                    ? "cover-right-down rd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingTwo"
                                                    : "cover-right-down rd-fifth BleedingTwo"
                                                }
                                                id="cover_31"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index31
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_31
                                                // class="cover-right-down rd-fifth Bleeding"

                                                class={
                                                  this.state.divactive === 31 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 31
                                                    ? "cover-right-down rd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingTwo"
                                                    : "cover-right-down rd-fifth Bleeding"
                                                }
                                                id="cover_31"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index31
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_31
                                                // class="cover-right-down rd-fifth plaque"

                                                class={
                                                  this.state.divactive === 31 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 31
                                                    ? "cover-right-down rd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingTwo"
                                                    : "cover-right-down rd-fifth plaque"
                                                }
                                                id="cover_31"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index31
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_31
                                                // class="cover-right-down rd-fifth Abscess"

                                                class={
                                                  this.state.divactive === 31 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 31
                                                    ? "cover-right-down rd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingTwo"
                                                    : "cover-right-down rd-fifth Abscess"
                                                }
                                                id="cover_31"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index31
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_31
                                                // class="cover-right-down rd-fifth BleedingAbscess"

                                                class={
                                                  this.state.divactive === 31 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 31
                                                    ? "cover-right-down rd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        31
                                                    ? "cover-right-down rd-fifth BleedingTwo"
                                                    : "cover-right-down rd-fifth BleedingAbscess"
                                                }
                                                id="cover_31"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_31
                                        // class={
                                        //   this.state.divactive === 31
                                        //     ? "cover-right-down rd-fifth BleedingTwo"
                                        //     : "cover-right-down rd-fifth"
                                        // }

                                        class={
                                          this.state.divactive === 31 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-right-down rd-fifth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 31
                                            ? "cover-right-down rd-fifth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 31
                                            ? "cover-right-down rd-fifth Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 31
                                            ? "cover-right-down rd-fifth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 31
                                            ? "cover-right-down rd-fifth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 31
                                            ? "cover-right-down rd-fifth BleedingTwo"
                                            : "cover-right-down rd-fifth "
                                        }
                                        // class="cover-right-down rd-fifth"
                                        id="cover_31"
                                      />
                                    );
                                  }
                                })()}
                              </div>

                              <img
                                class="frame-down"
                                src="assets/images/TeethIcons/icn_tooth_down.png"
                              />
                              <div
                                class={
                                  this.state.divactive == 6 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(6);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[6]) {
                                    var index6 =
                                      this.state.patientTeethId.indexOf(6);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index6
                                        ] &&
                                        this.state.patientDiceseRecord[index6]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index6
                                          ] &&
                                          this.state.patientDiceseRecord[index6]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_6
                                              // class="cover-down cd-first plaque"

                                              class={
                                                this.state.divactive === 6 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 6
                                                  ? "cover-down cd-first plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 6
                                                  ? "cover-down cd-first Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 6
                                                  ? "cover-down cd-first Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 6
                                                  ? "cover-down cd-first BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 6
                                                  ? "cover-down cd-first BleedingTwo"
                                                  : "cover-down cd-first plaque"
                                              }
                                              id="cover_6"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index6
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_6
                                                // class="cover-down cd-first BleedingTwo"
                                                class={
                                                  this.state.divactive === 6 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 6
                                                    ? "cover-down cd-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingTwo"
                                                    : "cover-down cd-first BleedingTwo"
                                                }
                                                id="cover_6"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index6
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_6
                                                // class="cover-down cd-first Bleeding"

                                                class={
                                                  this.state.divactive === 6 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 6
                                                    ? "cover-down cd-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingTwo"
                                                    : "cover-down cd-first Bleeding"
                                                }
                                                id="cover_6"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index6
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_6
                                                // class="cover-down cd-first plaque"

                                                class={
                                                  this.state.divactive === 6 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 6
                                                    ? "cover-down cd-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingTwo"
                                                    : "cover-down cd-first plaque"
                                                }
                                                id="cover_6"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index6
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_6
                                                // class="cover-down cd-first Abscess"

                                                class={
                                                  this.state.divactive === 6 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 6
                                                    ? "cover-down cd-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingTwo"
                                                    : "cover-down cd-first Abscess"
                                                }
                                                id="cover_6"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index6
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_6
                                                // class="cover-down cd-first BleedingAbscess"

                                                class={
                                                  this.state.divactive === 6 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 6
                                                    ? "cover-down cd-first plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 6
                                                    ? "cover-down cd-first BleedingTwo"
                                                    : "cover-down cd-first BleedingAbscess"
                                                }
                                                id="cover_6"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_6
                                        // class={
                                        //   this.state.divactive === 6
                                        //     ? "cover-down cd-first BleedingTwo"
                                        //     : "cover-down cd-first"
                                        // }
                                        class={
                                          this.state.divactive === 6 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-down cd-first BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 6
                                            ? "cover-down cd-first plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 6
                                            ? "cover-down cd-first Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 6
                                            ? "cover-down cd-first Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 6
                                            ? "cover-down cd-first BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 6
                                            ? "cover-down cd-first BleedingTwo"
                                            : "cover-down cd-first "
                                        }
                                        // class="cover-down cd-first"
                                        id="cover_6"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 7 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(7);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[7]) {
                                    var index7 =
                                      this.state.patientTeethId.indexOf(7);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index7
                                        ] &&
                                        this.state.patientDiceseRecord[index7]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index7
                                          ] &&
                                          this.state.patientDiceseRecord[index7]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_7
                                              // class="cover-down cd-second plaque"

                                              class={
                                                this.state.divactive === 7 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 7
                                                  ? "cover-down cd-second plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 7
                                                  ? "cover-down cd-second Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 7
                                                  ? "cover-down cd-second Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 7
                                                  ? "cover-down cd-second BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 7
                                                  ? "cover-down cd-second BleedingTwo"
                                                  : "cover-down cd-second plaque"
                                              }
                                              id="cover_7"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index7
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_7
                                                // class="cover-down cd-second BleedingTwo"

                                                class={
                                                  this.state.divactive === 7 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 7
                                                    ? "cover-down cd-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingTwo"
                                                    : "cover-down cd-second BleedingTwo"
                                                }
                                                id="cover_7"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index7
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_7
                                                // class="cover-down cd-second Bleeding"
                                                class={
                                                  this.state.divactive === 7 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 7
                                                    ? "cover-down cd-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingTwo"
                                                    : "cover-down cd-second Bleeding"
                                                }
                                                id="cover_7"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index7
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_7
                                                // class="cover-down cd-second plaque"
                                                class={
                                                  this.state.divactive === 7 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 7
                                                    ? "cover-down cd-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingTwo"
                                                    : "cover-down cd-second plaque"
                                                }
                                                id="cover_7"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index7
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_7
                                                // class="cover-down cd-second Abscess"

                                                class={
                                                  this.state.divactive === 7 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 7
                                                    ? "cover-down cd-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingTwo"
                                                    : "cover-down cd-second Abscess"
                                                }
                                                id="cover_7"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index7
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_7
                                                // class="cover-down cd-second BleedingAbscess"

                                                class={
                                                  this.state.divactive === 7 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 7
                                                    ? "cover-down cd-second plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 7
                                                    ? "cover-down cd-second BleedingTwo"
                                                    : "cover-down cd-second BleedingAbscess"
                                                }
                                                id="cover_7"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_7
                                        // class={
                                        //   this.state.divactive === 7
                                        //     ? "cover-down cd-second BleedingTwo"
                                        //     : "cover-down cd-second"
                                        // }
                                        class={
                                          this.state.divactive === 7 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-down cd-second BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 7
                                            ? "cover-down cd-second plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 7
                                            ? "cover-down cd-second Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 7
                                            ? "cover-down cd-second Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 7
                                            ? "cover-down cd-second BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 7
                                            ? "cover-down cd-second BleedingTwo"
                                            : "cover-down cd-second "
                                        }
                                        // class="cover-down cd-second"
                                        id="cover_7"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 8 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(8);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[8]) {
                                    var index8 =
                                      this.state.patientTeethId.indexOf(8);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index8
                                        ] &&
                                        this.state.patientDiceseRecord[index8]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index8
                                          ] &&
                                          this.state.patientDiceseRecord[index8]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_8
                                              // class="cover-down cd-third plaque"
                                              class={
                                                this.state.divactive === 8 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 8
                                                  ? "cover-down cd-third plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 8
                                                  ? "cover-down cd-third Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 8
                                                  ? "cover-down cd-third Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 8
                                                  ? "cover-down cd-third BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 8
                                                  ? "cover-down cd-third BleedingTwo"
                                                  : "cover-down cd-third plaque"
                                              }
                                              id="cover_8"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index8
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_8
                                                // class="cover-down cd-third BleedingTwo"
                                                class={
                                                  this.state.divactive === 8 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 8
                                                    ? "cover-down cd-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingTwo"
                                                    : "cover-down cd-third BleedingTwo"
                                                }
                                                id="cover_8"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index8
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_8
                                                // class="cover-down cd-third Bleeding"
                                                class={
                                                  this.state.divactive === 8 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 8
                                                    ? "cover-down cd-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingTwo"
                                                    : "cover-down cd-third Bleeding"
                                                }
                                                id="cover_8"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index8
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_8
                                                // class="cover-down cd-third plaque"
                                                class={
                                                  this.state.divactive === 8 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 8
                                                    ? "cover-down cd-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingTwo"
                                                    : "cover-down cd-third plaque"
                                                }
                                                id="cover_8"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index8
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_8
                                                // class="cover-down cd-third Abscess"
                                                class={
                                                  this.state.divactive === 8 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 8
                                                    ? "cover-down cd-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingTwo"
                                                    : "cover-down cd-third Abscess"
                                                }
                                                id="cover_8"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index8
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_8
                                                // class="cover-down cd-third BleedingAbscess"

                                                class={
                                                  this.state.divactive === 8 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 8
                                                    ? "cover-down cd-third plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 8
                                                    ? "cover-down cd-third BleedingTwo"
                                                    : "cover-down cd-third BleedingAbscess"
                                                }
                                                id="cover_8"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_8
                                        // class="cover-down cd-third"

                                        class={
                                          this.state.divactive === 8 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-down cd-third BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 8
                                            ? "cover-down cd-third plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 8
                                            ? "cover-down cd-third Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 8
                                            ? "cover-down cd-third Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 8
                                            ? "cover-down cd-third BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 8
                                            ? "cover-down cd-third BleedingTwo"
                                            : "cover-down cd-third "
                                        }
                                        id="cover_8"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 9 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(9);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[9]) {
                                    var index9 =
                                      this.state.patientTeethId.indexOf(9);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index9
                                        ] &&
                                        this.state.patientDiceseRecord[index9]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index9
                                          ] &&
                                          this.state.patientDiceseRecord[index9]
                                            .is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_9
                                              // class="cover-down cd-fourth plaque"
                                              class={
                                                this.state.divactive === 9 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 9
                                                  ? "cover-down cd-fourth plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 9
                                                  ? "cover-down cd-fourth Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 9
                                                  ? "cover-down cd-fourth Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 9
                                                  ? "cover-down cd-fourth BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 9
                                                  ? "cover-down cd-fourth BleedingTwo"
                                                  : "cover-down cd-fourth plaque"
                                              }
                                              id="cover_9"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index9
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_9
                                                // class="cover-down cd-fourth BleedingTwo"

                                                class={
                                                  this.state.divactive === 9 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 9
                                                    ? "cover-down cd-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingTwo"
                                                    : "cover-down cd-fourth BleedingTwo"
                                                }
                                                id="cover_9"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index9
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_9
                                                // class="cover-down cd-fourth Bleeding"
                                                class={
                                                  this.state.divactive === 9 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 9
                                                    ? "cover-down cd-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingTwo"
                                                    : "cover-down cd-fourth Bleeding"
                                                }
                                                id="cover_9"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index9
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_9
                                                // class="cover-down cd-fourth plaque"
                                                class={
                                                  this.state.divactive === 9 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 9
                                                    ? "cover-down cd-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingTwo"
                                                    : "cover-down cd-fourth plaque"
                                                }
                                                id="cover_9"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index9
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_9
                                                // class="cover-down cd-fourth Abscess"

                                                class={
                                                  this.state.divactive === 9 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 9
                                                    ? "cover-down cd-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingTwo"
                                                    : "cover-down cd-fourth Abscess"
                                                }
                                                id="cover_9"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index9
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_9
                                                // class="cover-down cd-fourth BleedingAbscess"

                                                class={
                                                  this.state.divactive === 9 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 9
                                                    ? "cover-down cd-fourth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive === 9
                                                    ? "cover-down cd-fourth BleedingTwo"
                                                    : "cover-down cd-fourth BleedingAbscess "
                                                }
                                                id="cover_9"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_9
                                        // class={
                                        //   this.state.divactive === 9
                                        //     ? "cover-down cd-fourth BleedingTwo"
                                        //     : "cover-down cd-fourth"
                                        // }
                                        class={
                                          this.state.divactive === 9 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-down cd-fourth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 9
                                            ? "cover-down cd-fourth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 9
                                            ? "cover-down cd-fourth Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 9
                                            ? "cover-down cd-fourth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 9
                                            ? "cover-down cd-fourth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 9
                                            ? "cover-down cd-fourth BleedingTwo"
                                            : "cover-down cd-fourth "
                                        }
                                        // class="cover-down cd-fourth"
                                        id="cover_9"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 10 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(10);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[10]) {
                                    var index10 =
                                      this.state.patientTeethId.indexOf(10);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index10
                                        ] &&
                                        this.state.patientDiceseRecord[index10]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index10
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index10
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_10
                                              // class="cover-down cd-fifth plaque"

                                              class={
                                                this.state.divactive === 10 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 10
                                                  ? "cover-down cd-fifth plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 10
                                                  ? "cover-down cd-fifth Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 10
                                                  ? "cover-down cd-fifth Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 10
                                                  ? "cover-down cd-fifth BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 10
                                                  ? "cover-down cd-fifth BleedingTwo"
                                                  : "cover-down cd-fifth plaque"
                                              }
                                              id="cover_10"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index10
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_10
                                                // class="cover-down cd-fifth BleedingTwo"
                                                class={
                                                  this.state.divactive === 10 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 10
                                                    ? "cover-down cd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingTwo"
                                                    : "cover-down cd-fifth BleedingTwo "
                                                }
                                                id="cover_10"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index10
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <ReactLogo_10
                                                // class="cover-down cd-fifth Bleeding"

                                                class={
                                                  this.state.divactive === 10 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 10
                                                    ? "cover-down cd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingTwo"
                                                    : "cover-down cd-fifth Bleeding "
                                                }
                                                id="cover_10"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index10
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_10
                                                // class="cover-down cd-fifth plaque"

                                                class={
                                                  this.state.divactive === 10 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 10
                                                    ? "cover-down cd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingTwo"
                                                    : "cover-down cd-fifth plaque "
                                                }
                                                id="cover_10"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index10
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_10
                                                // class="cover-down cd-fifth Abscess"

                                                class={
                                                  this.state.divactive === 10 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 10
                                                    ? "cover-down cd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingTwo"
                                                    : "cover-down cd-fifth Abscess "
                                                }
                                                id="cover_10"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index10
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_10
                                                // class="cover-down cd-fifth BleedingAbscess"

                                                class={
                                                  this.state.divactive === 10 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 10
                                                    ? "cover-down cd-fifth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        10
                                                    ? "cover-down cd-fifth BleedingTwo"
                                                    : "cover-down cd-fifth BleedingAbscess "
                                                }
                                                id="cover_10"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <ReactLogo_10
                                        class={
                                          this.state.divactive === 10 &&
                                          this.state.toothCondition !==
                                            "bleeding" &&
                                          this.state.toothCondition !==
                                            "abscess" &&
                                          this.state.toothCondition !==
                                            "bleeding+abscess" &&
                                          !this.state.isPlaqueSwitch
                                            ? "cover-down cd-fifth BleedingTwo"
                                            : this.state.isPlaqueSwitch &&
                                              this.state.divactive === 10
                                            ? "cover-down cd-fifth plaque"
                                            : this.state.toothCondition ===
                                                "bleeding" &&
                                              this.state.divactive === 10
                                            ? "cover-down cd-fifth Bleeding"
                                            : this.state.toothCondition ===
                                                "abscess" &&
                                              this.state.divactive === 10
                                            ? "cover-down cd-fifth Abscess"
                                            : this.state.toothCondition ===
                                                "bleeding+abscess" &&
                                              this.state.divactive === 10
                                            ? "cover-down cd-fifth BleedingAbscess"
                                            : this.state.toothCondition ===
                                                "healthy" &&
                                              this.state.divactive === 10
                                            ? "cover-down cd-fifth BleedingTwo"
                                            : "cover-down cd-fifth "
                                        }
                                        // class="cover-down cd-fifth"
                                        id="cover_10"
                                      />
                                    );
                                  }
                                })()}
                              </div>
                              <div
                                class={
                                  this.state.divactive == 11 ? "active" : ""
                                }
                                onClick={() => {
                                  this.getTeethDetails(11);
                                }}
                              >
                                {(() => {
                                  if (this.state.patientIndex[11]) {
                                    var index11 =
                                      this.state.patientTeethId.indexOf(11);

                                    if (
                                      this.state.patientDiceseRecord &&
                                      this.state.patientDiceseRecord.length > 0
                                    ) {
                                      if (
                                        this.state.patientDiceseRecord[
                                          index11
                                        ] &&
                                        this.state.patientDiceseRecord[index11]
                                          .periodontal_type !== undefined
                                      ) {
                                        if (
                                          this.state.patientDiceseRecord[
                                            index11
                                          ] &&
                                          this.state.patientDiceseRecord[
                                            index11
                                          ].is_plaque == 1
                                        ) {
                                          return (
                                            <ReactLogo_11
                                              // class="cover-down cd-sixth plaque"
                                              class={
                                                this.state.divactive === 11 &&
                                                this.state.isPlaqueSwitchEdit &&
                                                this.state.divactive === 11
                                                  ? "cover-down cd-sixth plaque"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding" &&
                                                    this.state.divactive === 11
                                                  ? "cover-down cd-sixth Bleeding"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "abscess" &&
                                                    this.state.divactive === 11
                                                  ? "cover-down cd-sixth Abscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "bleeding+abscess" &&
                                                    this.state.divactive === 11
                                                  ? "cover-down cd-sixth BleedingAbscess"
                                                  : this.state
                                                      .toothConditionEdit ===
                                                      "healthy" &&
                                                    this.state.divactive === 11
                                                  ? "cover-down cd-sixth BleedingTwo"
                                                  : "cover-down cd-sixth plaque "
                                              }
                                              id="cover_11"
                                            />
                                          );
                                        } else {
                                          if (
                                            this.state.patientDiceseRecord[
                                              index11
                                            ].periodontal_type == "healthy"
                                          ) {
                                            return (
                                              <ReactLogo_11
                                                class={
                                                  this.state.divactive === 11 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 11
                                                    ? "cover-down cd-sixth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingTwo"
                                                    : "cover-down cd-sixth BleedingTwo "
                                                }
                                                // class="cover-down cd-sixth BleedingTwo"
                                                id="cover_11"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index11
                                            ].periodontal_type == "bleeding"
                                          ) {
                                            return (
                                              <>
                                                <ReactLogo_11
                                                  // class="cover-down cd-sixth
                                                  class={
                                                    this.state.divactive ===
                                                      11 &&
                                                    this.state
                                                      .isPlaqueSwitchEdit &&
                                                    this.state.divactive === 11
                                                      ? "cover-down cd-sixth plaque"
                                                      : this.state
                                                          .toothConditionEdit ===
                                                          "bleeding" &&
                                                        this.state.divactive ===
                                                          11
                                                      ? "cover-down cd-sixth Bleeding"
                                                      : this.state
                                                          .toothConditionEdit ===
                                                          "abscess" &&
                                                        this.state.divactive ===
                                                          11
                                                      ? "cover-down cd-sixth Abscess"
                                                      : this.state
                                                          .toothConditionEdit ===
                                                          "bleeding+abscess" &&
                                                        this.state.divactive ===
                                                          11
                                                      ? "cover-down cd-sixth BleedingAbscess"
                                                      : this.state
                                                          .toothConditionEdit ===
                                                          "healthy" &&
                                                        this.state.divactive ===
                                                          11
                                                      ? "cover-down cd-sixth BleedingTwo"
                                                      : "cover-down cd-sixth Bleeding "
                                                  }
                                                  id="cover_11"
                                                />
                                              </>
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index11
                                            ].periodontal_type == "plaque"
                                          ) {
                                            return (
                                              <ReactLogo_11
                                                // class="cover-down cd-sixth plaque"
                                                class={
                                                  this.state.divactive === 11 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 11
                                                    ? "cover-down cd-sixth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingTwo"
                                                    : "cover-down cd-sixth plaque "
                                                }
                                                id="cover_11"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index11
                                            ].periodontal_type == "abscess"
                                          ) {
                                            return (
                                              <ReactLogo_11
                                                // class="cover-down cd-sixth Abscess"
                                                class={
                                                  this.state.divactive === 11 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 11
                                                    ? "cover-down cd-sixth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingTwo"
                                                    : "cover-down cd-sixth Abscess "
                                                }
                                                id="cover_11"
                                              />
                                            );
                                          } else if (
                                            this.state.patientDiceseRecord[
                                              index11
                                            ].periodontal_type ==
                                            "bleeding+abscess"
                                          ) {
                                            return (
                                              <ReactLogo_11
                                                class={
                                                  this.state.divactive === 11 &&
                                                  this.state
                                                    .isPlaqueSwitchEdit &&
                                                  this.state.divactive === 11
                                                    ? "cover-down cd-sixth plaque"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Bleeding"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth Abscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "bleeding+abscess" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingAbscess"
                                                    : this.state
                                                        .toothConditionEdit ===
                                                        "healthy" &&
                                                      this.state.divactive ===
                                                        11
                                                    ? "cover-down cd-sixth BleedingTwo"
                                                    : "cover-down cd-sixth BleedingAbscess "
                                                }
                                                // class="cover-down cd-sixth BleedingAbscess"
                                                id="cover_11"
                                              />
                                            );
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    return (
                                      <div>
                                        <ReactLogo_11
                                          class={
                                            this.state.divactive === 11 &&
                                            this.state.toothCondition !==
                                              "bleeding" &&
                                            this.state.toothCondition !==
                                              "abscess" &&
                                            this.state.toothCondition !==
                                              "bleeding+abscess" &&
                                            !this.state.isPlaqueSwitch
                                              ? "cover-down cd-sixth BleedingTwo"
                                              : this.state.isPlaqueSwitch &&
                                                this.state.divactive === 11
                                              ? "cover-down cd-sixth plaque"
                                              : this.state.toothCondition ===
                                                  "bleeding" &&
                                                this.state.divactive === 11
                                              ? "cover-down cd-sixth Bleeding"
                                              : this.state.toothCondition ===
                                                  "abscess" &&
                                                this.state.divactive === 11
                                              ? "cover-down cd-sixth Abscess"
                                              : this.state.toothCondition ===
                                                  "bleeding+abscess" &&
                                                this.state.divactive === 11
                                              ? "cover-down cd-sixth BleedingAbscess"
                                              : this.state.toothCondition ===
                                                  "healthy" &&
                                                this.state.divactive === 11
                                              ? "cover-down cd-sixth BleedingTwo"
                                              : "cover-down cd-sixth "
                                          }
                                          // class="cover-down cd-sixth"
                                          id="cover_11"
                                        />
                                      </div>
                                    );
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                        );
                      })()}
                    </div>
                  }

                  <div class="disease-details">
                    {this.state.detailstatus ? (
                      <div class="detailed-form">
                        <div class="form-contents">
                          <h4>Plaque?</h4>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Switch
                              checked={this.state?.isPlaqueSwitchEdit}
                              onChange={this.handlePlaqueSwitchEdit}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <Typography>
                              {this.state.isPlaqueSwitchEdit ? "Yes" : "No"}
                            </Typography>
                          </Stack>
                        </div>
                        <div class="form-contents">
                          <h4>Tooth Mobility</h4>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            value={this.state.toothMobilityNumberEdit}
                            onChange={this.handleToothMobilityEdit}
                            inputProps={{ min: 0, max: 3 }}
                            size="small"
                          />
                        </div>
                        <div class="form-contents">
                          <h4>Periodontal Condition</h4>
                          <FormControl fullWidth size="small">
                            <TextField
                              value={this.state.toothConditionEdit}
                              select
                              onChange={this.handleToothConditionEdit}
                              InputLabelProps={{ shrink: false }}
                              size="small"
                            >
                              <MenuItem value={`healthy`}>Healthy</MenuItem>
                              <MenuItem value={`bleeding`}>Bleeding</MenuItem>
                              <MenuItem value={`abscess`}>Abscess</MenuItem>
                              <MenuItem value={`bleeding+abscess`}>
                                Bleeding+Abscess
                              </MenuItem>
                            </TextField>
                          </FormControl>
                        </div>
                        <div class="form-contents">
                          <h4>Pocket Depth</h4>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            value={this.state.pocketDepthValueEdit}
                            onChange={this.handlePocketDepthEdit}
                            size="small"
                          />
                        </div>
                        <div class="form-contents">
                          <h4>Note</h4>
                          <div class="form-group">
                            <textarea
                              type="text"
                              class="form-control note-textarea"
                              rows="2"
                              value={this.state.noteEdit}
                              id=""
                              onChange={(e) => {
                                this.setState({ noteEdit: e.target.value });
                              }}
                              name="notes"
                            />
                          </div>
                        </div>
                        <div class="form-contents custom-checkbox">
                          <label class="c-container">
                            Disable Editing
                            {/* {this.state.note_disable == 0 ? <input type="checkbox" onChange={this.handleCheck} defaultChecked={false} checked={false}/> : <input type="checkbox" onChange={this.handleCheck} defaultChecked={false} checked={true}/>} */}
                            {this.state.note_disable == 0 ? (
                              <input
                                type="checkbox"
                                onChange={(e) => this.handleCheck(e)}
                                defaultChecked={false}
                                checked={false}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onChange={(e) => this.handleCheck(e)}
                                defaultChecked={false}
                                checked={true}
                              />
                            )}
                            <span class="c-checkmark"></span>
                          </label>
                        </div>

                        <div class="form-contents">
                          <button
                            type="submit"
                            class="btn btn-primary btn-update"
                            onClick={() => {
                              this.updateRecord();
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    ) : this.state.addDataForHealthyTeeth ? (
                      <div class="disease-details">
                        <div class="detailed-form">
                          <div class="form-contents">
                            <h4>Plaque?</h4>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Switch
                                checked={this.state.isPlaqueSwitch}
                                onChange={this.handlePlaqueSwitch}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <Typography>
                                {this.state.isPlaqueSwitch ? "Yes" : "No"}
                              </Typography>
                            </Stack>
                          </div>
                          <div class="form-contents">
                            <h4>Tooth Mobility</h4>
                            <TextField
                              id="outlined-basic"
                              type="number"
                              variant="outlined"
                              value={this.state.toothMobilityNumber}
                              onChange={this.handleToothMobility}
                              inputProps={{ min: 0, max: 3 }}
                              size="small"
                            />
                          </div>
                          <div class="form-contents">
                            <FormControl fullWidth size="small">
                              <TextField
                                value={this.state.toothCondition}
                                select
                                onChange={this.handleToothCondition}
                                InputLabelProps={{ shrink: false }}
                                size="small"
                              >
                                <MenuItem value={`healthy`}>Healthy</MenuItem>
                                <MenuItem value={`bleeding`}>Bleeding</MenuItem>
                                <MenuItem value={`abscess`}>Abscess</MenuItem>
                                <MenuItem value={`bleeding+abscess`}>
                                  Bleeding+Abscess
                                </MenuItem>
                              </TextField>
                            </FormControl>
                          </div>
                          <div class="form-contents">
                            <h4>Pocket Depth</h4>
                            <TextField
                              id="outlined-basic"
                              type="number"
                              variant="outlined"
                              value={this.state.pocketDepthValue}
                              onChange={this.handlePocketDepth}
                              size="small"
                            />
                          </div>
                          <div class="form-contents">
                            <h4>Note</h4>
                            <div class="form-group">
                              <textarea
                                type="text"
                                class="form-control note-textarea"
                                rows="2"
                                value={this.state.addNote}
                                id=""
                                onChange={(e) => {
                                  this.setState({ addNote: e.target.value });
                                }}
                                name="notes"
                              />
                            </div>
                          </div>
                          <div class="form-contents custom-checkbox">
                            <label class="c-container">
                              Disable Editing
                              {/* {this.state.note_disable == 0 ? <input type="checkbox" onChange={this.handleCheck} defaultChecked={false} checked={false}/> : <input type="checkbox" onChange={this.handleCheck} defaultChecked={false} checked={true}/>} */}
                              <input
                                type="checkbox"
                                onChange={this.handleDisableCheck}
                                defaultChecked={false}
                                checked={this.state.disableNote}
                              />
                              <span class="c-checkmark"></span>
                            </label>
                          </div>

                          <div class="form-contents">
                            <button
                              type="submit"
                              class="btn btn-primary btn-update"
                              onClick={() => {
                                this.AddRecord();
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div class="dummy-content">
                        <p>Select a tooth to view the records</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal modal-center Disease-record-modal"
          id="brushing_modal"
        >
          <div class="modal-dialog modal-md-feedback">
            <div class="modal-content" style={{ marginTop: "130px" }}>
              <div class="modal-header">
                <div class="d-flex">
                  <h4 class="modal-title">Brushing Overview</h4>
                  <div class="form-group">
                    {
                      <select
                        class="form-control custom-select"
                        value={this.state.targetkidid}
                        onChange={(e) => {
                          this.selectkidfromdropdown(e);
                        }}
                      >
                        <option value="">
                          {this.state.patientDetails.name}
                        </option>
                        {this.state.patientKidRecords.map(function (item) {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    }
                  </div>
                </div>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  onClick={() => {
                    this.updateStates();
                  }}
                >
                  <img src="assets/images/modal-close.svg" alt="" />
                </button>
              </div>

              <div class="modal-body pt-0">
                <div class="d-flex disease-modal-wrap">
                  <div class="modal-callender-wrapper1">
                    <p class="date-range">Please pick the date range</p>
                    <div class="calendar">
                      <div className="Sample" style={{ marginTop: 10 }}>
                        <div className="Sample__container">
                          <main className="Sample__container__content">
                            <DateRangePicker
                              startDate={this.state.startDate}
                              enddate={this.state.endDatess}
                              onSelect={this.onSelect}
                              numberOfCalendars={2}
                              selectionType="range"
                              stateDefinitions={stateDefinitions}
                              dateStates={dateRanges}
                              defaultState="available"
                              showLegend={false}
                              value={this.state.daterange}
                              dateFormat={"yyyy/MM/dd"}
                              focusedInput={this.state.focusedInput}
                              onFocusChange={this.handleFocusChange}
                              date={new Date()}
                              selected={new Date()}
                            />
                          </main>
                        </div>
                      </div>
                    </div>

                    {this.state.showingfromdate === "" ? (
                      ""
                    ) : (
                      <div class="date-range-div">
                        <p class="head">Showing Result from</p>
                        <span class="date">
                          {this.state.displyfromdate} to{" "}
                          {this.state.displaytodate}
                        </span>
                      </div>
                    )}
                  </div>

                  {(() => {
                    if (this.state.brushOverviewRecords == "") {
                      return (
                        <div class="teeth-container">
                          <div class="container-d ">
                            {/* <div class="container teethInner"> */}
                            <img
                              class="frame-left-up"
                              src="assets/images/icn_tooth_left_up.png"
                            />
                            <img
                              class="cover-left-up-inner ten"
                              src="assets/images/icn_tooth_left_up_inner_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-left-up-outer eight"
                              src="assets/images/icn_tooth_left_up_outer_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-left-up-surf nine"
                              src="assets/images/icn_tooth_left_up_top_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="frame-right-up"
                              src="assets/images/icn_tooth_left_up.png"
                            />
                            <img
                              class="cover-right-up-inner sixteen"
                              src="assets/images/icn_tooth_left_up_inner_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-right-up-outer fourteen"
                              src="assets/images/icn_tooth_left_up_outer_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-right-up-surf fifteen"
                              src="assets/images/icn_tooth_left_up_top_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="frame-up"
                              src="assets/images/icn_tooth_up.png"
                            />
                            <img
                              class="cover-up-inner four"
                              src="assets/images/icn_tooth_up_inner_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-up-outer three"
                              src="assets/images/icn_tooth_up_outer_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="frame-left-down "
                              src="assets/images/icn_tooth_left_down.png"
                            />
                            <img
                              class="cover-left-down-inner seven"
                              src="assets/images/icn_tooth_left_down_inner_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-left-down-outer five"
                              src="assets/images/icn_tooth_left_down_outer_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-left-down-surf six"
                              src="assets/images/icn_tooth_left_down_top_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="frame-right-down"
                              src="assets/images/icn_tooth_left_down.png"
                            />
                            <img
                              class="cover-right-down-inner thirteen"
                              src="assets/images/icn_tooth_left_down_inner_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-right-down-outer eleven"
                              src="assets/images/icn_tooth_left_down_outer_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-right-down-surf twelve"
                              src="assets/images/icn_tooth_left_down_top_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="frame-down"
                              src="assets/images/icn_tooth_down.png"
                            />
                            <img
                              class="cover-down-inner two"
                              src="assets/images/icn_tooth_down_inner_cover.png"
                              style={{ opacity: 0 }}
                            />
                            <img
                              class="cover-down-outer one "
                              src="assets/images/icn_tooth_down_outer_cover.png"
                              style={{ opacity: 0 }}
                            />
                          </div>
                          <div>
                            <div class="gradientGenerator">
                              <div class="gradientPanel"></div>
                              <div class="coverageNone">
                                <span class="arrow arrow-up"></span>
                                <p class="mb-0">0% Coverage</p>
                              </div>
                              <div class="coverageFull">
                                <span class="arrow arrow-up"></span>
                                <p class="mb-0">100% Coverage</p>
                              </div>
                            </div>

                            {/* </div> */}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div class="teeth-container">
                          <div class="container-d ">
                            <div class="container teethInner">
                              <img
                                class="frame-left-up"
                                src="assets/images/icn_tooth_left_up.png"
                              />
                              <ToastContainer />
                              {this.state.brushing_percentages[10] == 1 ||
                              this.state.brushing_percentages[10] == 0 ? (
                                <img
                                  class="cover-left-up-inner ten"
                                  src="assets/images/icn_tooth_left_up_inner_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-left-up-inner ten"
                                  src="assets/images/icn_tooth_left_up_inner_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[10]),
                                  }}
                                />
                              )}

                              {this.state.brushing_percentages[8] == 1 ||
                              this.state.brushing_percentages[8] == 0 ? (
                                <img
                                  class="cover-left-up-outer eight"
                                  src="assets/images/icn_tooth_left_up_outer_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-left-up-outer eight"
                                  src="assets/images/icn_tooth_left_up_outer_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[8]),
                                  }}
                                />
                              )}
                              {this.state.brushing_percentages[9] == 1 ||
                              this.state.brushing_percentages[9] == 0 ? (
                                <img
                                  class="cover-left-up-surf nine"
                                  src="assets/images/icn_tooth_left_up_top_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-left-up-surf nine"
                                  src="assets/images/icn_tooth_left_up_top_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[9]),
                                  }}
                                />
                              )}
                              <img
                                class="frame-right-up"
                                src="assets/images/icn_tooth_left_up.png"
                              />
                              {this.state.brushing_percentages[16] == 1 ||
                              this.state.brushing_percentages[16] == 0 ? (
                                <img
                                  class="cover-right-up-inner sixteen"
                                  src="assets/images/icn_tooth_left_up_inner_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-right-up-inner sixteen"
                                  src="assets/images/icn_tooth_left_up_inner_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[16]),
                                  }}
                                />
                              )}
                              {this.state.brushing_percentages[14] == 1 ||
                              this.state.brushing_percentages[14] == 0 ? (
                                <img
                                  class="cover-right-up-outer fourteen"
                                  src="assets/images/icn_tooth_left_up_outer_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-right-up-outer fourteen"
                                  src="assets/images/icn_tooth_left_up_outer_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[14]),
                                  }}
                                />
                              )}
                              {this.state.brushing_percentages[15] == 1 ||
                              this.state.brushing_percentages[15] == 0 ? (
                                <img
                                  class="cover-right-up-surf fifteen"
                                  src="assets/images/icn_tooth_left_up_top_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-right-up-surf fifteen"
                                  src="assets/images/icn_tooth_left_up_top_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[15]),
                                  }}
                                />
                              )}
                              <img
                                class="frame-up"
                                src="assets/images/icn_tooth_up.png"
                              />
                              {this.state.brushing_percentages[4] == 1 ||
                              this.state.brushing_percentages[4] == 0 ? (
                                <img
                                  class="cover-up-inner four"
                                  src="assets/images/icn_tooth_up_inner_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-up-inner four"
                                  src="assets/images/icn_tooth_up_inner_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[4]),
                                  }}
                                />
                              )}

                              {this.state.brushing_percentages[3] == 1 ||
                              this.state.brushing_percentages[3] == 0 ? (
                                <img
                                  class="cover-up-outer three"
                                  src="assets/images/icn_tooth_up_outer_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-up-outer three"
                                  src="assets/images/icn_tooth_up_outer_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[3]),
                                  }}
                                />
                              )}
                              <img
                                class="frame-left-down "
                                src="assets/images/icn_tooth_left_down.png"
                              />

                              {this.state.brushing_percentages[7] == 1 ||
                              this.state.brushing_percentages[7] == 0 ? (
                                <img
                                  class="cover-left-down-inner seven"
                                  src="assets/images/icn_tooth_left_down_inner_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-left-down-inner seven"
                                  src="assets/images/icn_tooth_left_down_inner_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[7]),
                                  }}
                                />
                              )}

                              {this.state.brushing_percentages[5] == 1 ||
                              this.state.brushing_percentages[5] == 0 ? (
                                <img
                                  class="cover-left-down-outer five"
                                  src="assets/images/icn_tooth_left_down_outer_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-left-down-outer five"
                                  src="assets/images/icn_tooth_left_down_outer_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[5]),
                                  }}
                                />
                              )}

                              {this.state.brushing_percentages[6] == 1 ||
                              this.state.brushing_percentages[6] == 0 ? (
                                <img
                                  class="cover-left-down-surf six"
                                  src="assets/images/icn_tooth_left_down_top_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-left-down-surf six"
                                  src="assets/images/icn_tooth_left_down_top_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[6]),
                                  }}
                                />
                              )}

                              <img
                                class="frame-right-down"
                                src="assets/images/icn_tooth_left_down.png"
                              />

                              {this.state.brushing_percentages[13] == 1 ||
                              this.state.brushing_percentages[13] == 0 ? (
                                <img
                                  class="cover-right-down-inner thirteen"
                                  src="assets/images/icn_tooth_left_down_inner_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-right-down-inner thirteen"
                                  src="assets/images/icn_tooth_left_down_inner_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[13]),
                                  }}
                                />
                              )}

                              {this.state.brushing_percentages[11] == 1 ||
                              this.state.brushing_percentages[11] == 0 ? (
                                <img
                                  class="cover-right-down-outer eleven"
                                  src="assets/images/icn_tooth_left_down_outer_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-right-down-outer eleven"
                                  src="assets/images/icn_tooth_left_down_outer_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[11]),
                                  }}
                                />
                              )}

                              {this.state.brushing_percentages[12] == 1 ||
                              this.state.brushing_percentages[12] == 0 ? (
                                <img
                                  class="cover-right-down-surf twelve"
                                  src="assets/images/icn_tooth_left_down_top_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-right-down-surf twelve"
                                  src="assets/images/icn_tooth_left_down_top_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[12]),
                                  }}
                                />
                              )}

                              <img
                                class="frame-down"
                                src="assets/images/icn_tooth_down.png"
                              />

                              {this.state.brushing_percentages[2] == 1 ||
                              this.state.brushing_percentages[2] == 0 ? (
                                <img
                                  class="cover-down-inner two"
                                  src="assets/images/icn_tooth_down_inner_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-down-inner two"
                                  src="assets/images/icn_tooth_down_inner_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[2]),
                                  }}
                                />
                              )}

                              {this.state.brushing_percentages[1] == 1 ||
                              this.state.brushing_percentages[1] == 0 ? (
                                <img
                                  class="cover-down-outer one "
                                  src="assets/images/icn_tooth_down_outer_cover.png"
                                  style={{ opacity: 0 }}
                                />
                              ) : (
                                <img
                                  class="cover-down-outer one "
                                  src="assets/images/icn_tooth_down_outer_cover.png"
                                  style={{
                                    opacity:
                                      0.6 *
                                      (1 - this.state.brushing_percentages[1]),
                                  }}
                                />
                              )}
                            </div>
                            <div>
                              <div class="gradientGenerator">
                                <div class="gradientPanel"></div>
                                <div class="coverageNone">
                                  <span class="arrow arrow-up"></span>
                                  <p class="mb-0">0% Coverage</p>
                                </div>
                                <div class="coverageFull">
                                  <span class="arrow arrow-up"></span>
                                  <p class="mb-0">100% Coverage</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}

                  <div class="disease-details">
                    <div class="graphWrapper d-flex flex-wrap">
                      <div class="d-flex graph-head-card flex-column align-items-center position-relative">
                        <h3 class=" graph-head">Brushed Surface</h3>
                        {!this.state.brushed_surface == "" ? (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series1}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        ) : (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={[0, 10]}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        )}
                        <div class="graphDtails">
                          {!this.state.brushed_surface == "" ? (
                            <h1>{this.state.brushed_surface}%</h1>
                          ) : (
                            <h1></h1>
                          )}
                        </div>
                      </div>

                      <div class="d-flex graph-head-card flex-column align-items-center position-relative">
                        <h3 class=" graph-head">Flossed</h3>
                        {!this.state.brushing_flossed_percentage == "" ? (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series4}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        ) : (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={[0, 10]}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        )}
                        <div class="graphDtails">
                          {!this.state.brushing_flossed_percentage == "" ? (
                            <h1>{this.state.brushing_flossed_percentage}%</h1>
                          ) : (
                            <h1></h1>
                          )}
                        </div>
                      </div>
                      <div class="d-flex graph-head-card flex-column align-items-center position-relative">
                        <h3 class=" graph-head">Brushing frequency</h3>
                        {!this.state.brushing_frequency == "" ? (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series2}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        ) : (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={[0, 10]}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        )}
                        <div class="graphDtails">
                          {!this.state.brushing_frequency == "" ? (
                            <h1>{this.state.brushing_frequency}</h1>
                          ) : (
                            <h1></h1>
                          )}
                          {!this.state.brushing_frequency == "" ? (
                            <p>per day</p>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>

                      <div class="d-flex graph-head-card flex-column align-items-center position-relative">
                        <h3 class=" graph-head">Brushing Duration</h3>
                        {!this.state.brushing_duration == "" ? (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series3}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        ) : (
                          <div class="graph-container">
                            <ReactApexChart
                              options={this.state.options}
                              series={[0, 10]}
                              height="176"
                              width="176"
                              type="donut"
                            />
                          </div>
                        )}
                        <div class="graphDtails">
                          {!this.state.brushing_duration == "" ? (
                            <h1>{this.state.brushing_duration}</h1>
                          ) : (
                            <h1></h1>
                          )}
                          {!this.state.brushing_duration == "" ? (
                            <p>minutes</p>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!this.state.brushingerrormsg == "" ? (
          <div class="alert alert-danger alert-danger-top" role="alert">
            {this.state.brushingerrormsg}
          </div>
        ) : (
          ""
        )}
        50%
      </>
    );
  }
}

export default connect(mapStateToProps, { ...patientIndexAction })(
  PatientsIndex
);
