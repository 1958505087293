const baseUrl = "https://bluapi.com/api/";

export default {
  login: baseUrl + "auth/dentist_login?",
  signup: baseUrl + "auth/dentist_signup?",
  forgetPassword: baseUrl + "auth/password/forgot?",
  getPatientInvitation: baseUrl + "dentist/patient_invitations?",
  acceptInvitation: baseUrl + "dentist/accept/",
  declineInvitation: baseUrl + "dentist/decline/",
  disconnectPatient: baseUrl + "dentist/disconnect/",
  recentPatientList: baseUrl + "dentist/recent_accessed_patients",
  quickPatientList: baseUrl + "dentist/patients?",
  dashboardOverview: baseUrl + "dentist/analysis?",
  getPatients: baseUrl + "dentist/patients?",
  viewPatient: baseUrl + "dentist/patient/",
  sentHomeCareInstruction: baseUrl + "dentist/home_care_instruction?",
  viewPatientRecord: baseUrl + "dentist/disease_records?patient_id=",
  updatePatientRecords: baseUrl + "dentist/disease_record?id=",
  getpatientkidRecords: baseUrl + "dentist/kids?patient_id=",
  getBrushingOverview: baseUrl + "dentist/brushing_overview?patient_id=",
  getContactList: baseUrl + "consult/contacts",
  getMessages: baseUrl + "consult/messages?interlocutor_id=",
  sentMessages: baseUrl + "consult/send?message=",

  updateProfile: baseUrl + "dentist/info/change",
  changePassword: baseUrl + "dentist/password/change?old_password=",
  getDentistInfo: baseUrl + "dentist/info",
  addToothDetails: baseUrl + "/dentist/disease_record",
};
