export default {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  FORGET_REQUEST: "FORGET_REQUEST",
  FORGET_SUCCESS: "FORGET_SUCCESS",
  FORGET_FAILURE: "FORGET_FAILURE",

  VIEW_INVITATION_REQUEST: "VIEW_INVITATION_REQUEST",
  VIEW_INVITATION_SUCCESS: "VIEW_INVITATION_SUCCESS",
  VIEW_INVITATION_FAILURE: "VIEW_INVITATION_FAILURE",

  RECENT_PATIENT_LIST_REQUEST: "RECENT_PATIENT_LIST_REQUEST",
  RECENT_PATIENT_LIST_SUCCESS: "RECENT_PATIENT_LIST_SUCCESS",
  RECENT_PATIENT_LIST_FAILURE: "RECENT_PATIENT_LIST_FAILURE",

  QUICK_PATIENT_LIST_REQUEST: "QUICK_PATIENT_LIST_REQUEST",
  QUICK_PATIENT_LIST_SUCCESS: "QUICK_PATIENT_LIST_SUCCESS",
  QUICK_PATIENT_LIST_FAILURE: "QUICK_PATIENT_LIST_FAILURE",

  PATIENT_LIST_REQUEST: "PATIENT_LIST_REQUEST",
  PATIENT_LIST_SUCCESS: "PATIENT_LIST_SUCCESS",
  PATIENT_LIST_FAILURE: "PATIENT_LIST_FAILURE",

  DASHBOARD_OVERVIEW_REQUEST: "DASHBOARD_OVERVIEW_REQUEST",
  DASHBOARD_OVERVIEW_SUCCESS: "DASHBOARD_OVERVIEW_SUCCESS",
  DASHBOARD_OVERVIEW_FAILURE: "DASHBOARD_OVERVIEW_FAILURE",

  DENY_PATIENT_REQUEST: "DENY_PATIENT_REQUEST",
  DENY_PATIENT_SUCCESS: "DENY_PATIENT_SUCCESS",
  DENY_PATIENT_FAILURE: "DENY_PATIENT_FAILURE",

  ACCEPT_PATIENT_REQUEST: "ACCEPT_PATIENT_REQUEST",
  ACCEPT_PATIENT_SUCCESS: "ACCEPT_PATIENT_SUCCESS",
  ACCEPT_PATIENT_FAILURE: "ACCEPT_PATIENT_FAILURE",

  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  VIEW_PATIENT_DETAILS_REQUEST: "VIEW_PATIENT_DETAILS_REQUEST",
  VIEW_PATIENT_DETAILS_SUCCESS: "VIEW_PATIENT_DETAILS_SUCCESS",
  VIEW_PATIENT_DETAILS_FAILURE: "VIEW_PATIENT_DETAILS_FAILURE",

  SENT_HOMECARE_INSTRUCTION_REQUEST: "SENT_HOMECARE_INSTRUCTION_REQUEST",
  SENT_HOMECARE_INSTRUCTION_SUCESS: "SENT_HOMECARE_INSTRUCTION_SUCESS",
  SENT_HOMECARE_INSTRUCTION_FAILURE: "SENT_HOMECARE_INSTRUCTION_FAILURE",

  VIEW_PATIENT_RECORD_REQUEST: "VIEW_PATIENT_RECORD_REQUEST",
  VIEW_PATIENT_RECORD_SUCCESS: "VIEW_PATIENT_RECORD_SUCCESS",
  VIEW_PATIENT_RECORD_FAILURE: "VIEW_PATIENT_RECORD_FAILURE",

  UPDATE_PATIENT_RECORD_REQUEST: "UPDATE_PATIENT_RECORD_REQUEST",
  UPDATE_PATIENT_RECORD_SUCESS: "UPDATE_PATIENT_RECORD_SUCESS",
  UPDATE_PATIENT_RECORD_FAILURE: "UPDATE_PATIENT_RECORD_FAILURE",

  GET_PATIENT_KID_REQUEST: "GET_PATIENT_KID_REQUEST",
  GET_PATIENT_KID_SUCESS: "GET_PATIENT_KID_SUCESS",
  GET_PATIENT_KID_FAILURE: "GET_PATIENT_KID_FAILURE",

  GET_BRUSHING_OVERVIEW_REQUEST: "GET_BRUSHING_OVERVIEW_REQUEST",
  GET_BRUSHING_OVERVIEW_SUCESS: "GET_BRUSHING_OVERVIEW_SUCESS",
  GET_BRUSHING_OVERVIEW_FAILURE: "GET_BRUSHING_OVERVIEW_FAILURE",

  CONTACT_LIST_REQUEST: "CONTACT_LIST_REQUEST",
  CONTACT_LIST_SUCCESS: "CONTACT_LIST_SUCCESS",
  CONTACT_LIST_FAILURE: "CONTACT_LIST_FAILURE",

  LOAD_MESSAGE_REQUEST: "LOAD_MESSAGE_REQUEST",
  LOAD_MESSAGE_SUCCESS: "LOAD_MESSAGE_SUCCESS",
  LOAD_MESSAGE_FAILURE: "LOAD_MESSAGE_FAILURE",

  SENT_MESSAGE_REQUEST: "SENT_MESSAGE_REQUEST",
  SENT_MESSAGE_SUCESS: "SENT_MESSAGE_SUCESS",
  SENT_MESSAGE_FAILURE: "SENT_MESSAGE_SUCESS",

  GET_DENTIST_INFO_REQUEST: "GET_DENTIST_INFO_REQUEST",
  GET_DENTIST_INFO_SUCCESS: "GET_DENTIST_INFO_SUCCESS",
  GET_DENTIST_INFO_FAILURE: "GET_DENTIST_INFO_FAILURE",

  TOGGLE_STATE: "TOGGLE_STATE",
  TOKEN_VALUE: "TOKEN_VALUE",
};
