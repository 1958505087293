import React from 'react'

const Header= ({ component, ...rest }) => {
    return (
            <nav class="sidebar-nav">
                            <ul>
                                <li class="nav-header">
                                    Menu
                                </li>
                                <li class={ rest.path === '/Dashboard' ? "nav-item active" : "nav-item"}>
                                    <a href="/Dashboard">
                                        Dashboard
                                    </a>
                                </li>
                                <li class={ rest.path === '/PatientsIndex' ? "nav-item active" : "nav-item"}>
                                    <a href="/PatientsIndex">
                                        Patients
                                    </a>
                                </li>
                                <li class={ rest.path === '/InvitationIndex' ? "nav-item active" : "nav-item"}>
                                    <a href="/InvitationIndex">
                                    Invitations
                                    </a>
                                </li>
                                <li class={ rest.path === '/SettingsIndex' ? "nav-item active" : "nav-item"}>
                                    <a href="/SettingsIndex">
                                        Settings
                                    </a>
                                </li>
                            </ul>
                        </nav>
    )
}

export default Header
