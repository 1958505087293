import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import Loader from "../common/toast/Loader";
import authAction from "../../actions/authAction";
import history from "../../config/history";
import LayoutBeforLogin from "../common/components/LayoutBeforLogin";

const mapStateToProps = (state) => {
  console.log("state", state);
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      busName: "",
      busAddress: "",
      nameErrorMsg: "",
      emailErrorMsg: "",
      passwordErrorMsg: "",
      busNameErrorMsg: "",
      busAddressErrorMsg: "",
      conpassword: "",
      conpasswordErrorMsg: "",
      loader: false,
    };
  }

  handleChange = (e) => {
    if (e.target.name === "name") {
      this.setState({ name: e.target.value, nameErrorMsg: "" });
    } else if (e.target.name === "email") {
      this.setState({ email: e.target.value, emailErrorMsg: "" });
    } else if (e.target.name === "password") {
      this.setState({ password: e.target.value, passwordErrorMsg: "" });
    } else if (e.target.name === "conpassword") {
      this.setState({ conpassword: e.target.value, conpasswordErrorMsg: "" });
    }
  };
  signUp = async (e) => {
    e.preventDefault();
    /* eslint-disable */
    let passExp =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?(\.\w{2,3})+)*(\.\w{2,3})+$/;
    /* eslint-enable */
    if (this.state.name.trim() === "") {
      this.setState({ nameErrorMsg: "Please enter name " });
    } else if (this.state.email === "") {
      this.setState({ emailErrorMsg: "Please enter email" });
    } else if (this.state.email !== "" && !reg.test(this.state.email)) {
      this.setState({ emailErrorMsg: "Please enter valid email" });
    } else if (this.state.password.trim() === "") {
      this.setState({ passwordErrorMsg: "Please enter password" });
    } else if (!passExp.test(this.state.password)) {
      this.setState({
        passwordErrorMsg:
          "Must contain 8 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character",
      });
    } else if (this.state.conpassword.trim() === "") {
      this.setState({ conpasswordErrorMsg: "Please enter confirm password" });
    } else if (
      this.state.conpassword !== "" &&
      this.state.password !== this.state.conpassword
    ) {
      this.setState({ conpasswordErrorMsg: "Password Mismatch" });
    } else {
      // this.setState({ loader: true })
      const signupData = {
        name: this.state.name.trim(),
        email: this.state.email.trim(),
        password: this.state.password.trim(),
        password_confirmation: this.state.password.trim(),
        // business_name: this.state.busName.trim(),
        // business_address: this.state.busAddress.trim(),
      };
      history.push("/SignupPracticeInfo", signupData);

      // await this.props.SignUp(signupData)
      // this.setState({ loader: false })
      //api function
    }
  };

  render() {
    return (
      <LayoutBeforLogin>
        <div class="auth-box sign-up" style={{ width: "500px" }}>
          {this.state.loader && <Loader />}
          <ToastContainer />
          <h1 class="auth-title">Getting Started</h1>
          <p class="auth-title-sub">
            Already a user?
            <a class="new-acc-link" href="/">
              {" "}
              Sign In
            </a>
          </p>
          <form onSubmit={this.signUp}>
            <div class="form-group">
              <label for="">Full Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Full Name"
                id=""
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="name"
              />
              <span style={{ color: "red" }}> {this.state.nameErrorMsg} </span>
            </div>
            <div class="form-group">
              <label for="">Email</label>
              <input
                class="form-control"
                placeholder="Email"
                id=""
                value={this.state.email.trim()}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="email"
              />
              <span style={{ color: "red" }}> {this.state.emailErrorMsg} </span>
            </div>
            <div class="form-group">
              <label for="">Password</label>
              <input
                type="password"
                class="form-control"
                placeholder="Password"
                id=""
                value={this.state.password.trim()}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="password"
              />
              <span style={{ color: "red" }}>
                {" "}
                {this.state.passwordErrorMsg}{" "}
              </span>
            </div>
            <div class="form-group">
              <label for="">Confirm Password</label>
              <input
                type="password"
                class="form-control"
                placeholder="Confirm Password"
                id=""
                value={this.state.conpassword.trim()}
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="conpassword"
              />
              <span style={{ color: "red" }}>
                {" "}
                {this.state.conpasswordErrorMsg}{" "}
              </span>
            </div>
            <div class="form-action">
              <button type="submit" class="btn btn-primary btn-block">
                Get Started
              </button>
            </div>
          </form>
          <div class="progress-bar progressbar-primary">
            <div class="progress" style={{ width: "33.33%" }}></div>
          </div>
        </div>
      </LayoutBeforLogin>
    );
  }
}
export default connect(mapStateToProps, { ...authAction })(Signup);
