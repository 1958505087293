import ApiLink from "../api/ApiLink";
import ApiCall from "../api/ApiCall";
const headerText = { "Access-Control-Allow-Origin": "*" };

const contactlisting = async (params) => {
  const paramsData = {
    // data: '',
    apiLink: ApiLink.getContactList,

    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const getContactList = await ApiCall.getApiCall(paramsData);

  return getContactList;
};

const loadmessages = async (params) => {
  const paramsData = {
    // data: '',
    apiLink:
      ApiLink.getMessages +
      params.interlocutor_id +
      "&per_page=" +
      params.per_page +
      "&page=" +
      params.page,

    method: "put",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const getMessages = await ApiCall.getApiCall(paramsData);
  return getMessages;
};

const sentMessage = async (params) => {
  const paramsData = {
    // data: '',
    apiLink:
      ApiLink.sentMessages +
      params.message +
      "&receiver_id=" +
      params.receiver_id,

    method: "post",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };

  const getMessages = await ApiCall.getApiCall(paramsData);
  return getMessages;
};

export default {
  contactlisting,
  loadmessages,
  sentMessage,
};
