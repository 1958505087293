import { toast } from "react-toastify";
import { css } from "glamor";

const toastFunction = (message, color, textColor) => {
  toast(message, {
    className: css({
      background: color,
      color: textColor,
    }),
  });
};

export default toastFunction;
