import toastFunction from "../components/common/toast/toastFunction";
import authServices from "../services/authServices";
import history from "../config/history";
import userConstants from "../constants/userConstants";
import { useNavigate } from "react-router-dom";

const {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  FORGET_REQUEST,
  FORGET_SUCCESS,
  FORGET_FAILURE,
  TOKEN_VALUE,
} = userConstants;

const login = (params) => {
  const request = (loginAction) => ({
    type: LOGIN_REQUEST,
    loginAction,
  });
  const success = (loginAction) => ({
    type: LOGIN_SUCCESS,
    loginAction,
  });
  const failure = (error) => ({
    type: LOGIN_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await authServices.login(params);
      const loginData = res;

      if (loginData.status == 200) {
        if (loginData.data.error) {
          toastFunction(loginData.data.error, "#8ae22a", "#000000");
        } else {
          await dispatch(success(loginData));

          localStorage.setItem("token", loginData.data.token);

          dispatch(tokenValue(loginData.data.token));
          localStorage.setItem("user", JSON.stringify(loginData.data.user));

          setTimeout(() => {
            toastFunction("Login Success", "#8ae22a", "#000000");
          }, 500);
          history.push("/Dashboard");
        }
      } else {
        dispatch(failure(false));
        toastFunction(loginData.data.error, "#ad0606", "#000000");
      }
    } catch (e) {
      console.log(e.error);
    }
  };
};

const SignUp = (params) => {
  const request = (signUpAction) => ({
    type: SIGNUP_REQUEST,
    signUpAction,
  });
  const success = (signUpAction) => ({
    type: SIGNUP_SUCCESS,
    signUpAction,
  });
  const failure = (error) => ({
    type: SIGNUP_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await authServices.signup(params);

      const signUpData = res;
      if (signUpData.status == 200) {
        await dispatch(success(signUpData));
        setTimeout(() => {
          toastFunction(signUpData.data.success, "#8ae22a", "#000000");
        }, 500);
        history.push("/SignupComplete");
      } else {
        if (signUpData.status == 422) {
          toastFunction(
            signUpData.data.errors.npi_number[0],
            "#ad0606",
            "#000000"
          );
        } else if (signUpData.status == 409) {
          toastFunction(signUpData.data.error, "#ad0606", "#000000");
        }
        await dispatch(failure(false));
      }
    } catch (e) {
      console.log(e.error);
    }
  };
};

const forgetPassword = (params) => {
  const request = (forgetAction) => ({
    type: FORGET_REQUEST,
    forgetAction,
  });
  const success = (forgetAction) => ({
    type: FORGET_SUCCESS,
    forgetAction,
  });
  const failure = (error) => ({
    type: FORGET_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await authServices.forgetPassword(params);
      const ForgetData = res;
      if (ForgetData.data.status) {
        dispatch(success(ForgetData));
        setTimeout(() => {
          toastFunction(ForgetData.data.message, "#8ae22a", "#000000");
        }, 500);
        history.push("/ForgetPasswordConfirm");
      } else {
        dispatch(failure(false));
        toastFunction(ForgetData.data.errors.email[0], "#ad0606", "#000000");
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const tokenValue = (token = "") => {
  return {
    type: TOKEN_VALUE,
    payload: token,
  };
};

export default {
  login,
  SignUp,
  forgetPassword,
  tokenValue,
};
