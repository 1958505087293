import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../common/toast/Loader";
import history from "../../config/history";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import LayoutBeforLogin from "../common/components/LayoutBeforLogin";

export default class SignupPracticeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      practice_name: "",
      practice_address: "",
      practice_phone_number: "",
      npi_number: "",
      dentist_license_number: "",
      busnameErrorMsg: "",
      busaddressErrorMsg: "",
      phoneErrorMsg: "",
      npinumberErrorMsg: "",
      licenseErrorMsg: "",
      practice_lat: "",
      practice_lon: "",
      signupData: {},
      address: "",
      addressErrorMsg: "",
      lat: {},
      selected: false,
    };
  }
  async componentDidMount() {
    await this.setState({ signupData: this.props.history.location.state });
  }

  handleChangePlace = (address) => {
    this.setState({ address: address, selected: false, addressErrorMsg: "" });
  };

  handleSelect = async (address) => {
    this.setState({ address: address });
    await geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ lat: latLng, selected: true, addressErrorMsg: "" });
      })
      .catch((error) => console.error("Error", error));
  };

  handleChange = (e) => {
    if (e.target.name === "busname") {
      this.setState({
        practice_name: e.target.value,
        busnameErrorMsg: "",
        signupData: { ...this.state.signupData, practice_name: e.target.value },
      });
    } else if (e.target.name === "phone") {
      this.setState({
        practice_phone_number: e.target.value,
        phoneErrorMsg: "",
        signupData: {
          ...this.state.signupData,
          practice_phone_number: e.target.value,
          practice_lat: this.state.lat.lat,
          practice_lon: this.state.lat.lng,
          practice_address: this.state.address,
        },
      });
    } else if (e.target.name === "npinumber") {
      this.setState({
        npi_number: e.target.value,
        npinumberErrorMsg: "",
        signupData: { ...this.state.signupData, npi_number: e.target.value },
      });
    } else if (e.target.name === "license") {
      this.setState({
        dentist_license_number: e.target.value,
        licenseErrorMsg: "",
        signupData: {
          ...this.state.signupData,
          dentist_license_number: e.target.value,
        },
      });
    }
  };

  signUpContinue = async (e) => {
    const allSignupData = {
      signupData: this.state.signupData,
      location: this.state.lat,
    };

    e.preventDefault();

    if (this.state.practice_name.trim() === "") {
      this.setState({ busnameErrorMsg: "Please enter Practice Name" });
    } else if (this.state.address === "") {
      this.setState({ addressErrorMsg: "Please enter Practice Address" });
    } else if (this.state.practice_phone_number === "") {
      this.setState({ phoneErrorMsg: "Please enter Practice Phone Number" });
    } else if (
      this.state.practice_phone_number !== "" &&
      this.state.practice_phone_number.length !== 10
    ) {
      this.setState({
        phoneErrorMsg: "Please enter valid Practice Phone Number",
      });
    } else if (this.state.npi_number === "") {
      this.setState({ npinumberErrorMsg: "Please enter NPI Number" });
    } else if (
      this.state.npi_number !== "" &&
      this.state.npi_number.length !== 10
    ) {
      this.setState({ npinumberErrorMsg: "Please enter valid NPI Number" });
    } else if (this.state.dentist_license_number === "") {
      this.setState({ licenseErrorMsg: "Please enter Dentist License Number" });
    } else {
      history.push("/TermsCondition", this.state.signupData);
    }
  };

  render() {
    return (
      <LayoutBeforLogin>
        <div class="auth-box sign-up" style={{ width: "500px" }}>
          {this.state.loader && <Loader />}
          <ToastContainer />
          <h1 class="auth-title">Practice Information</h1>
          <form onSubmit={this.signUpContinue}>
            <div class="form-group">
              <label for="">Practice Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Practice Name"
                id=""
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="busname"
              />
              <span style={{ color: "red" }}>
                {" "}
                {this.state.busnameErrorMsg}{" "}
              </span>
            </div>
            <div class="form-group suggest-list">
              <label for="">Practice Address</label>
              <PlacesAutocomplete
                value={this.state.address.trim()}
                onChange={this.handleChangePlace}
                onSelect={this.handleSelect}
              >
                {({
                  getInputProps,
                  suggestions = [],
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="autocompleteOuter">
                    <input
                      {...getInputProps({
                        placeholder: "Practice Address",
                        className: "location-search-input",
                      })}
                    />
                    {suggestions.length ? (
                      <div className="autocomplete-dropdown-container">
                        <div class="suggestion-scroll">
                          {loading && (
                            <div class="content-loading">Loading...</div>
                          )}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>
                                  {suggestion.description === "" &&
                                  this.state.address !== ""
                                    ? "No result Found"
                                    : suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </PlacesAutocomplete>
              <span style={{ color: "red" }}>
                {" "}
                {this.state.addressErrorMsg}{" "}
              </span>
            </div>
            <div class="form-group">
              <label for="">Practice Phone Number</label>
              <input
                type="text"
                class="form-control"
                placeholder="Practice Phone Number"
                id=""
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="phone"
              />
              <span style={{ color: "red" }}> {this.state.phoneErrorMsg} </span>
            </div>
            <div class="form-group">
              <label for="">NPI Number</label>
              <input
                type="text"
                class="form-control"
                placeholder="NPI Number"
                id=""
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="npinumber"
              />
              <span style={{ color: "red" }}>
                {" "}
                {this.state.npinumberErrorMsg}{" "}
              </span>
            </div>
            <div class="form-group">
              <label for="">Dentist License Number</label>
              <input
                type="text"
                class="form-control"
                placeholder="Dentist License Number"
                id=""
                onChange={(e) => {
                  this.handleChange(e);
                }}
                name="license"
              />
              <span style={{ color: "red" }}>
                {" "}
                {this.state.licenseErrorMsg}{" "}
              </span>
            </div>
            <div class="form-action">
              <button type="submit" class="btn btn-primary btn-block">
                Continue
              </button>
            </div>
          </form>
          <div class="progress-bar progressbar-primary">
            <div class="progress" style={{ width: "66.66%" }}></div>
          </div>
        </div>
      </LayoutBeforLogin>
    );
  }
}
