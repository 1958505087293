import userConstants from "../constants/userConstants";
import ConsultationService from "../services/ConsultationService";

const {
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAILURE,
  LOAD_MESSAGE_REQUEST,
  LOAD_MESSAGE_SUCCESS,
  LOAD_MESSAGE_FAILURE,
  SENT_MESSAGE_REQUEST,
  SENT_MESSAGE_SUCESS,
  SENT_MESSAGE_FAILURE,
} = userConstants;

const contactList = (params) => {
  const request = (contactListAction) => ({
    type: CONTACT_LIST_REQUEST,
    contactListAction,
  });
  const success = (contactListAction) => ({
    type: CONTACT_LIST_SUCCESS,
    contactListAction,
  });
  const failure = (error) => ({
    type: CONTACT_LIST_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await ConsultationService.contactlisting(params);
      const contactlist = res;
      if (contactlist.status == 200) {
        dispatch(success(contactlist));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

const LoadMessages = (params) => {
  const request = (loadMessageAction) => ({
    type: LOAD_MESSAGE_REQUEST,
    loadMessageAction,
  });
  const success = (loadMessageAction) => ({
    type: LOAD_MESSAGE_SUCCESS,
    loadMessageAction,
  });
  const failure = (error) => ({
    type: LOAD_MESSAGE_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await ConsultationService.loadmessages(params);
      const contactlist = res;
      if (contactlist.status == 200) {
        dispatch(success(contactlist));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

const sentMessage = (params) => {
  const request = (sentMessageAction) => ({
    type: SENT_MESSAGE_REQUEST,
    sentMessageAction,
  });
  const success = (sentMessageAction) => ({
    type: SENT_MESSAGE_SUCESS,
    sentMessageAction,
  });
  const failure = (error) => ({
    type: SENT_MESSAGE_FAILURE,
    error,
  });

  return async (dispatch) => {
    try {
      dispatch(request());
      const res = await ConsultationService.sentMessage(params);
      const contactlist = res;
      if (contactlist.status == 200) {
        dispatch(success(contactlist));
      } else {
        dispatch(failure(false));
      }
    } catch (e) {
      console.log("e", e);
    }
  };
};

export default {
  contactList,
  LoadMessages,
  sentMessage,
};
