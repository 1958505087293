import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify';

export default class SignupComplete extends Component {
    render() {
        return (
         
    <div class="auth-box auth-notification">
      <ToastContainer/>
    <h1 class="auth-title text-center">Thank you for registering</h1>
    <article>
      <p class="text-center">
      We’ll reach out within 24-48 hours while we process your application.
      </p>
    </article>
    <a href="/" type="submit" class="btn btn-primary btn-block">Back to Login</a> 
  </div>
  
        )
    }
}
