import ApiLink from "../api/ApiLink";
import ApiCall from "../api/ApiCall";

const headerText = { "Access-Control-Allow-Origin": "*" };

const updateProfile = async (params, token) => {
  const paramsData = {
    data: params,
    apiLink: ApiLink.updateProfile,
    method: "post",

    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };

  const updateProfileResult = await ApiCall.postApiCallNew(paramsData);

  return updateProfileResult;
};
const changePassword = async (params) => {
  const paramsData = {
    data: params.data,
    apiLink:
      ApiLink.changePassword +
      params.oldpassword +
      "&new_password=" +
      params.newpassword +
      "&new_password_confirmation=" +
      params.confirmationpassword,
    method: "put",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };
  const changePasswordResult = await ApiCall.getApiCall(paramsData);

  return changePasswordResult;
};

const getDentistDetails = async (params) => {
  const paramsData = {
    data: params.data,
    apiLink: ApiLink.getDentistInfo,
    method: "get",
    headers: {
      Authorization: `Bearer ${params.token}`,
      headerText,
    },
  };
  const dentistInfo = await ApiCall.getApiCall(paramsData);

  return dentistInfo;
};

export default {
  updateProfile,
  changePassword,
  getDentistDetails,
};
