import userConstants from '../constants/userConstants'

const {
    PATIENT_LIST_REQUEST,
    PATIENT_LIST_SUCCESS,
    PATIENT_LIST_FAILURE,
    VIEW_PATIENT_DETAILS_REQUEST,
    VIEW_PATIENT_DETAILS_SUCCESS,
    VIEW_PATIENT_DETAILS_FAILURE,

    VIEW_PATIENT_RECORD_REQUEST,
    VIEW_PATIENT_RECORD_SUCCESS,
    VIEW_PATIENT_RECORD_FAILURE,

    UPDATE_PATIENT_RECORD_REQUEST,
    UPDATE_PATIENT_RECORD_SUCESS,
    UPDATE_PATIENT_RECORD_FAILURE,

    GET_PATIENT_KID_REQUEST,
    GET_PATIENT_KID_SUCESS,
    GET_PATIENT_KID_FAILURE,

    GET_BRUSHING_OVERVIEW_REQUEST,
    GET_BRUSHING_OVERVIEW_SUCESS,
    GET_BRUSHING_OVERVIEW_FAILURE,
   

   TOGGLE_STATE

} = userConstants

const initialState = {
    patientListing: {},
    patientDetails: {},
    patientRecords:{},
    updatedPatientRecords:{},
    kidListing:{},
    brushOverviewListing:{},
    toogleState: {}
}


export default (state = initialState, action) => {

    switch (action.type) {

        case PATIENT_LIST_REQUEST:
            return {
                ...state
            }
        case PATIENT_LIST_SUCCESS:
            return {
                ...state,
                patientListing: { success: true, ...action.patientListingAction }
            }
        case PATIENT_LIST_FAILURE:
            return {
                ...state,
                patientListing: { success: false }
            }
        case VIEW_PATIENT_DETAILS_REQUEST:
            return {
                ...state
            }
        case VIEW_PATIENT_DETAILS_SUCCESS:
            return {
                ...state,
                patientDetails: { success: true, ...action.viewPatientAction } 

            
            }


        case VIEW_PATIENT_RECORD_FAILURE:
            return {
                ...state,
                patientDetails: { success: false }
            }



       case VIEW_PATIENT_RECORD_REQUEST:
                return {
                    ...state
                }
            case VIEW_PATIENT_RECORD_SUCCESS:
                return {
                    ...state,
                    patientRecords: { success: true, ...action.viewPatientsRecord }
                }
            case VIEW_PATIENT_DETAILS_FAILURE:
                return {
                    ...state,
                    patientRecords: { success: false }
                }

             case UPDATE_PATIENT_RECORD_REQUEST:
                return {
                    ...state
                }
            case UPDATE_PATIENT_RECORD_SUCESS:
                return {
                    ...state,
                    updatedPatientRecords: { success: true, ...action.updatedPatientRecordAction }
                }
            case UPDATE_PATIENT_RECORD_FAILURE:
                return {
                    ...state,
                    updatedPatientRecords: { success: false }
                }





                case GET_PATIENT_KID_REQUEST:
                return {
                    ...state
                }
            case GET_PATIENT_KID_SUCESS:
                return {
                    ...state,
                    kidListing: { success: true, ...action.getPatientkidAction }
                }
            case GET_PATIENT_KID_FAILURE:
                return {
                    ...state,
                    kidListing: { success: false }
                }


              
                case GET_BRUSHING_OVERVIEW_REQUEST:
                    return {
                        ...state
                    }
                case GET_BRUSHING_OVERVIEW_SUCESS:
                    return {
                        ...state,
                        brushOverviewListing: { success: true, ...action.getBrushingOverviewAction }
                    }
                case GET_BRUSHING_OVERVIEW_FAILURE:
                    return {
                        ...state,
                        brushOverviewListing: { success: false }
                    }


           


            case TOGGLE_STATE + "true":
            return {
                ...state,
                toogleState: { toggle: true }
            }
        case TOGGLE_STATE + "false":
            return {
                ...state,
                toogleState: { toggle: false }
            }
        default:
            return {
                ...state
            }

    }

}
